import { compose } from 'redux';
import { withRouter } from 'next/router';

import withRedirect from './withRedirect';
import isAuthenticated from './isAuthenticated';
import connectContainer from './isAuthenticatedContainer';

const componentsPackage = () => ({
    isAuthenticatedWithRedirect: withRedirect,
    isAuthenticated: compose(connectContainer, withRouter, isAuthenticated(false))
});

export default componentsPackage;
