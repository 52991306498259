import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import theme from 'src/styles/components/Checkbox.module.scss';

const Check = ({ checked, children, onMouseDown, style }) => (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      data-sdk="check"
      className={classnames(theme.check, { [theme.checked]: checked })}
      onMouseDown={onMouseDown}
      style={style}
    >
        {children}
    </div>
);

Check.propTypes = {
    checked: PropTypes.bool,
    children: PropTypes.node,
    onMouseDown: PropTypes.func,
    style: PropTypes.object
};

export default Check;
