export const MODULE_NAME = 'menu';

const prefix = `${MODULE_NAME}/`;

export const ACTION_TYPES = {
    FETCHING_MENUS: `${prefix}FETCHING_MENUS`,
    MENUS_FETCHED: `${prefix}MENUS_FETCHED`,
    MENUS_FETCH_FAILED: `${prefix}MENUS_FETCH_FAILED`,
    FETCHING_MENU: `${prefix}FETCHING_MENU`,
    MENU_FETCHED: `${prefix}MENU_FETCHED`,
    MENU_FETCH_FAILED: `${prefix}MENU_FETCH_FAILED`,
    ADDING_MENU: `${prefix}ADDING_MENU`,
    MENU_ADDED: `${prefix}MENU_ADDED`,
    ADD_MENU_FAILED: `${prefix}ADD_MENU_FAILED`,
    EDITING_MENU: `${prefix}EDITING_MENU`,
    MENU_EDITED: `${prefix}MENU_EDITED`,
    EDIT_MENU_FAILED: `${prefix}EDIT_MENU_FAILED`,
    DELETEING_MENU: `${prefix}DELETEING_MENU`,
    MENU_DELETED: `${prefix}MENU_DELETED`,
    DELETE_MENU_FAILED: `${prefix}DELETE_MENU_FAILED`,
    FETCHING_MENU_CATEGORIES: `${prefix}FETCHING_MENU_CATEGORIES`,
    MENU_CATEGORIES_FETCHED: `${prefix}MENU_CATEGORIES_FETCHED`,
    MENU_CATEGORIES_FETCH_FAILED: `${prefix}MENU_CATEGORIES_FETCH_FAILED`,
    FETCHING_MENU_CATEGORY: `${prefix}FETCHING_MENU_CATEGORY`,
    MENU_CATEGORY_FETCHED: `${prefix}MENU_CATEGORY_FETCHED`,
    MENU_CATEGORY_FETCH_FAILED: `${prefix}MENU_CATEGORY_FETCH_FAILED`,
    ADDING_MENU_CATEGORY: `${prefix}ADDING_MENU_CATEGORY`,
    MENU_CATEGORY_ADDED: `${prefix}MENU_CATEGORY_ADDED`,
    ADD_MENU_CATEGORY_FAILED: `${prefix}ADD_MENU_CATEGORY_FAILED`,
    EDITING_MENU_CATEGORY: `${prefix}EDITING_MENU_CATEGORY`,
    MENU_CATEGORY_EDITED: `${prefix}MENU_CATEGORY_EDITED`,
    EDIT_MENU_CATEGORY_FAILED: `${prefix}EDIT_MENU_CATEGORY_FAILED`,
    DELETEING_MENU_CATEGORY: `${prefix}DELETEING_MENU_CATEGORY`,
    MENU_CATEGORY_DELETED: `${prefix}MENU_CATEGORY_DELETED`,
    DELETE_MENU_CATEGORY_FAILED: `${prefix}DELETE_MENU_CATEGORY_FAILED`,
    FETCHING_MENU_POSITIONS: `${prefix}FETCHING_MENU_POSITIONS`,
    MENU_POSITIONS_FETCHED: `${prefix}MENU_POSITIONS_FETCHED`,
    MENU_POSITIONS_FETCH_FAILED: `${prefix}MENU_POSITIONS_FETCH_FAILED`,
    MENU_CATEGORY_REORDERED: `${prefix}MENU_CATEGORY_REORDERED`,
    MENU_CATEGORY_REORDER: `${prefix}MENU_CATEGORY_REORDER_FAILED`,
    MENU_POSITION_REORDERED: `${prefix}MENU_POSITION_REORDERED`,
    MENU_POSITION_REORDER_FAILED: `${prefix}MENU_POSITION_REORDER_FAILED`,
    MENU_REORDERED: `${prefix}MENU_REORDERED`,
    MENU_REORDER_FAILED: `${prefix}MENU_REORDER_FAILED`
};

export const WEEK_DAYS_SELECTION = {
    EVERY_DAY: 'everyDay',
    SELECTED: 'selected'
};

export const HOURS_SELECTION = {
    ALL_DAY: 'allDay',
    FROM_TO: 'fromTo'
};

export const DATES_SELECTION = {
    ALL_YEAR: 'allYear',
    FROM_TO: 'fromTo'
};

export const YEARS_SELECTION = {
    YEARLY: 'yearly',
    FROM_TO: 'fromTo'
};

export const WEEK_DAYS = {
    MONDAY: 'monday',
    TUESDAY: 'tuesday',
    WEDNESDAY: 'wednesday',
    THURSDAY: 'thursday',
    FRIDAY: 'friday',
    SATURDAY: 'saturday',
    SUNDAY: 'sunday'
};

export const UNAVAILABLE_MENU_POSITION_VISIBILITY = {
    GRAYED_OUT: false,
    HIDDEN: true
};
