import omit from 'lodash/omit';
import get from 'lodash/get';

const mapProps = ({ input, intl, ...props }) => {
    const customProps = { };

    const isTouched = get(props.meta, 'touched');
    const validationError = get(props.meta, 'error');

    if (validationError && isTouched && !props.error) {
        customProps.error = intl.formatMessage({ id: validationError });
    }

    return {
        ...input,
        ...omit(props, ['meta']),
        ...customProps,
        checked: Boolean(input.value)
    };
};

export default mapProps;
