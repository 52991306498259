import ApiUrl from 'src/config/api';
import { selectUserToken } from 'src/modules/User';
import ApiError from 'src/utils/error';
import { ACTION_TYPES } from './constants';

export const fetchAddonGroups = ({ id }) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.FETCHING_ADDON_GROUPS
    });

    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/products/${id}/addonGroups`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then((response) => {
        dispatch({
            type: ACTION_TYPES.ADDON_GROUPS_FETCHED,
            payload: response
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.ADDON_GROUPS_FETCH_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const fetchAddonGroup = ({ id }) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.FETCHING_ADDON_GROUP
    });

    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/addongroups/${id}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then((response) => {
        dispatch({
            type: ACTION_TYPES.ADDON_GROUP_FETCHED,
            payload: response
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.ADDON_GROUP_FETCH_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const addAddonGroup = ({ productId }, addonGroup) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.ADDING_ADDON_GROUP
    });

    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/addongroups`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({ ...addonGroup, productId })
    }).then((response) => {
        if (!response.ok) {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then(() => {
        dispatch({
            type: ACTION_TYPES.ADDON_GROUP_ADDED
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.ADD_ADDON_GROUP_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const addAddonGroupToExistingProduct = ({ productId, addonGroupId }) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.ADDING_ADDON_GROUP
    });

    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/products/${productId}/addonGroups`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({ addonGroupId })
    }).then((response) => {
        if (!response.ok) {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then(() => {
        dispatch({
            type: ACTION_TYPES.ADDON_GROUP_ADDED
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.ADD_ADDON_GROUP_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const editAddonGroup = ({ id }, addonGroup) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.EDITING_ADDON_GROUP
    });

    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/addonGroups/${id}`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify(addonGroup)
    }).then((response) => {
        if (!response.ok) {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then(() => {
        dispatch({
            type: ACTION_TYPES.ADDON_GROUP_EDITED
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.EDIT_ADDON_GROUP_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const deleteAddonGroupFromExistingProduct = ({ id, productId }) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.DELETEING_ADDON_GROUP
    });

    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/products/${productId}/addonGroups`, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({ addonGroupId: id })
    }).then((response) => {
        if (!response.ok) {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then(() => {
        dispatch({
            type: ACTION_TYPES.ADDON_GROUP_DELETED
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.DELETE_ADDON_GROUP_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const updateAddonGroupOrder = (id, { productId }, { fromIndex, toIndex }) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.ADDON_GROUP_REORDERED,
        payload: { id, productId, fromIndex, toIndex }
    });

    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/products/${productId}/addonGroups/${id}/order`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            order: toIndex
        })
    }).then((response) => {
        if (!response.ok) {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.ADDON_GROUP_REORDER_FAILED,
            payload: { error: error?.firstMessage, id, productId, fromIndex, toIndex }
        });
    });
};
