import { ACTION_TYPES } from './constants';

const initialState = {
    notifications: []
};

export default function notificationReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.NOTIFICATION_PUSHED: {
            return {
                ...state,
                notifications: state.notifications.concat(action.payload)
            };
        }
        case ACTION_TYPES.NOTIFICATION_REMOVED: {
            return {
                ...state,
                notifications: state.notifications.slice(1)
            };
        }

        default: return state;
    }
}
