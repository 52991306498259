import { createSelector } from 'reselect';
import { prepareScheduleForm } from 'src/utils/schedule';

export const selectAreas = (state) => state.area.areas || [];

export const selectArea = (state) => state.area.area;

export const selectIsAddAreaPending = (state) => state.area.isAreaAdding;

export const selectAddAreaError = (state) => state.area.areaAddError;

export const selectIsEditAreaPending = (state) => state.area.isAreaEditing;

export const selectEditAreaError = (state) => state.area.areaEditError;

export const selectIsDeleteAreaPending = (state) => state.area.isAreaDeleting;

export const selectDeleteAreaError = (state) => state.area.areaDeleteError;

export const selectAddAreaStatus = createSelector(
    selectIsAddAreaPending,
    selectAddAreaError,
    (isPending, error) => ({
        isPending,
        error
    })
);

export const selectEditAreaStatus = createSelector(
    selectIsEditAreaPending,
    selectEditAreaError,
    (isPending, error) => ({
        isPending,
        error
    })
);

export const selectDeleteAreaStatus = createSelector(
    selectIsDeleteAreaPending,
    selectDeleteAreaError,
    (isPending, error) => ({
        isPending,
        error
    })
);

function prepareTiles(collection) {
    return collection.map(({
        ...element
    }) => ({
        ...element
    }));
}

export const selectAreasTiles = createSelector(
    selectAreas,
    (areas) => prepareTiles(areas)
);

export const selectAreaFormInitialData = createSelector(
    selectArea,
    (area) => {
        if (!area) {
            return null;
        }

        return prepareScheduleForm(area);
    }
);
