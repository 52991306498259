import { connect } from 'react-redux';

import {
    fetchRestaurantBySlug,
    fetchRestaurantByCode,
    selectIsUserRestaurantInitialized,
    selectRestaurantId,
    selectUserRestaurantId,
    logout,
    selectIsLoggedIn
} from 'src/modules/User';

const mapDispatchToProps = {
    fetchRestaurantBySlug,
    fetchRestaurantByCode,
    logout
};

const mapStateToProps = (state) => ({
    isInitialized: selectIsUserRestaurantInitialized(state),
    restaurantId: selectRestaurantId(state),
    userRestaurantId: selectUserRestaurantId(state),
    isLoggedIn: selectIsLoggedIn(state)
});

export default connect(mapStateToProps, mapDispatchToProps);
