import addHours from 'date-fns/addHours';
import addMinutes from 'date-fns/addMinutes';
import addSeconds from 'date-fns/addSeconds';
import subMinutes from 'date-fns/subMinutes';
import startOfDay from 'date-fns/startOfDay';
import format from 'date-fns/format';

export function getUTCTimeFromDate(date) {
    return `${(`0${date.getUTCHours()}`).slice(-2)}:${(`0${date.getUTCMinutes()}`).slice(-2)}:${(`0${date.getUTCSeconds()}`).slice(-2)}`;
}

export function getUTCTimeFromTime(time) {
    const timeArray = time.split(':');

    const date = new Date();

    if (timeArray[0]) {
        date.setHours(timeArray[0]);
    }

    if (timeArray[1]) {
        date.setMinutes(timeArray[1]);
    } else {
        date.setMinutes(0);
    }

    if (timeArray[2]) {
        date.setSeconds(timeArray[2]);
    } else {
        date.setSeconds(0);
    }

    return getUTCTimeFromDate(date);
}

export function getTimezoneOffsetTimeFromTime(time) {
    const timeArray = time.split(':');
    const offset = new Date().getTimezoneOffset();
    let utcDate = subMinutes(startOfDay(new Date()), offset);

    if (timeArray[0]) {
        utcDate = addHours(utcDate, timeArray[0]);
    }

    if (timeArray[1]) {
        utcDate = addMinutes(utcDate, timeArray[1]);
    }

    if (timeArray[2]) {
        utcDate = addSeconds(utcDate, timeArray[2]);
    }

    return format(utcDate, 'HH:mm:ss');
}
