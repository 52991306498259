import { createSelector } from 'reselect';

export const selectMenuPositionRaw = (state) => state.menuPosition.position;

export const selectMenuSettingsPosition = (state) => state.menuPosition.settingsPosition;

export const selectIsAddPositionPending = (state) => state.menuPosition.isPositionAdding;

export const selectAddPositionError = (state) => state.menuPosition.positionAddError;

export const selectIsEditPositionPending = (state) => state.menuPosition.isPositionEditing;

export const selectEditPositionError = (state) => state.menuPosition.positionEditError;

export const selectIsDeletePositionPending = (state) => state.menuPosition.isPositionDeleting;

export const selectDeletePositionError = (state) => state.menuPosition.positionDeleteError;

export const selectBreadcrumb = (state) => state.menuPosition.breadcrumb;

export const selectMenuPosition = createSelector(
    selectMenuPositionRaw,
    (position) => {
        if (!position) {
            return position;
        }

        return {
            ...position,
            allergensNames: position.productAllergens ? position.productAllergens.map((allergen) => allergen.name).join(', ') : null,
            tagsNames: position.productTags ? position.productTags.map((tag) => tag.name).join(', ') : null,
            productImage: position.productImageId
                ? { id: position.productImageId, name: `${position.productImageId}.jpg` }
                : null
        };
    }
);

export const selectMenuPositionTile = createSelector(
    selectMenuSettingsPosition,
    (position) => {
        if (!position) {
            return position;
        }

        return {
            name: position.productName,
            price: position.productPrice,
            description: position.productDescription,
            id: position.id,
            productId: position.productId,
            imageId: position.productImageId,
            isActive: position.isActive
        };
    }
);

export const selectMenuPositionSettings = createSelector(
    selectMenuPositionTile,
    (position) => {
        if (position) {
            return [position];
        }

        return [];
    }
);

export const selectAddPositionStatus = createSelector(
    selectIsAddPositionPending,
    selectAddPositionError,
    (isPending, error) => ({
        isPending,
        error
    })
);

export const selectEditPositionStatus = createSelector(
    selectIsEditPositionPending,
    selectEditPositionError,
    (isPending, error) => ({
        isPending,
        error
    })
);

export const selectDeletePositionStatus = createSelector(
    selectIsDeletePositionPending,
    selectDeletePositionError,
    (isPending, error) => ({
        isPending,
        error
    })
);
