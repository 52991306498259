import React, { useEffect } from 'react';

export const calculateVh = () => {
    document.querySelector(':root').style.setProperty('--vh', `${window.innerHeight / 100}px`);
};

const withScreenHeightDetector = (Component) => {
    const screenHeightDetector = (props) => {
        useEffect(() => {
            calculateVh();

            window.addEventListener('resize', () => {
                calculateVh();
            });
        });

        return <Component {...props} />;
    };

    return screenHeightDetector;
};

export default withScreenHeightDetector;
