import { ACTION_TYPES, TABS } from './constants';

const initialState = {
    tabs: TABS,
    activeTab: TABS[0],
    idleObject: {
        isPaused: false,
        remainingTime: 0
    }
};

export default function tableReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.ACTIVE_TAB_CHANGED: {
            return {
                ...state,
                activeTab: action.payload
            };
        }

        case ACTION_TYPES.CHANGE_IDLE_OBJECT: {
            return {
                ...state,
                idleObject: {
                    ...state.idleObject,
                    ...action.payload
                }
            };
        }

        default: return state;
    }
}
