import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';

export const selectAddons = (state) => state.addon.addons || {};

export const selectAreAddonsFetching = (state) => state.addon.areAddonsFetching;

export const selectAddon = (state) => state.addon.addon;

export const selectIsAddonFetching = (state) => state.addon.isAddonFetching;

export const selectIsAddAddonPending = (state) => state.addon.isAddonAdding;

export const selectAddAddonError = (state) => state.addon.addonAddError;

export const selectIsEditAddonPending = (state) => state.addon.isAddonEditing;

export const selectEditAddonError = (state) => state.addon.addonEditError;

export const selectIsDeleteAddonPending = (state) => state.addon.isAddonDeleting;

export const selectDeleteAddonError = (state) => state.addon.addonDeleteError;

export const selectMenuSettingsAddon = (state) => state.addon.settingsAddon;

export const selectAddonsSettings = createSelector(
    selectAddons,
    (addons) => {
        if (!addons || isEmpty(addons)) {
            return addons;
        }

        return Object.keys(addons).reduce((result, key) => ({
            ...result,
            [key]: addons[key].map((addon) => ({
                name: addon.productName,
                description: addon.productDescription,
                price: addon.price || addon.productPrice,
                id: addon.id,
                addonGroupId: addon.addonGroupId
            }))
        }), {});
    }
);

export const selectAddAddonStatus = createSelector(
    selectIsAddAddonPending,
    selectAddAddonError,
    (isPending, error) => ({
        isPending,
        error
    })
);

export const selectEditAddonStatus = createSelector(
    selectIsEditAddonPending,
    selectEditAddonError,
    (isPending, error) => ({
        isPending,
        error
    })
);

export const selectDeleteAddonStatus = createSelector(
    selectIsDeleteAddonPending,
    selectDeleteAddonError,
    (isPending, error) => ({
        isPending,
        error
    })
);

export const selectAddonTile = createSelector(
    selectMenuSettingsAddon,
    (addon) => {
        if (!addon) {
            return addon;
        }

        return {
            name: addon.productName,
            price: addon.productPrice,
            description: addon.productDescription,
            id: addon.id,
            productId: addon.productId
        };
    }
);

export const selectAddonSettings = createSelector(
    selectAddonTile,
    (addon) => {
        if (addon) {
            return [addon];
        }

        return [];
    }
);
