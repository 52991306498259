export const MODULE_NAME = 'order';

const prefix = `${MODULE_NAME}/`;

export const ACTION_TYPES = {
    ORDER_POSITION_ADDED: `${prefix}ORDER_POSITION_ADDED`,
    ADD_ORDER_POSITION_FAILED: `${prefix}ADD_ORDER_POSITION_FAILED`,
    CLEAR_ADD_ORDER_POSITION_ERROR_MESSAGE: `${prefix}CLEAR_ADD_ORDER_POSITION_ERROR_MESSAGE`,
    ORDERS_FETCHED: `${prefix}ORDERS_FETCHED`,
    INCREASE_QUANTITY: `${prefix}INCREASE_QUANTITY`,
    DECREASE_QUANTITY: `${prefix}DECREASE_QUANTITY`,
    SET_SELECTED_MENU_POSITION: `${prefix}SET_SELECTED_MENU_POSITION`,
    SELECTED_MENU_POSITION_CLEARED: `${prefix}SELECTED_MENU_POSITION_CLEARED`,
    SUBMIT_ORDER_SUCCESS: `${prefix}SUBMIT_ORDER_SUCCESS`,
    SUBMIT_ORDER_FAILED: `${prefix}SUBMIT_ORDER_FAILED`,
    SUBMIT_ORDER_PENDING: `${prefix}SUBMIT_ORDER_PENDING`,
    SUBMIT_ASK_FOR_BILL_FAILED: `${prefix}SUBMIT_ASK_FOR_BILL_FAILED`,
    ASKED_FOR_BILL: `${prefix}ASKED_FOR_BILL`,
    SET_ADDON_AS_SELECTED: `${prefix}SET_ADDON_AS_SELECTED`,
    SET_ADDON_AS_UNSELECTED: `${prefix}SET_ADDON_AS_UNSELECTED`,
    ORDER_POSITION_VALIDATED: `${prefix}ORDER_POSITION_VALIDATED`,
    REMOVE_ORDER_POSITION: `${prefix}REMOVE_ORDER_POSITION`,
    REMOVE_ORDER_POSITION_FAILED: `${prefix}REMOVE_ORDER_POSITION_FAILED`
};

export const ORDER_STATUS = {
    PLACED: 0,
    ACCEPTED: 1,
    COMPLETED: 2
};

export const PAYMENT_METHOD = {
    TERMINAL: 0,
    CASH: 1
};
