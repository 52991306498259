import reducer from './reducer';
import { ACTION_TYPES, MODULE_NAME, TABS, TAB, SECTION } from './constants';

export {
    setActiveTab,
    changeIdleObject
} from './actions';

export {
    selectHeaderTabs,
    selectActiveTab,
    selectIdleObject
} from './selectors';

export {
    ACTION_TYPES,
    MODULE_NAME,
    TABS,
    TAB,
    SECTION
};

export default () => ({
    [MODULE_NAME]: {
        reducer
    }
});
