import ApiUrl from 'src/config/api';
import { selectUserToken } from 'src/modules/User';
import ApiError from 'src/utils/error';
import { ACTION_TYPES } from './constants';

export const fetchMenus = () => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.FETCHING_MENUS
    });

    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/menus/`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then((response) => {
        dispatch({
            type: ACTION_TYPES.MENUS_FETCHED,
            payload: response
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.MENUS_FETCH_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const fetchMenu = (id) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.FETCHING_MENU
    });

    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/menus/${id}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then((response) => {
        dispatch({
            type: ACTION_TYPES.MENU_FETCHED,
            payload: response
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.MENU_FETCH_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const addMenu = (menu) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.ADDING_MENU
    });

    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/menus/`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify(menu)
    }).then((response) => {
        if (!response.ok) {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then(() => {
        dispatch({
            type: ACTION_TYPES.MENU_ADDED
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.ADD_MENU_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const editMenu = (id, menu) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.EDITING_MENU
    });

    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/menus/${id}`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify(menu)
    }).then((response) => {
        if (!response.ok) {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then(() => {
        dispatch({
            type: ACTION_TYPES.MENU_EDITED
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.EDIT_MENU_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const deleteMenu = ({ id }) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.DELETEING_MENU
    });

    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/menus/${id}`, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }).then((response) => {
        if (!response.ok) {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then(() => {
        dispatch({
            type: ACTION_TYPES.MENU_DELETED
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.DELETE_MENU_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const fetchMenuCategories = (id) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.FETCHING_MENU_CATEGORIES
    });

    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/menus/${id}/categories`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then((response) => {
        dispatch({
            type: ACTION_TYPES.MENU_CATEGORIES_FETCHED,
            payload: response
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.MENU_CATEGORIES_FETCH_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const fetchMenuCategory = (id, { menuId }) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.FETCHING_MENU_CATEGORY
    });

    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/menus/${menuId}/categories/${id}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then((response) => {
        dispatch({
            type: ACTION_TYPES.MENU_CATEGORY_FETCHED,
            payload: response
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.MENU_CATEGORY_FETCH_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const updateMenuCategoryOrder = (id, { menuId }, { fromIndex, toIndex }) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.MENU_CATEGORY_REORDERED,
        payload: { id, menuId, fromIndex, toIndex }
    });

    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/menus/${menuId}/categories/${id}/order`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            order: toIndex
        })
    }).then((response) => {
        if (!response.ok) {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.MENU_CATEGORY_REORDER_FAILED,
            payload: { error: error?.firstMessage, id, menuId, fromIndex, toIndex }
        });
    });
};

export const updateMenuOrder = (id, routeIds, { fromIndex, toIndex }) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.MENU_REORDERED,
        payload: { id, fromIndex, toIndex }
    });

    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/menus/${id}/order`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            order: toIndex
        })
    }).then((response) => {
        if (!response.ok) {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.MENU_REORDER_FAILED,
            payload: { error: error?.firstMessage, fromIndex, toIndex }
        });
    });
};

export const addCategory = (menuId, category) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.ADDING_MENU_CATEGORY
    });

    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/menus/${menuId}/categories`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify(category)
    }).then((response) => {
        if (!response.ok) {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then(() => {
        dispatch({
            type: ACTION_TYPES.MENU_CATEGORY_ADDED
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.ADD_MENU_CATEGORY_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const editCategory = (id, category, { menuId }) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.EDITING_MENU_CATEGORY
    });

    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/menus/${menuId}/categories/${id}`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify(category)
    }).then((response) => {
        if (!response.ok) {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then(() => {
        dispatch({
            type: ACTION_TYPES.MENU_CATEGORY_EDITED
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.EDIT_MENU_CATEGORY_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const deleteCategory = ({ menuId, id }) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.DELETEING_MENU_CATEGORY
    });

    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/menus/${menuId}/categories/${id}`, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }).then((response) => {
        if (!response.ok) {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then((response) => {
        dispatch({
            type: ACTION_TYPES.MENU_CATEGORY_DELETED,
            payload: response
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.DELETE_MENU_CATEGORY_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const fetchMenuPositions = (menuId, categoryId) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.FETCHING_MENU_POSITIONS
    });

    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/menus/${menuId}/categories/${categoryId}/positions`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then((response) => {
        dispatch({
            type: ACTION_TYPES.MENU_POSITIONS_FETCHED,
            payload: response
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.MENU_POSITIONS_FETCH_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const updateMenuPositionOrder = (id, { menuId, categoryId }, { fromIndex, toIndex }) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.MENU_POSITION_REORDERED,
        payload: { id, menuId, fromIndex, toIndex }
    });

    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/menus/${menuId}/categories/${categoryId}/positions/${id}/order`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            order: toIndex
        })
    }).then((response) => {
        if (!response.ok) {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.MENU_POSITION_REORDER_FAILED,
            payload: { error: error?.firstMessage, fromIndex, toIndex, id, menuId }
        });
    });
};
