import reducer from './reducer';
import { MODULE_NAME } from './constants';

export {
    fetchMenuPosition,
    fetchMenuPositionSettings,
    fetchBreadcrumb,
    addMenuPosition,
    addMenuPositionByExistingProduct,
    editMenuPosition,
    deleteMenuPosition,
    clearMenuPosition,
    statusChange
} from './actions';

export {
    selectMenuPosition,
    selectMenuPositionTile,
    selectMenuPositionSettings,
    selectDeletePositionStatus,
    selectAddPositionStatus,
    selectEditPositionStatus,
    selectBreadcrumb
} from './selectors';

export default () => ({
    [MODULE_NAME]: {
        reducer
    }
});
