import reducer from './reducer';
import { MODULE_NAME } from './constants';

export {
    fetchMenus,
    fetchMenu,
    addMenu,
    deleteMenu,
    editMenu,
    fetchMenuCategories,
    addCategory,
    editCategory,
    deleteCategory,
    fetchMenuPositions,
    fetchMenuCategory,
    updateMenuCategoryOrder,
    updateMenuOrder
} from './actions';

export {
    selectMenus,
    selectMenusTiles,
    selectMenu,
    selectMenuCategory,
    selectMenuFormInitialData,
    selectMenuCategoryFormInitialData,
    selectMenuCategories,
    selectMenuCategoriesTiles,
    selectMenuPositions,
    selectAddMenuStatus,
    selectEditMenuStatus,
    selectDeleteMenuStatus,
    selectAddMenuCategoryStatus,
    selectEditMenuCategoryStatus,
    selectDeleteMenuCategoryStatus,
    selectMenuName,
    selectMenuCategoryName,
    selectMenuPositionsManagementAdditionalData
} from './selectors';

export default () => ({
    [MODULE_NAME]: {
        reducer
    }
});
