import omit from 'lodash/omit';
import get from 'lodash/get';

const mapProps = ({ input: { onBlur, ...inputRest }, intl, ...props }) => {
    const customProps = {};

    const isTouched = get(props.meta, 'touched');
    const validationError = get(props.meta, 'error');

    if (validationError && isTouched && !props.error) {
        customProps.error = intl.formatMessage({ id: validationError });
    }

    return {
        ...inputRest,
        ...omit(props, ['meta']),
        onBlur: () => onBlur(),
        onChange: (value) => { inputRest.onChange(value.join(',')); },
        allowCreate: true,
        value: inputRest.value ? inputRest.value.split(',').map((key) => ({ key, value: key })) : [],
        multiple: true,
        keepFocusOnChange: true,
        shouldAddOnSpace: true,
        ...customProps
    };
};

export default mapProps;
