import WebSocket from 'src/utils/webSocket';
import isString from 'lodash/isString';

import { selectUserToken } from 'src/modules/User';

import { ACTION_TYPES } from './constants';

class TablesWebSocket extends WebSocket {
    receive(tables) {
        if (isString(tables)) {
            return;
        }

        this.dispatch({
            type: ACTION_TYPES.TABLES_FETCHED,
            payload: tables
        });
    }
}

export default ({
    WebSocket: TablesWebSocket,
    options: {
        userSelector: selectUserToken
    },
    socketName: 'tablesWebSocket'
});
