import { createSelector } from 'reselect';
import first from 'lodash/first';
import { ORDER_STATUS } from './constants';

export const selectOrders = (state) => state.order.orders;

export const selectOrdersPrice = (state) => state.order.price;

export const selectOrdersPositionsNumber = (state) => state.order.positionsNumber;

export const selectAreOrdersFetched = (state) => state.order.areOrdersFetched;

export const selectIsOrderPositionAdded = (state) => state.order.isOrderPositionAdded;

export const selectOrderContext = (state) => state.order.context;

export const shouldShouldShowAddOrderPositionErrorMessage = (state) => state.order.shouldShowAddOrderPositionErrorMessage;

export const selectIsSubmitOrderPending = (state) => state.order.isSubmitOrderPending;

export const selectMenuPositionConfigurator = (state) => state.order.configurablePosition;

export const selectValidationErrors = (state) => state.order.validationErrors;

export const selectOrderSummary = createSelector(
    selectOrdersPrice,
    selectOrdersPositionsNumber,
    (price, positions) => ({
        price,
        positions
    })
);

export const selectLastOrderStatus = createSelector(
    selectOrders,
    (orders) => {
        const order = first(orders);

        if (!order) {
            return 0;
        }

        return order.status;
    }
);

export const selectShouldCreateNewOrder = createSelector(
    selectLastOrderStatus,
    (status) => status !== ORDER_STATUS.NEW
);

export const selectIsOrderContextDefined = createSelector(
    selectOrderContext,
    (context) => Boolean(context && context.sessionId)
);

const selectIsOrderOpened = createSelector(
    selectLastOrderStatus,
    (status) => status === ORDER_STATUS.NEW
);

export const selectCanOrder = createSelector(
    selectOrders,
    selectIsOrderOpened,
    (orders, isOrderOpened) => {
        const order = first(orders);

        if (!order) {
            return false;
        }

        return order.positions.length > 0 && isOrderOpened;
    }
);

export const selectIsAnyPlacedyOrder = createSelector(
    selectOrders,
    (orders) => {
        const firstOrder = first(orders);

        if (!firstOrder) {
            return false;
        }

        return orders.every((order) => (order.status === ORDER_STATUS.COMPLETED));
    }
);
