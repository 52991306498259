import arrayMove from 'array-move';
import { ACTION_TYPES } from './constants';

const initialState = {
    areas: [],
    area: null,
    areAreasFetching: false,
    isAreaAdding: false,
    areaAddError: null,
    isAreaEditing: false,
    areaEditError: null,
    isAreaDeleting: false,
    isAreaFetching: false,
    areaDeleteError: null
};

export default function areaReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.AREAS_FETCHED: {
            const { areas } = action.payload;

            return {
                ...state,
                areAreasFetching: false,
                areas
            };
        }

        case ACTION_TYPES.AREAS_FETCHING: {
            return {
                ...state,
                areAreasFetching: true
            };
        }

        case ACTION_TYPES.AREAS_FETCH_FAILED: {
            return {
                ...state,
                areAreasFetching: false,
                areas: initialState.areas
            };
        }

        case ACTION_TYPES.AREA_FETCHED: {
            const area = action.payload;

            return {
                ...state,
                isAreaFetching: false,
                area
            };
        }

        case ACTION_TYPES.AREA_FETCHING: {
            return {
                ...state,
                isAreaFetching: true
            };
        }

        case ACTION_TYPES.AREA_FETCH_FAILED: {
            return {
                ...state,
                isAreaFetching: false,
                area: initialState.area
            };
        }

        case ACTION_TYPES.ADDING_AREA: {
            return {
                ...state,
                isAreaAdding: true,
                areaAddError: null
            };
        }

        case ACTION_TYPES.AREA_ADDED: {
            return {
                ...state,
                isAreaAdding: false,
                areaAddError: null
            };
        }

        case ACTION_TYPES.ADD_AREA_FAILED: {
            return {
                ...state,
                isAreaAdding: false,
                areaAddError: action.payload
            };
        }

        case ACTION_TYPES.EDITING_AREA: {
            return {
                ...state,
                isAreaEditing: true,
                areaEditError: null
            };
        }

        case ACTION_TYPES.AREA_EDITED: {
            return {
                ...state,
                isAreaEditing: false,
                areaEditError: null
            };
        }

        case ACTION_TYPES.EDIT_AREA_FAILED: {
            return {
                ...state,
                isAreaEditing: false,
                areaEditError: action.payload
            };
        }

        case ACTION_TYPES.DELETEING_AREA: {
            return {
                ...state,
                isAreaDeleting: true,
                areaDeleteError: null
            };
        }

        case ACTION_TYPES.AREA_DELETED: {
            return {
                ...state,
                isAreaDeleting: false,
                areaDeleteError: null
            };
        }

        case ACTION_TYPES.DELETE_AREA_FAILED: {
            return {
                ...state,
                isAreaDeleting: false,
                areaDeleteError: action.payload
            };
        }

        case ACTION_TYPES.AREA_REORDERED: {
            const { fromIndex, toIndex } = action.payload;

            return {
                ...state,
                areas: arrayMove(state.areas, fromIndex - 1, toIndex - 1)
            };
        }

        case ACTION_TYPES.AREA_REORDER_FAILED: {
            const { fromIndex, toIndex } = action.payload;

            return {
                ...state,
                areas: arrayMove(state.areas, toIndex - 1, fromIndex - 1)
            };
        }

        default: return state;
    }
}
