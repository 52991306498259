import camelcaseKeys from 'camelcase-keys';
import env from '@beam-australia/react-env';
import { selectUserToken } from 'src/modules/User';
import { noop } from 'lodash';
import ReconnectingWebSocket from 'reconnecting-websocket';

export const SOCKET_STATE = {
    CONNECTING: 0,
    OPEN: 1,
    CLOSING: 2,
    CLOSED: 3
};

export function getSocketUrl() {
    const CONFIG = {
        SOCKET_REMOTE_URL: env('SOCKET_URL') || process?.env?.SOCKET_URL
    };

    return CONFIG.SOCKET_REMOTE_URL;
}

class WebSocket {
    options = {
        actionName: '',
        tryOpenCount: 10,
        tryOpenTimeout: 250,
        ping: 1000,
        url: getSocketUrl(),
        userSelector: () => ''
    }

    socket = {
        connection: null,
        opened: false
    }

    dispatch = undefined;

    constructor(options) {
        this.options = {
            ...this.options,
            ...options
        };
    }

    getSocketState = () => this.socket?.connection?.readyState

    connect = (dispatch, getState, actionName) => {
        if (!dispatch) {
            // eslint-disable-next-line no-console
            console.error('dispatch parameter is required');
        }

        if (!getState) {
            // eslint-disable-next-line no-console
            console.error('getState parameter is required');
        }

        if (!this.receive) {
            // eslint-disable-next-line no-console
            console.error('receive method is undefined, implement receive method to get data from socket!');
        }

        if (!this.options.url) {
            // eslint-disable-next-line no-console
            console.error('socket url is undefined. Pass url param in socket options to set url.');
        }

        this.dispatch = dispatch;
        this.getState = getState;
        this.actionName = actionName;

        const token = selectUserToken(getState());
        const socketUrl = `${this.options.url}/${actionName || this.options.actionName}?authorization=${token}`;

        if (this.socket.connection) {
            this.socket.connection.onOpen = noop;
            this.socket.connection.onMessage = noop;
            this.socket.connection.onError = noop;
            this.socket.connection.close();
        }

        this.socket.connection = new ReconnectingWebSocket(socketUrl);
        this.socket.connection.addEventListener('open', this.onOpen);
        this.socket.connection.addEventListener('error', this.onError);
        this.socket.connection.addEventListener('message', this.onMessage);

        window.socket = {
            [actionName]: this.socket
        };
    }

    close() {
        this.socket.connection.removeEventListener('open', this.onOpen);
        this.socket.connection.removeEventListener('error', this.onError);
        this.socket.connection.removeEventListener('message', this.onMessage);
        this.socket.connection.close();
    }

    onOpen = () => {
        this.socket.opened = true;
    }

    onError = (event) => {
        // eslint-disable-next-line no-console
        console.warn(event);
    }

    onMessage = (message) => {
        if (message.data === 'Ping') {
            this.pong();

            return;
        }

        try {
            const json = JSON.parse(message.data);
            this.receive(camelcaseKeys(json, { deep: true }));
        } catch (e) {
            this.receive(message.data);
        }
    }

    async send(message, options = {}) {
        if (!options.force) {
            const isOpened = await this.waitForOpen();

            if (!isOpened) {
                return;
            }
        }

        if (options.type === 'plain') {
            this.socket.connection.send(message);
        } else {
            const jsonMessage = JSON.stringify(message);
            this.socket.connection.send(jsonMessage);
        }
    }

    wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

    async waitForOpen() {
        // eslint-disable-next-line no-async-promise-executor
        const promise = new Promise(async (resolve) => {
            for (let i = 0; i < this.options.tryOpenCount; i += 1) {
                await this.wait(this.options.tryOpenTimeout); // eslint-disable-line

                if (this.socket.opened) {
                    resolve(this.socket.opened);
                    break;
                }
            }
        });

        return promise.then((openState) => openState && this.socket.connection.readyState === 1);
    }

    pong = async () => {
        const authToken = this.options.userSelector(this.getState()) || {};

        await this.send(authToken, { type: 'plain' });
    }
}

export default WebSocket;
