import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import noop from 'lodash/noop';
import { isValuePresent } from 'src/utils/utils';

import FontIcon from 'src/components/FontIcon';

class Input extends Component {
    componentDidMount() {
        if (this.props.multiline) {
            window.addEventListener('resize', this.handleAutoresize);
            this.handleAutoresize();
        }
    }

    componentDidUpdate(prevProps) {
        const { multiline } = this.props;

        if (!prevProps.multiline && multiline) {
            window.addEventListener('resize', this.handleAutoresize);
        } else if (prevProps.multiline && !multiline) {
            window.removeEventListener('resize', this.handleAutoresize);
        }

        if (multiline) {
            this.handleAutoresize();
        }
    }

    componentWillUnmount() {
        if (this.props.multiline) {
            window.removeEventListener('resize', this.handleAutoresize);
        }
    }

    handleChange = (event) => {
        const { onChange, multiline, maxLength, type } = this.props;

        const valueFromEvent = event.target.value && type === 'number'
            ? parseFloat(event.target.value)
            : event.target.value;

        const haveToTrim = (multiline && maxLength && event.target.value.length > maxLength);
        const value = haveToTrim ? valueFromEvent.substr(0, maxLength) : valueFromEvent;
        onChange(value, event);
    };

    handleAutoresize = () => {
        const element = this.inputNode;
        const { rows } = this.props;

        if (typeof rows === 'number' && !Number.isNaN(rows)) {
            element.style.height = null;
        } else {
            const style = getComputedStyle(element, null);
            const heightOffset = style.boxSizing === 'content-box'
                ? -(parseFloat(style.paddingTop) + parseFloat(style.paddingBottom))
                : parseFloat(style.borderTopWidth) + parseFloat(style.borderBottomWidth);
            element.style.height = 'auto';
            element.style.height = `${element.scrollHeight + heightOffset}px`;
        }
    }

    handleKeyPress = (event) => {
        const { multiline, maxLength, onKeyPress } = this.props;

        if (multiline && maxLength) {
            const isReplacing = event.target.selectionEnd - event.target.selectionStart;
            const { value } = event.target;
            if (!isReplacing && value.length === maxLength) {
                event.preventDefault();
                event.stopPropagation();
                return undefined;
            }
        }
        onKeyPress(event);
        return undefined;
    };

    blur() {
        this.inputNode.blur();
    }

    focus() {
        this.inputNode.focus();
    }

    render() {
        const { children, defaultValue, disabled, error, floating, hint, icon, containerClassName,
            name, label: labelText, maxLength, multiline, required, role, inputRef,
            type, value, onKeyPress, rows, theme, i18n, shouldShowCounter, hidden, ...others } = this.props;

        const length = maxLength && value ? value.length : 0;
        const labelClassName = classnames(theme.label, { [theme.fixed]: !floating });
        const className = classnames(theme.input, {
            [theme.disabled]: disabled,
            [theme.errored]: error,
            [theme.hidden]: type === 'hidden',
            [theme.withIcon]: icon
        }, this.props.className);
        const valuePresent = isValuePresent(value) || isValuePresent(defaultValue);
        const inputElementProps = {
            ...others,
            className: classnames(theme.inputElement, { [theme.filled]: valuePresent }),
            onChange: this.handleChange,
            ref: (node) => { this.inputNode = node; inputRef(node); },
            role,
            name,
            defaultValue,
            disabled,
            required,
            type,
            value
        };

        if (!multiline) {
            inputElementProps.maxLength = maxLength;
            inputElementProps.onKeyPress = onKeyPress;
        } else {
            inputElementProps.rows = rows;
            inputElementProps.onKeyPress = this.handleKeyPress;
        }

        return (
            <div className={classnames(containerClassName, theme.inputContainer)}>
                {labelText
                ? (
                    <>
                        <label // eslint-disable-line jsx-a11y/label-has-for
                          className={classnames(labelClassName, error && theme.error, required && theme.required)}
                          htmlFor={inputElementProps.id}
                        >
                            {labelText}
                            {required ? <span className={theme.required}> * </span> : null}
                        </label>
                        <div className={theme.labelBorder} />
                    </>
                )
                : null}
                {error ? <span className={theme.error}>{error}</span> : null}
                <div data-sdk="input" className={classnames(className, { [theme.hidden]: hidden })}>

                    {React.createElement(multiline ? 'textarea' : 'input', inputElementProps)}
                    {icon ? <FontIcon className={theme.icon} value={icon} /> : null}
                    <span className={theme.bar} />
                    {hint ? <span hidden={labelText} className={theme.hint}>{hint}</span> : null}

                    {(shouldShowCounter && maxLength) ? <span className={theme.counter}>{length}/{maxLength}</span> : null}
                    {children}
                </div>
            </div>
        );
    }
}

Input.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    floating: PropTypes.bool,
    hint: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    icon: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    maxLength: PropTypes.number,
    multiline: PropTypes.bool,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyPress: PropTypes.func,
    required: PropTypes.bool,
    role: PropTypes.string,
    rows: PropTypes.number,
    type: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.object,
        PropTypes.string
    ]),
    theme: PropTypes.object,
    i18n: PropTypes.object,
    shouldShowCounter: PropTypes.bool,
    inputRef: PropTypes.func,
    hidden: PropTypes.bool,
    containerClassName: PropTypes.string
};

Input.defaultProps = {
    className: '',
    hint: '',
    disabled: false,
    floating: true,
    multiline: false,
    required: false,
    onChange: noop,
    onKeyPress: noop,
    role: 'input',
    type: 'text',
    shouldShowCounter: true,
    inputRef: noop,
    rows: 1,
    hidden: false
};

export default Input;
