import arrayMove from 'array-move';
import { ACTION_TYPES } from './constants';

const initialState = {
    menus: [],
    menu: null,
    areMenusFetching: false,
    isMenuAdding: false,
    menuAddError: null,
    isMenuEditing: false,
    menuEditError: null,
    isMenuDeleting: false,
    isMenuFetching: false,
    menuDeleteError: null,
    areCategoriesFetching: false,
    categories: [],
    menuCategory: null,
    isMenuCategoryFetching: false,
    isMenuCategoryAdding: true,
    menuCategoryAddError: null,
    isMenuCategoryEditing: false,
    menuCategoryEditError: null,
    isMenuCategoryDeleting: false,
    menuCategoryDeleteError: null,
    arePositionsFetching: false,
    positions: [],
    menuName: null,
    menuCategoryName: null,
    categorySchedule: null,
    isHiddenWhenNotActive: false
};

export default function menuReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.MENUS_FETCHED: {
            const { menus } = action.payload;

            return {
                ...state,
                areMenusFetching: false,
                menus
            };
        }

        case ACTION_TYPES.MENUS_FETCHING: {
            return {
                ...state,
                areMenusFetching: true
            };
        }

        case ACTION_TYPES.MENUS_FETCH_FAILED: {
            return {
                ...state,
                areMenusFetching: false,
                menus: initialState.menus
            };
        }

        case ACTION_TYPES.MENU_FETCHED: {
            const menu = action.payload;

            return {
                ...state,
                isMenuFetching: false,
                menu
            };
        }

        case ACTION_TYPES.MENU_FETCHING: {
            return {
                ...state,
                isMenuFetching: true
            };
        }

        case ACTION_TYPES.MENU_FETCH_FAILED: {
            return {
                ...state,
                isMenuFetching: false,
                menu: initialState.menu
            };
        }

        case ACTION_TYPES.ADDING_MENU: {
            return {
                ...state,
                isMenuAdding: true,
                menuAddError: null
            };
        }

        case ACTION_TYPES.MENU_ADDED: {
            return {
                ...state,
                isMenuAdding: false,
                menuAddError: null
            };
        }

        case ACTION_TYPES.ADD_MENU_FAILED: {
            return {
                ...state,
                isMenuAdding: false,
                menuAddError: action.payload
            };
        }

        case ACTION_TYPES.EDITING_MENU: {
            return {
                ...state,
                isMenuEditing: true,
                menuEditError: null
            };
        }

        case ACTION_TYPES.MENU_EDITED: {
            return {
                ...state,
                isMenuEditing: false,
                menuEditError: null
            };
        }

        case ACTION_TYPES.EDIT_MENU_FAILED: {
            return {
                ...state,
                isMenuEditing: false,
                menuEditError: action.payload
            };
        }

        case ACTION_TYPES.DELETEING_MENU: {
            return {
                ...state,
                isMenuDeleting: true,
                menuDeleteError: null
            };
        }

        case ACTION_TYPES.MENU_DELETED: {
            return {
                ...state,
                isMenuDeleting: false,
                menuDeleteError: null
            };
        }

        case ACTION_TYPES.DELETE_MENU_FAILED: {
            return {
                ...state,
                isMenuDeleting: false,
                menuDeleteError: action.payload
            };
        }

        case ACTION_TYPES.MENU_CATEGORIES_FETCHED: {
            const { categories, menuName } = action.payload;

            return {
                ...state,
                areCategoriesFetching: false,
                categories,
                menuName
            };
        }

        case ACTION_TYPES.FETCHING_MENU_CATEGORIES: {
            return {
                ...state,
                areCategoriesFetching: true
            };
        }

        case ACTION_TYPES.MENU_CATEGORIES_FETCH_FAILED: {
            return {
                ...state,
                areCategoriesFetching: false,
                categories: initialState.categories
            };
        }

        case ACTION_TYPES.MENU_CATEGORY_FETCHED: {
            const menuCategory = action.payload;

            return {
                ...state,
                isMenuCategoryFetching: false,
                menuCategory
            };
        }

        case ACTION_TYPES.MENU_CATEGORY_FETCHING: {
            return {
                ...state,
                isMenuCategoryFetching: true
            };
        }

        case ACTION_TYPES.MENU_CATEGORY_FETCH_FAILED: {
            return {
                ...state,
                isMenuCategoryFetching: false,
                menuCategory: initialState.menuCategory
            };
        }

        case ACTION_TYPES.ADDING_MENU_CATEGORY: {
            return {
                ...state,
                isMenuCategoryAdding: true,
                menuCategoryAddError: null
            };
        }

        case ACTION_TYPES.MENU_CATEGORY_ADDED: {
            return {
                ...state,
                isMenuCategoryAdding: false,
                menuCategoryAddError: null
            };
        }

        case ACTION_TYPES.ADD_MENU_CATEGORY_FAILED: {
            return {
                ...state,
                isMenuCategoryAdding: false,
                menuCategoryAddError: action.payload
            };
        }

        case ACTION_TYPES.EDITING_MENU_CATEGORY: {
            return {
                ...state,
                isMenuCategoryEditing: true,
                menuCategoryEditError: null
            };
        }

        case ACTION_TYPES.MENU_CATEGORY_EDITED: {
            return {
                ...state,
                isMenuCategoryEditing: false,
                menuCategoryEditError: null
            };
        }

        case ACTION_TYPES.EDIT_MENU_CATEGORY_FAILED: {
            return {
                ...state,
                isMenuCategoryEditing: false,
                menuCategoryEditError: action.payload
            };
        }

        case ACTION_TYPES.DELETEING_MENU_CATEGORY: {
            return {
                ...state,
                isMenuCategoryDeleting: true,
                menuCategoryDeleteError: null
            };
        }

        case ACTION_TYPES.MENU_CATEGORY_DELETED: {
            return {
                ...state,
                isMenuCategoryDeleting: false,
                menuCategoryDeleteError: null
            };
        }

        case ACTION_TYPES.DELETE_MENU_CATEGORY_FAILED: {
            return {
                ...state,
                isMenuCategoryDeleting: false,
                menuCategoryDeleteError: action.payload
            };
        }

        case ACTION_TYPES.MENU_POSITIONS_FETCHED: {
            const {
                positions,
                menuName, menuCategoryName,
                isHiddenWhenNotActive,
                categorySchedule
            } = action.payload;

            return {
                ...state,
                arePositionsFetching: false,
                positions,
                menuName,
                menuCategoryName,
                isHiddenWhenNotActive,
                categorySchedule
            };
        }

        case ACTION_TYPES.FETCHING_MENU_POSTIONS: {
            return {
                ...state,
                arePositionsFetching: true
            };
        }

        case ACTION_TYPES.MENU_POSITION_FETCH_FAILED: {
            return {
                ...state,
                arePositionsFetching: false,
                positions: initialState.positions
            };
        }

        case ACTION_TYPES.MENU_REORDERED: {
            const { fromIndex, toIndex } = action.payload;

            return {
                ...state,
                menus: arrayMove(state.menus, fromIndex - 1, toIndex - 1)
            };
        }

        case ACTION_TYPES.MENU_REORDER_FAILED: {
            const { fromIndex, toIndex } = action.payload;

            return {
                ...state,
                menus: arrayMove(state.menus, toIndex - 1, fromIndex - 1)
            };
        }

        case ACTION_TYPES.MENU_CATEGORY_REORDERED: {
            const { fromIndex, toIndex } = action.payload;

            return {
                ...state,
                categories: arrayMove(state.categories, fromIndex - 1, toIndex - 1)
            };
        }

        case ACTION_TYPES.MENU_CATEGORY_REORDER_FAILED: {
            const { fromIndex, toIndex } = action.payload;

            return {
                ...state,
                categories: arrayMove(state.categories, toIndex - 1, fromIndex - 1)
            };
        }

        case ACTION_TYPES.MENU_POSITION_REORDERED: {
            const { fromIndex, toIndex } = action.payload;

            return {
                ...state,
                positions: arrayMove(state.positions, fromIndex - 1, toIndex - 1)
            };
        }

        case ACTION_TYPES.MENU_POSITION_REORDER_FAILED: {
            const { fromIndex, toIndex } = action.payload;

            return {
                ...state,
                positions: arrayMove(state.positions, toIndex - 1, fromIndex - 1)
            };
        }

        default: return state;
    }
}
