export const MODULE_NAME = 'area';

const prefix = `${MODULE_NAME}/`;

export const ACTION_TYPES = {
    FETCHING_AREAS: `${prefix}FETCHING_AREAS`,
    AREAS_FETCHED: `${prefix}AREAS_FETCHED`,
    AREAS_FETCH_FAILED: `${prefix}AREAS_FETCH_FAILED`,
    FETCHING_AREA: `${prefix}FETCHING_AREA`,
    AREA_FETCHED: `${prefix}AREA_FETCHED`,
    AREA_FETCH_FAILED: `${prefix}AREA_FETCH_FAILED`,
    ADDING_AREA: `${prefix}ADDING_AREA`,
    AREA_ADDED: `${prefix}AREA_ADDED`,
    ADD_AREA_FAILED: `${prefix}ADD_AREA_FAILED`,
    EDITING_AREA: `${prefix}EDITING_AREA`,
    AREA_EDITED: `${prefix}AREA_EDITED`,
    EDIT_AREA_FAILED: `${prefix}EDIT_AREA_FAILED`,
    DELETEING_AREA: `${prefix}DELETEING_AREA`,
    AREA_DELETED: `${prefix}AREA_DELETED`,
    DELETE_AREA_FAILED: `${prefix}DELETE_AREA_FAILED`,
    AREA_STATUS_CHANGE_FAILED: `${prefix}AREA_STATUS_CHANGE_FAILED`
};
