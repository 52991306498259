import ApiUrl from 'src/config/api';
import { encode } from '@aveneo/frontmoon-utils/lib/queryString';
import get from 'lodash/get';
import { selectUserToken } from 'src/modules/User';
import { ACTION_TYPES } from './constants';

export const autocomplete = ({
    id,
    fieldName,
    url,
    query,
    filterParameters,
    selectedIds,
    responseDataSelector
}) => async (dispatch, getState) => {
    if (!url) {
        throw new Error('PredefinedAutocomplete url is undefined. Add autocomplete actionUrl prop.');
    }

    const params = encode({ searchString: query, pageSize: 10, ...filterParameters, selectedIds });
    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/${url}?${params}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            throw new Error(response);
        }
    }).then((data) => {
        dispatch({
            type: ACTION_TYPES.AUTOCOMPLETE_FETCHED,
            payload: {
                id,
                fieldName,
                data: get(data, responseDataSelector || 'items')
            }
        });
    });
};

export const clearAutocomplete = ({ id }) => async (dispatch) => {
    dispatch({
        type: ACTION_TYPES.CLEAR_AUTOCOMPLETE,
        payload: id
    });
};
