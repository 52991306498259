import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import omit from 'lodash/omit';
import noop from 'lodash/noop';
import get from 'lodash/get';

import GenericAutocomplete from 'src/components/GenericAutocomplete';
import IconButton from 'src/components/IconButton';

import theme from 'src/styles/components/Autocomplete.module.scss';

class Autocomplete extends Component {
    constructor(props) {
        super(props);

        this.state = {
            query: ''
        };
    }

    componentDidUpdate() {
        if (this.autocomplete) {
            const autocompleteInput = get(this.autocomplete, 'suggestionsNode.previousSibling.childNodes[0]');
            if (autocompleteInput) {
                autocompleteInput.autoComplete = 'new-password';
            }
        }
    }

    handleInitialize = (query, value) => {
        this.setState({ query });
        this.props.onInitialize(query, value);
    }

    handleQueryChange = (query, value) => {
        this.setState({ query });
        this.props.onQueryChange(query, value);
    }

    onClear = () => {
        this.setState({ query: '' });

        this.props.onQueryChange('');
        this.props.onChange(null);
    }

    render() {
        const { className, disabled, disableClearIcon, label } = this.props;

        return (
            <div className={classnames(className, { [theme.container]: !disabled && !disableClearIcon, [theme.withLabel]: label })}>
                <GenericAutocomplete
                  {...omit(this.props, ['onClear', 'disableClearIcon'])}
                  onQueryChange={this.handleQueryChange}
                  onInitialize={this.handleInitialize}
                  query={this.state.query}
                  label={label}
                  customActions={(!disabled && !disableClearIcon) && (
                      <IconButton
                        icon="icon-close"
                        className={theme.clearIcon}
                        onClick={this.onClear}
                        mini
                      />
                  )}
                />
            </div>
        );
    }
}

Autocomplete.propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    disableClearIcon: PropTypes.bool,
    allowCreate: PropTypes.bool,
    onQueryChange: PropTypes.func,
    onInitialize: PropTypes.func,
    label: PropTypes.string
};

Autocomplete.defaultProps = {
    onChange: noop,
    disabled: false,
    disableClearIcon: false,
    allowCreate: false,
    onQueryChange: noop,
    onInitialize: noop
};

export default Autocomplete;
