import GoogleMapReact from 'google-map-react';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';

import MapMarker from 'src/components/MapMarker/MapMarker';

import theme from 'src/styles/components/GoogleMapReact.module.scss';

const GoogleMapReactComponent = ({ value, logoUrl, zoom, onChange, editable, center }) => (
    <div className={theme.map}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyDWzV_Wdnfg0qDNsGv7gddju6py8fVzzNA' }}
          center={{
            lat: value.latitude || center.lat,
            lng: value.longitude || center.lng
          }}
          zoom={zoom}
          options={{
            disableDefaultUI: true
           }}
          onClick={(event) => {
            onChange({ latitude: event.lat, longitude: event.lng });
          }}
          yesIWantToUseGoogleMapApiInternals
          draggable={editable}
          zoomControl={editable}
          scrollwheel={editable}
          disableDoubleClickZoom={!editable}
          mapElement={<div style={{ height: `300px`, width: `300px` }} />}
        >
            <MapMarker
              lat={value.latitude}
              lng={value.longitude}
              logo={logoUrl}
            />
        </GoogleMapReact>
    </div>
);

GoogleMapReactComponent.propTypes = {
    logoUrl: PropTypes.string.isRequired,
    zoom: PropTypes.number,
    value: PropTypes.shape({
        latitude: PropTypes.number.isRequired,
        longitude: PropTypes.number.isRequired
    }),
    onChange: PropTypes.func,
    editable: PropTypes.bool.isRequired,
    center: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number
    })
};

GoogleMapReactComponent.defaultProps = {
    zoom: 15,
    onChange: noop,
    center: {
        lat: 52.409538,
        lng: 16.931992
    }
};

export default GoogleMapReactComponent;
