import isArray from 'lodash/isArray';

class ApiError extends Error {
    constructor(error, name = 'apiError', ...props) {
        // Pass remaining arguments (including vendor specific ones) to parent constructor
        super(props);

        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ApiError);
        }

        // Custom debugging information
        this.name = name;
        try {
            const errorObject = JSON.parse(error);
            this.error = errorObject?.errors || errorObject;
        } catch {
            this.error = error?.errors || error;
        }

        if (isArray(this.error) && (this.error[0]?.metadata?.Code || this.error[0]?.metadata?.code)) {
            this.firstMessage = this.error[0]?.metadata?.Code || this.error[0]?.metadata?.code;

            return;
        }

        this.firstMessage = isArray(this.error)
            ? this.error[0].Message || this.error[0].message
            : this.error.Message || this.error.message;
    }
}

export default ApiError;
