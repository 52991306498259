import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Check from 'src/components/Check';

import theme from 'src/styles/components/Checkbox.module.scss';

class Checkbox extends Component {
    handleToggle = (event) => {
        if (event.pageX !== 0 && event.pageY !== 0) this.blur();
        if (!this.props.disabled && this.props.onChange) {
            this.props.onChange(!this.props.checked, event);
        }
    };

    blur() {
        if (this.inputNode) {
            this.inputNode.blur();
        }
    }

    focus() {
        if (this.inputNode) {
            this.inputNode.focus();
        }
    }

    render() {
        const { checked, children, disabled, label, name, style, onChange, error,
            onMouseEnter, onMouseLeave, containerClassName, ...others } = this.props;

        const className = classnames(theme.field, {
            [theme.disabled]: this.props.disabled
        }, this.props.className);

        return (
            <div className={classnames(containerClassName, theme.checkboxContainer, { [theme.error]: Boolean(error) })}>
                <label // eslint-disable-line jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control
                  data-sdk="checkbox"
                  className={className}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                >
                    <input
                      {...others}
                      checked={checked}
                      className={theme.input}
                      disabled={disabled}
                      name={name}
                      onChange={() => { }}
                      onClick={this.handleToggle}
                      ref={(node) => { this.inputNode = node; }}
                      type="checkbox"
                    />
                    <Check
                      checked={checked}
                      disabled={disabled}
                      rippleClassName={theme.ripple}
                      style={style}
                    />
                    {label ? <span data-sdk="label" className={theme.text}>{label}</span> : null}
                    {children}
                </label>
                {Boolean(error) && (
                    <div className={theme.errorDescription}>
                        {error}
                    </div>
                )}
            </div>
        );
    }
}

Checkbox.propTypes = {
    checked: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    containerClassName: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    name: PropTypes.string,
    onChange: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    style: PropTypes.object,
    error: PropTypes.string
};

Checkbox.defaultProps = {
    checked: false,
    className: '',
    disabled: false
};

export default Checkbox;
