export const MODULE_NAME = 'user';

const prefix = `${MODULE_NAME}/`;

export const ACTION_TYPES = {
    FINGERPRINT_INITIALIZED: `${prefix}FINGERPRINT_INITIALIZED`,
    REQUEST_LOGIN: `${prefix}REQUEST_LOGIN`,
    REQUEST_LOGIN_FAILED: `${prefix}REQUEST_LOGIN_FAILED`,
    LOGGED_IN: `${prefix}LOGGED_IN`,
    LOGIN_FAILED: `${prefix}LOGIN_FAILED`,
    LOGGING_IN: `${prefix}LOGING_IN`,
    RESTORE_SESSION: `${prefix}RESTORE_SESSION`,
    LOGOUT: `${prefix}LOGOUT`,
    RESTAURANT_FETCHED: `${prefix}RESTAURANT_FETCHED`,
    RESTAURANT_INITIALIZE_FAILED: `${prefix}RESTAURANT_INITIALIZE_FAILED`,
    GENERATE_SIGN_IN_CODE_SUCCESS: `${prefix}GENERATE_SIGN_IN_CODE_SUCCESS`,
    CLEAR_LOGIN_FORM: `${prefix}CLEAR_LOGIN_FORM`
};

export const AUTHENTICATION_METHOD = {
    EMAIL: 0,
    SMS: 1
};
