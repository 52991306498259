import { ACTION_TYPES, DEFAULT_TAB } from './constants';
import { createNotification } from './utils';

const initialState = {
    tables: [],
    allTables: [],
    tablesInitialised: false,
    activeTab: DEFAULT_TAB,
    filterStatus: false,
    selectedSessionId: null,
    tableOrdersFetched: false,
    details:
    {
        orders: [],
        price: 0,
        positionsNumber: 0
    },
    table: null,
    tableCodeAssigned: false,
    loggedinAssigned: false,
    isTableAdding: false,
    tableAddError: null,
    isTableEditing: false,
    tableEditError: null,
    isTableDeleting: false,
    tableDeleteError: null,
    closeBillError: null,
    notification: null,
    notificationDate: null,
    isInIdleMode: false
};

export default function tableReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.TABLES_FETCHED: {
            const {
                tables
            } = action.payload;

            const notification = createNotification({
                oldTables: state.tables,
                newTables: tables
            });

            return {
                ...state,
                tablesInitialised: true,
                tables,
                notification
            };
        }

        case ACTION_TYPES.ALL_TABLES_FETCHED: {
            const {
                tables
            } = action.payload;

            return {
                ...state,
                allTables: tables
            };
        }

        case ACTION_TYPES.TAB_SELECTION_CHANGED: {
            const {
                selectedTab
            } = action.payload;

            return {
                ...state,
                activeTab: selectedTab
            };
        }
        case ACTION_TYPES.SELECT_TABLE_CHANGED: {
            const {
                selectedSessionId
            } = action.payload;

            return {
                ...state,
                selectedSessionId
            };
        }
        case ACTION_TYPES.DELETING_TABLE: {
            return {
                ...state,
                isTableDeleting: true,
                tableDeleteError: null
            };
        }
        case ACTION_TYPES.TABLE_DELETED: {
            return {
                ...state,
                isTableDeleting: false,
                tableDeleteError: null
            };
        }
        case ACTION_TYPES.DELETING_TABLE_ERROR: {
            return {
                ...state,
                isTableDeleting: false,
                tableDeleteError: action.payload
            };
        }
        case ACTION_TYPES.TABLE_FILTER_CHANGED: {
            return {
                ...state,
                filterStatus: action.payload
            };
        }
        case ACTION_TYPES.CLEAR_TABLE: {
            return {
                ...state,
                table: initialState.table
            };
        }
        case ACTION_TYPES.TABLE_FETCHED: {
            return {
                ...state,
                table: action.payload
            };
        }
        case ACTION_TYPES.TABLE_DETAILS_FETCHED: {
            return {
                ...state,
                details: {
                    ...action.payload,
                    waiterCallDate: action.payload.waiterCallDate === null ? null : new Date(action.payload.waiterCallDate),
                    serverTime: new Date(action.payload.serverDate)
                },
                tableOrdersFetched: true
            };
        }
        case ACTION_TYPES.CLOSE_TABLE_DETAILS: {
            return {
                ...state,
                details: initialState.details,
                tableOrdersFetched: false
            };
        }

        case ACTION_TYPES.TABLE_CREATED: {
            return {
                ...state,
                isTableAdding: false,
                tableAddError: null
            };
        }

        case ACTION_TYPES.CREATE_TABLE: {
            return {
                ...state,
                isTableAdding: true,
                tableAddError: null
            };
        }

        case ACTION_TYPES.TABLE_CREATE_ERROR: {
            return {
                ...state,
                isTableAdding: false,
                tableAddError: action.payload
            };
        }

        case ACTION_TYPES.ASSIGN_CODE_TO_TABLE_SUCCESS: {
            return {
                ...state,
                tableCodeAssigned: true
            };
        }

        case ACTION_TYPES.ASSIGNED_LOGIN: {
            return {
                ...state,
                loggedinAssigned: true
            };
        }

        case ACTION_TYPES.CLEAR_ASSIGN_CODE_SUCCESS: {
            return {
                ...state,
                tableCreated: false,
                tableCodeAssigned: false,
                loggedinAssigned: false
            };
        }

        case ACTION_TYPES.EDITING_TABLE: {
            return {
                ...state,
                isTableEditing: true,
                tableEditError: null
            };
        }
        case ACTION_TYPES.EDITING_TABLE_SUCCESS: {
            return {
                ...state,
                isTableEditing: false,
                tableEditError: null
            };
        }
        case ACTION_TYPES.EDITING_TABLE_ERROR: {
            return {
                ...state,
                isTableEditing: false,
                tableEditError: action.payload
            };
        }

        case ACTION_TYPES.CLEAR_EDITING_TABLE: {
            return {
                ...state,
                edit: {
                    isSuccess: false,
                    isPending: false,
                    error: null
                },
                table: null
            };
        }

        case ACTION_TYPES.CLOSE_BILL_ERROR: {
            return {
                ...state,
                closeBillError: action.payload
            };
        }

        case ACTION_TYPES.CLEAR_CLOSE_BILL_ERROR: {
            return {
                ...state,
                closeBillError: initialState.closeBillError
            };
        }

        case ACTION_TYPES.PUSH_TABLE_NOTIFICATION: {
            return {
                ...state,
                notification: action.payload
            };
        }

        case ACTION_TYPES.SET_IDLE_MODE: {
            return {
                ...state,
                isInIdleMode: action.payload
            };
        }

        case ACTION_TYPES.BILL_PAY_ERROR: {
            return {
                ...state,
                closeBillError: action.payload
            };
        }

        default: return state;
    }
}
