import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import once from 'lodash/once';
import noop from 'lodash/noop';
import isEqual from 'lodash/isEqual';
import isString from 'lodash/isString';
import isArray from 'lodash/isArray';
import { isValuePresent } from 'src/utils/utils';

import Input from 'src/components/Input';
import Chip from 'src/components/Chip';

import theme from 'src/styles/components/Autocomplete.module.scss';

const POSITION = {
    AUTO: 'auto',
    DOWN: 'down',
    UP: 'up'
};

class GenericAutocomplete extends Component {
    initialize = once(() => {
        this.props.onInitialize(this.state.query, this.props.value);
    })

    constructor(props) {
        super(props);

        this.state = {
            direction: this.props.direction,
            focus: false,
            showAllSuggestions: this.props.showSuggestionsWhenValueIsSet,
            query: this.props.query ? this.props.query : this.query(this.value())
        };
    }

    shouldComponentUpdate({ value }, { focus, query }) {
        const hasFocusDisable = !focus && focus !== this.state.focus;
        if (hasFocusDisable && query === '') {
            const queryToUpdate = this.query(value);

            this.updateQuery(queryToUpdate, false);
        }

        if (!this.state.focus
            && focus
            && this.props.direction === POSITION.AUTO
        ) {
            const direction = this.calculateDirection();
            if (this.state.direction !== direction) {
                this.setState({ direction });
            }
        }

        if (!this.state.focus && value && !this.props.value) {
            const queryToUpdate = this.query(value);

            this.updateQuery(queryToUpdate, false);
        }

        return true;
    }

    componentDidUpdate(prevProps, prevState) {
        const hasValueCleared = !this.props.value && !isEqual(prevProps.value, this.props.value);

        if (hasValueCleared && !this.props.allowCreate && !this.props.multiple) {
            this.updateQuery('', true);

            return;
        }

        const hasFocusDisabled = !this.state.focus && prevState.focus !== this.state.focus;

        if (!this.props.query && prevProps.query !== this.props.query && !this.state.focus) {
            this.updateQuery(this.props.query, false);
        }

        if (hasFocusDisabled && this.state.query !== this.value()) {
            this.updateQuery(this.value(), true);
        }

        if (!hasFocusDisabled && !this.state.focus && this.state.query !== this.value()) {
            this.updateQuery(this.value(), true);
        }

        if (!isEqual(this.props.value, prevProps.value)) {
            this.props.onValueChanged(this.props.value);
        }
    }

    handleChange = (values, event) => {
        const value = this.props.multiple ? values : values[values.length - 1];
        const { showSuggestionsWhenValueIsSet: showAllSuggestions } = this.props;

        let item = null;

        if (this.props.allowCreate && this.props.multiple) {
            item = value;
        } else if (this.props.multiple) {
            item = value.map(this.mapObject);
        } else {
            item = this.object(value);
        }

        if (this.props.onChange) {
            this.props.onChange(item, event);
        }

        const query = this.query(value);

        if (this.props.keepFocusOnChange) {
            this.setState({ query, showAllSuggestions });
        } else {
            this.setState({ focus: false, query, showAllSuggestions }, () => {
                ReactDOM.findDOMNode(this) // eslint-disable-line react/no-find-dom-node
                    .querySelector('input')
                    .blur();
            });
        }

        this.updateQuery(query, this.props.query);
    };

    handleMouseDown = (event) => {
        this.selectOrCreateActiveItem(event);
    };

    handleClick = (event) => {
        this.selectOrCreateActiveItem(event);
    }

    handleQueryBlur = (event) => {
        if (this.state.focus) this.setState({ focus: false });
        if (this.props.onBlur) this.props.onBlur(event, this.state.active);
    };

    updateQuery = (query, notify) => {
        if (notify && this.props.onQueryChange) {
            this.props.onQueryChange(query, this.props.value);
        }

        this.setState({ query });
    };

    handleQueryChange = (value) => {
        const query = this.clearQuery ? '' : value;
        this.clearQuery = false;

        this.updateQuery(query, true);
        this.setState({
            showAllSuggestions: query
                ? false
                : this.props.showSuggestionsWhenValueIsSet,
            active: null
        });
    };

    handleQueryFocus = (event) => {
        event.target.scrollTop = 0; // eslint-disable-line no-param-reassign
        this.setState({ active: '', focus: true });
        if (this.props.onFocus) this.props.onFocus(event);

        this.initialize();
    };

    handleQueryKeyDown = (event) => {
        // Mark query for clearing in handleQueryChange when pressing backspace and showing all suggestions.
        this.clearQuery = event.which === 8
            && this.props.showSuggestionsWhenValueIsSet
            && this.state.showAllSuggestions;

        if (event.which === 13) {
            this.selectOrCreateActiveItem(event);
        }

        if (this.props.shouldAddOnSpace && (event.which === 32 || event.which === 188)) {
            this.selectOrCreateActiveItem(event);
        }

        if (!this.state.query && event.which === 8) {
            const valuesKeys = this.valuesKeys();
            this.unselect(event, valuesKeys[valuesKeys.length - 1]);
        }

        if (this.props.onKeyDown) this.props.onKeyDown(event);
    };

    handleQueryKeyUp = (event) => {
        if (event.which === 27) {
            ReactDOM.findDOMNode(this) // eslint-disable-line react/no-find-dom-node
                .querySelector('input')
                .blur();
        }

        if ([40, 38].indexOf(event.which) !== -1) {
            const suggestionsIds = this.source().map((item) => item[this.props.keyFieldName]);

            let index = suggestionsIds.indexOf(this.state.active)
                + (event.which === 40 ? +1 : -1);
            if (index < 0) {
                index = suggestionsIds.length - 1;
            }
            if (index >= suggestionsIds.length) {
                index = 0;
            }

            this.setState({ active: suggestionsIds[index] });
        }

        if (this.props.onKeyUp) this.props.onKeyUp(event);
    };

    handleSuggestionHover = (event) => {
        this.setState({ active: event.target.id });
    };

    select = (event, target) => {
        event.stopPropagation();
        event.preventDefault();

        const newKey = target === undefined ? event.target.id : target;
        const valuesKeys = this.valuesKeys();

        this.handleChange([...valuesKeys, newKey], event);
    };

    // eslint-disable-next-line eqeqeq
    object = (key) => this.source().find((item) => item[this.props.keyFieldName] == key) || null;

    mapObject = (key) => {
        const sourceObject = this.object(key);

        if (!sourceObject) {
            // eslint-disable-next-line eqeqeq
            return this.valuesObjects().find((item) => item[this.props.keyFieldName] == key) || null;
        }

        return sourceObject;
    }

    selectOrCreateActiveItem(event) {
        let target = this.state.active;

        if (!target && this.state.query) {
            if (this.props.allowCreate) {
                target = this.state.query;
            }

            const source = this.source()[0];
            if (source) {
                target = source[this.props.keyFieldName];
            }

            this.setState({ active: target });
        }

        this.select(event, target);
    }

    normalise(value) {
        // eslint-disable-next-line max-len
        const sdiak = 'áâäąáâäąččććççĉĉďđďđééěëēėęéěëēėęĝĝğğġġģģĥĥħħíîíîĩĩīīĭĭįįi̇ıĵĵķķĸĺĺļļŀŀłłĺľĺľňńņŋŋņňńŉóöôőøōōóöőôøřřŕŕŗŗššśśŝŝşşţţťťŧŧũũūūŭŭůůűűúüúüűųųŵŵýyŷŷýyžžźźżżß';
        // eslint-disable-next-line max-len
        const bdiak = 'AAAAAAAACCCCCCCCDDDDEEEEEEEEEEEEEGGGGGGGGHHHHIIIIIIIIIIIIIIJJKKKLLLLLLLLLLLLNNNNNNNNNOOOOOOOOOOOORRRRRRSSSSSSSSTTTTTTUUUUUUUUUUUUUUUUUWWYYYYYYZZZZZZS';

        let normalised = '';
        for (let p = 0; p < value.length; p += 1) {
            if (sdiak.indexOf(value.charAt(p)) !== -1) {
                normalised += bdiak.charAt(sdiak.indexOf(value.charAt(p)));
            } else {
                normalised += value.charAt(p);
            }
        }

        return normalised.toLowerCase().trim();
    }

    query(key) {
        const { valueFieldName } = this.props;

        let queryValue = '';
        if (!this.props.multiple && isValuePresent(key)) {
            // eslint-disable-next-line eqeqeq
            const sourceObject = this.source().find((item) => item[this.props.keyFieldName] == key);
            const sourceValue = sourceObject && sourceObject[valueFieldName];

            queryValue = sourceValue || key;
        }

        return queryValue;
    }

    valuesObjects() {
        if (this.props.value && this.props.multiple) {
            return this.props.value;
        }

        if (this.props.value && !this.props.multiple) {
            return [this.props.value];
        }

        return [];
    }

    value() {
        if (isArray(this.props.value)) {
            return '';
        }

        return isString(this.props.value)
            ? this.props.value
            : this.props.value[this.props.valueFieldName];
    }

    values() {
        return this.valuesObjects().map((item) => item[this.props.valueFieldName]);
    }

    valuesKeys() {
        return this.valuesObjects().map((item) => item[this.props.keyFieldName]);
    }

    source() {
        const { source } = this.props;

        if (source.hasOwnProperty('length')) { // eslint-disable-line no-prototype-builtins
            return source;
        }

        return Object.keys(source).map((key) => ({ [key]: source[key] }));
    }

    calculateDirection() {
        if (this.props.direction === 'auto') {
            const client = ReactDOM.findDOMNode(this.input).getBoundingClientRect(); // eslint-disable-line react/no-find-dom-node
            const screenHeight = window.innerHeight || document.documentElement.offsetHeight;
            const up = client.top > screenHeight / 2 + client.height;

            return up ? 'up' : 'down';
        }

        return this.props.direction;
    }

    unselect(event, key) {
        if (!this.props.disabled) {
            this.handleChange(this.valuesKeys().filter((valueKey) => valueKey !== key), event);
        }
    }

    mapToObject(map) {
        return Array.from(map).reduce((obj, [k, value]) => {
            obj[k] = value; // eslint-disable-line no-param-reassign
            return obj;
        }, {});
    }

    renderSelected() {
        const { keyFieldName, valueFieldName, multiple } = this.props;

        if (multiple) {
            const selectedItems = this.valuesObjects().map((item) => (
                <Chip
                  key={item[keyFieldName]}
                  className={theme.value}
                  deletable
                  onDeleteClick={(e) => this.unselect(e, item[keyFieldName])}
                  isLarge
                >
                    {item[valueFieldName]}
                </Chip>
            ));

            if (!selectedItems.length) {
                return null;
            }

            return <ul className={theme.multipleValues}>{selectedItems}</ul>;
        }
    }

    renderSuggestions() {
        const { keyFieldName, valueFieldName, areSuggestionsPinned } = this.props;

        const source = this.source();

        if (!source.length) {
            return null;
        }

        const suggestions = source.map((item) => {
            const className = classnames(theme.suggestion, {
                [theme.selected]: this.state.active === item[keyFieldName]
            });

            return (
                <li
                  id={item[keyFieldName]}
                  key={item[keyFieldName]}
                  className={className}
                  onMouseDown={this.handleMouseDown}
                  onMouseOver={this.handleSuggestionHover}
                  onFocus={this.handleSuggestionHover}
                  tabIndex={0}
                  role="option"
                  aria-selected="false"
                >
                    {item[valueFieldName]}
                </li>
            );
        });

        return (
            <ul
              className={classnames(theme.values, {
                [theme.up]: this.state.direction === 'up',
                [theme.pinned]: areSuggestionsPinned
              })}
            >
                {suggestions}
            </ul>
        );
    }

    render() {
        const {
            allowCreate,
            error,
            label,
            source,
            suggestionMatch,
            query,
            selectedPosition,
            keepFocusOnChange,
            showSuggestionsWhenValueIsSet,
            showSelectedWhenNotInSource,
            showAllSuggestions,
            areSuggestionsPinned,
            onQueryChange,
            valueFieldName,
            keyFieldName,
            onInitialize,
            onValueChanged,
            customActions,
            shouldAddOnSpace,
            ...other
        } = this.props;

        const className = classnames(
            theme.autocomplete,
            {
                [theme.active]: this.state.focus
            },
            this.props.className
        );

        return (
            <div data-sdk="autocomplete" className={className}>
                {this.props.selectedPosition === 'above'
                    ? this.renderSelected()
                    : null}
                <Input
                  {...other}
                  inputRef={(node) => {
                      this.input = node;
                  }}
                  autoComplete="off"
                  className={classnames(theme.value, theme.multiple)}
                  error={error}
                  label={label}
                  onBlur={this.handleQueryBlur}
                  onChange={this.handleQueryChange}
                  onFocus={this.handleQueryFocus}
                  onKeyDown={this.handleQueryKeyDown}
                  onKeyUp={this.handleQueryKeyUp}
                  value={this.state.query || ''}
                >
                    {this.props.selectedPosition === 'inside'
                        ? this.renderSelected()
                        : null}
                    {customActions}
                </Input>
                {this.renderSuggestions()}
                {this.props.selectedPosition === 'below'
                    ? this.renderSelected()
                    : null}
            </div>
        );
    }
}

GenericAutocomplete.propTypes = {
    allowCreate: PropTypes.bool,
    className: PropTypes.string,
    direction: PropTypes.oneOf(['auto', 'up', 'down']),
    disabled: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    keepFocusOnChange: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    multiple: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyUp: PropTypes.func,
    onQueryChange: PropTypes.func,
    onInitialize: PropTypes.func,
    query: PropTypes.string,
    selectedPosition: PropTypes.oneOf(['inside', 'above', 'below', 'none']),
    showSelectedWhenNotInSource: PropTypes.bool,
    showAllSuggestions: PropTypes.bool,
    showSuggestionsWhenValueIsSet: PropTypes.bool,
    source: PropTypes.arrayOf(PropTypes.object),
    suggestionMatch: PropTypes.oneOf([
        'disabled',
        'start',
        'anywhere',
        'word',
        'none'
    ]),
    value: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.object)
    ]),
    keyFieldName: PropTypes.string,
    valueFieldName: PropTypes.string,
    onValueChanged: PropTypes.func,
    areSuggestionsPinned: PropTypes.bool,
    customActions: PropTypes.node,
    shouldAddOnSpace: PropTypes.bool
};

GenericAutocomplete.defaultProps = {
    allowCreate: false,
    className: '',
    direction: 'auto',
    keepFocusOnChange: false,
    multiple: false,
    selectedPosition: 'inside',
    showSelectedWhenNotInSource: false,
    showSuggestionsWhenValueIsSet: false,
    areSuggestionsPinned: false,
    source: [],
    suggestionMatch: 'start',
    keyFieldName: 'key',
    valueFieldName: 'value',
    value: '',
    onInitialize: noop,
    onValueChanged: noop,
    shouldAddOnSpace: false
};

export default GenericAutocomplete;
