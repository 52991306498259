import withProps from '@aveneo/frontmoon-utils/lib/hocs/withProps';
import theme from 'src/styles/components/Input.module.scss';
import Input from './Input';

export default withProps(({ theme: overridedTheme }) => ({
    theme: ({
        ...overridedTheme,
        ...Object.keys(theme).reduce((result, key) => {
            if (overridedTheme || {}[key]) {
                return { ...result, [key]: `${theme[key]} ${overridedTheme[key]}` };
            }

            return { ...result, [key]: theme[key] };
        }, {})
    })
}))(Input);
