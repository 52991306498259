import reducer from './reducer';
import { MODULE_NAME } from './constants';

export {
    login,
    logout,
    initializeFingerprint,
    restoreUserSession,
    fetchRestaurantBySlug,
    fetchRestaurantByCode,
    clearCookies,
    passwordLogin,
    generateSignInCodeByUser,
    clearLoginForm
} from './actions';

export {
    selectFingerprint,
    selectUser,
    selectIsLoggingIn,
    selectIsLoggedIn,
    selectIsSessionRestored,
    selectUserToken,
    selectUserRestaurant,
    selectUserRestaurantStyles,
    selectIsUserRestaurantInitialized,
    selectUserRestaurantSlug,
    selectUserRestaurantId,
    selectRestaurantId,
    selectLoginError,
    selectIsRestaurantFetchFailed,
    selectRestaurantColors,
    selectUserContextRestaurantSlug,
    selectPrefferedUserLoginName,
    selectIsSignInCodeGenerated,
    selectSignInRestaurantId
} from './selectors';

export default () => ({
    [MODULE_NAME]: {
        reducer
    }
});
