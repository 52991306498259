/* eslint-disable no-underscore-dangle */
import '@formatjs/intl-locale/polyfill';

import appPl from 'src/resources/App/app.pl';
import appEn from 'src/resources/App/app.en';

import corePl from 'src/resources/Core/core.pl';
import coreEn from 'src/resources/Core/core.en';

import errorPl from 'src/resources/Error/error.pl';
import errorEn from 'src/resources/Error/error.en';

import userEn from 'src/resources/User/user.en';
import userPl from 'src/resources/User/user.pl';

import orderEn from 'src/resources/Order/order.en';
import orderPl from 'src/resources/Order/order.pl';

import tableEn from 'src/resources/Table/table.en';
import tablePl from 'src/resources/Table/table.pl';

import restaurantEn from 'src/resources/Restaurant/restaurant.en';
import restaurantPl from 'src/resources/Restaurant/restaurant.pl';

if (typeof Intl.NumberFormat.__addLocaleData === 'function') {
    Intl.NumberFormat.__addLocaleData(require('@formatjs/intl-numberformat/locale-data/pl.js'));
}

export const App = {
    en: appEn,
    pl: appPl
};

export const Core = {
    en: coreEn,
    pl: corePl
};

export const Error = {
    en: errorEn,
    pl: errorPl
};

export const User = {
    en: userEn,
    pl: userPl
};

export const Order = {
    en: orderEn,
    pl: orderPl
};

export const Table = {
    en: tableEn,
    pl: tablePl
};

export const Restaurant = {
    en: restaurantEn,
    pl: restaurantPl
};
