export const createNotification = ({
    oldTables,
    newTables
}) => {
    if (!newTables.some((table) => table.isActive)) {
        return null;
    }

    const activeTables = newTables.filter((table) => table.isActive);
    const isNewTableActive = activeTables.some((table) => oldTables.some((oldTable) => oldTable.id === table.id && !oldTable.isActive));

    if (isNewTableActive) {
        return {
            type: 'newTableActive'
        };
    }

    const isWaiterCalled = activeTables.some((table) => table.isWaiterCalled
        && oldTables.some((oldTable) => oldTable.id === table.id && !oldTable.isWaiterCalled));
    if (isWaiterCalled) {
        return {
            type: 'waiterCalled'
        };
    }

    const isBillClosed = activeTables.some((table) => table.isBillClosed
        && oldTables.some((oldTable) => oldTable.id === table.id && !oldTable.isBillClosed));
    if (isBillClosed) {
        return {
            type: 'billClosed'
        };
    }

    const hasOrdersCountChanged = activeTables.some((table) => table.ordersCount
        && oldTables.some((oldTable) => oldTable.id === table.id && oldTable.ordersCount !== table.ordersCount));

    if (hasOrdersCountChanged) {
        return {
            type: 'newOrder'
        };
    }

    return null;
};
