import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import moduleTheme from 'src/styles/components/Chip.module.scss';

const Chip = ({ children, className, deletable, isDark, isLarge, onDeleteClick, theme, ...other }) => {
    const classes = classnames(theme.chip, {
        [theme.deletable]: Boolean(deletable),
        [theme.dark]: isDark,
        [theme.large]: isLarge
    }, className);

    return (
        <div data-sdk="chip" className={classes} {...other}>
            {typeof children === 'string' ? <span>{children}</span> : children}
            {deletable ? (
                <span className={theme.delete} onClick={onDeleteClick} role="button" tabIndex={0}>
                    <svg viewBox="0 0 40 40" className={theme.deleteIcon}>
                        <path className={theme.deleteX} d="M 12,12 L 28,28 M 28,12 L 12,28" />
                    </svg>
                </span>
            ) : null}
        </div>
    );
};

Chip.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    deletable: PropTypes.bool,
    onDeleteClick: PropTypes.func,
    theme: PropTypes.shape({
        chip: PropTypes.string,
        deletable: PropTypes.string,
        delete: PropTypes.string,
        deleteIcon: PropTypes.string,
        deleteX: PropTypes.string,
        dark: PropTypes.string,
        large: PropTypes.string
    }),
    isDark: PropTypes.bool,
    isLarge: PropTypes.bool
};

Chip.defaultProps = {
    className: '',
    deletable: false,
    theme: moduleTheme
};

export default Chip;
