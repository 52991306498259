import React, { useCallback, useContext, useRef, useEffect } from 'react';
import DiContext from '@aveneo/frontmoon-di/lib';
import omit from 'lodash/omit';
import classnames from 'classnames';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import isUndefined from 'lodash/isUndefined';

import IconButton from 'src/components/IconButton';

import theme from 'src/styles/components/Input.module.scss';
import dropdownTheme from 'src/styles/components/Dropdown.module.scss';

const Dropdown = ({ className, disabled, disableClearIcon, onChange, value, ...props }) => {
    const dropdown = useRef();
    const context = useContext(DiContext);
    const { BaseDropdown } = context.container;

    useEffect(() => {
        dropdown.current.handleFocus = () => { };
    }, []);

    const handleClear = useCallback(() => {
        onChange(null);
    });

    return (
        <div className={classnames(className, { [theme.outerContainer]: !disabled && !disableClearIcon })}>
            <BaseDropdown
              {...omit(props, ['onClear'])}
              onChange={onChange}
              disabled={disabled}
              dropdownRef={dropdown}
              theme={theme}
              disableClearIcon={disableClearIcon}
              value={value}
            />
            {Boolean(!disabled && !disableClearIcon && !isUndefined(value)) && (
                <IconButton
                  icon="icon-close"
                  className={dropdownTheme.clearIcon}
                  onClick={handleClear}
                  mini
                />
            )}
        </div>
    );
};

Dropdown.propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    disableClearIcon: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};

Dropdown.defaultProps = {
    onChange: noop,
    disabled: false,
    disableClearIcon: false
};

export default Dropdown;
