import { ACTION_TYPES } from './constants';

export const emitMessage = ({
    type,
    addon,
    title,
    subtitle,
    message,
    messageCodename,
    actions,
    theme,
    position,
    onDismiss,
    onSuccess,
    isDismissButtonEnabled,
    className,
    contentClassName,
    isBackdropClickEnabled = true,
    isDefaultOkEnabled = false,
    isEscKeyDownEnabled = false,
    hasFixedContent = false,
    isFullscreenEnabled = false,
    shouldBeFirst = false
}) => async (dispatch) => {
    dispatch({
        type: ACTION_TYPES.ADDED_MESSAGE,
        payload: {
            type,
            addon,
            title,
            subtitle,
            message,
            messageCodename,
            theme,
            position,
            onDismiss,
            onSuccess,
            isDismissButtonEnabled,
            isDefaultOkEnabled,
            actions,
            isBackdropClickEnabled,
            isEscKeyDownEnabled,
            hasFixedContent,
            isFullscreenEnabled,
            className,
            contentClassName,
            shouldBeFirst
        }
    });
};

export const dismissFirstMessage = () => async (dispatch) => {
    dispatch({
        type: ACTION_TYPES.DISMISSED_FIRST_MESSAGE
    });
};

export const dismissAllMessages = () => async (dispatch) => {
    dispatch({
        type: ACTION_TYPES.DISMISS_ALL_MESSAGES
    });
};
