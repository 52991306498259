export const MODULE_NAME = 'tables';

const prefix = `${MODULE_NAME}/`;

export const ACTION_TYPES = {
    TABLES_FETCHED: `${prefix}TABLES_FETCHED`,
    ALL_TABLES_FETCH_FAILED: `${prefix}ALL_TABLES_FETCH_FAILED`,
    ALL_TABLES_FETCHED: `${prefix}ALL_TABLES_FETCHED`,
    TAB_SELECTION_CHANGED: `${prefix}TAB_SELECTION_CHANGED`,
    TABLE_DETAILS_FETCHED: `${prefix}TABLE_DETAILS_FETCHED`,
    SELECT_TABLE_CHANGED: `${prefix}SELECT_TABLE_CHANGED`,
    DELETING_TABLE: `${prefix}DELETING_TABLE`,
    TABLE_DELETED: `${prefix}TABLE_DELETED`,
    EDITING_TABLE: `${prefix}EDITING_TABLE`,
    CLEAR_EDITING_TABLE: `${prefix}CLEAR_EDITING_TABLE`,
    EDITING_TABLE_SUCCESS: `${prefix}EDITING_TABLE_SUCCESS`,
    EDITING_TABLE_ERROR: `${prefix}EDITING_TABLE_ERROR`,
    DELETING_TABLE_ERROR: `${prefix}DELETING_TABLE_ERROR`,
    TABLE_FILTER_CHANGED: `${prefix}TABLE_FILTER_CHANGED`,
    CLEAR_TABLE_DETAILS: `${prefix}CLEAR_TABLE_DETAILS`,
    TABLE_DETAIL_FETCHED: `${prefix}TABLE_DETAIL_FETCHED`,
    BILL_PAY: `${prefix}BILL_PAY`,
    BILL_PAID: `${prefix}BILL_PAID`,
    BILL_PAY_ERROR: `${prefix}BILL_PAY_ERROR`,
    ORDER_ACCEPT: `${prefix}ORDER_ACCEPT`,
    ORDER_ACCEPTED: `${prefix}ORDER_ACCEPTED`,
    ORDER_ACCEPT_ERROR: `${prefix}ORDER_ACCEPT_ERROR`,
    ORDER_COMPLETE: `${prefix}ORDER_COMPLETE`,
    ORDER_COMPLETED: `${prefix}ORDER_COMPLETED`,
    ORDER_COMPLETE_ERROR: `${prefix}ORDER_COMPLETE_ERROR`,
    CLOSE_TABLE_DETAILS: `${prefix}CLOSE_TABLE_DETAILS`,
    CLEAR_TABLE: `${prefix}CLEAR_TABLE`,
    TABLE_FETCHED: `${prefix}TABLE_FETCHED`,
    TABLE_FETCH_FAILED: `${prefix}TABLE_FETCH_FAILED`,
    WAITER_CALL_ACCEPT: `${prefix}WAITER_CALL_ACCEPT`,
    WAITER_CALL_ACCEPTED: `${prefix}WAITER_CALL_ACCEPTED`,
    WAITER_CALL_ACCEPT_ERROR: `${prefix}WAITER_CALL_ACCEPT_ERROR`,
    CREATE_TABLE: `${prefix}CREATE_TABLE`,
    TABLE_CREATED: `${prefix}TABLE_CREATED`,
    TABLE_CREATE_ERROR: `${prefix}TABLE_CREATE_ERROR`,
    ASSIGN_LOGIN: `${prefix}ASSIGN_LOGIN`,
    ASSIGNED_LOGIN: `${prefix}ASSIGNED_LOGIN`,
    ASSIGN_LOGIN_ERROR: `${prefix}ASSIGN_LOGIN_ERROR`,
    ASSIGN_CODE_TO_TABLE: `${prefix}ASSIGN_CODE_TO_TABLE`,
    ASSIGN_CODE_TO_TABLE_SUCCESS: `${prefix}ASSIGN_CODE_TO_TABLE_SUCCESS`,
    ASSIGN_CODE_TO_TABLE_FAILED: `${prefix}ASSIGN_CODE_TO_TABLE_FAILED`,
    CLEAR_ASSIGN_CODE_SUCCESS: `${prefix}CLEAR_ASSIGN_CODE_SUCCESS`,
    CLOSE_BILL_ERROR: `${prefix}CLOSE_BILL_ERROR`,
    CLEAR_CLOSE_BILL_ERROR: `${prefix}CLEAR_CLOSE_BILL_ERROR`,
    PUSH_TABLE_NOTIFICATION: `${prefix}PUSH_TABLE_NOTIFICATION`,
    SET_IDLE_MODE: `${prefix}SET_IDLE_MODE`
};

export const ORDER_STATUS = {
    PLACED: 0,
    ACCEPTED: 1,
    COMPLETED: 2
};

export const BILL_STATUS = {
    OPEN: 0,
    CLOSED: 1,
    PAID: 2,
    EXPIRED: 3
};

export const ORDER_CARD_STATUS = {
    WAITER_CALLED: 0,
    BILL: 1,
    ORDER: 2
};

export const DEFAULT_TAB = 'DEFAULT';
