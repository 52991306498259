import ApiUrl from 'src/config/api';
import { selectUserToken } from 'src/modules/User';
import ApiError from 'src/utils/error';
import { ACTION_TYPES } from './constants';

export const fetchRestaurant = () => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.FETCHING_RESTAURANT
    });

    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/restaurants`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then((response) => {
        dispatch({
            type: ACTION_TYPES.RESTAURANT_FETCHED,
            payload: response
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.RESTAURANT_FETCH_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const uploadPhoto = (image) => async (dispatch, getState) => {
    const token = selectUserToken(getState());

    const formData = new FormData();

    formData.append('Image', image);
    formData.append('Extension', image.name.split('.').pop());

    return fetch(`${ApiUrl}/api/restaurant/images`, {
        method: 'POST',
        headers: {
            'Authorization': token
        },
        body: formData
    }).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then((response) => {
        dispatch({
            type: ACTION_TYPES.ADD_IMAGE_SUCCESS,
            payload: response
        });

        return response?.id;
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.ADD_IMAGE_FAILED,
            payload: error?.firstMessage
        });

        return null;
    });
};

export const updateRestaurant = (values, restaurant) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.UPDATE_RESTAURANT
    });

    const token = selectUserToken(getState());

    let logoId = null;

    if (values.logo) {
        if (values.logo.id) {
            logoId = values.logo.id;
        } else {
            logoId = await uploadPhoto(values.logo)(dispatch, getState);
        }
    }

    if (values.logo === undefined) {
        logoId = restaurant.logoId;
    }

    let backgroundId = null;

    if (values.background) {
        if (values.background.id) {
            backgroundId = values.background.id;
        } else {
            backgroundId = await uploadPhoto(values.background)(dispatch, getState);
        }
    }

    if (values.background === undefined) {
        backgroundId = restaurant.backgroundId;
    }

    await fetch(`${ApiUrl}/api/restaurant/restaurants`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            ...restaurant,
            ...values,
            logoId,
            backgroundId
        })
    }).then((response) => {
        if (!response.ok) {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then((response) => {
        dispatch({
            type: ACTION_TYPES.UPDATE_RESTAURANT_SUCCESS,
            payload: response
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.UPDATE_RESTAURANT_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const clearRestaurantUpdateSuccess = () => async (dispatch) => {
    dispatch({
        type: ACTION_TYPES.CLEAR_RESTAUANT_UPDATE_SUCCESS
    });
};
