import omit from 'lodash/omit';

const mapProps = ({ input, error, ...props }) => {
    const customProps = { };

    if (error) {
        customProps.error = error;
    }

    return {
        ...input,
        ...omit(props, ['meta']),
        ...customProps,
        checked: Boolean(input.value)
    };
};

export default mapProps;
