import { ACTION_TYPES, MODULE_NAME, MESSAGE_TYPE } from './constants';
import reducer from './reducer';

export {
    selectFirstMessage
} from './selectors';

export {
    emitMessage,
    dismissFirstMessage,
    dismissAllMessages
} from './actions';

export {
    ACTION_TYPES,
    MODULE_NAME,
    MESSAGE_TYPE
};

export default () => ({
    [MODULE_NAME]: {
        reducer
    }
});
