import reduce from 'lodash/reduce';
import mapValues from 'lodash/mapValues';
import has from 'lodash/has';

import locales, { DEFAULT_LOCALE } from 'src/i18n/locales';
import * as mergedStrings from 'src/i18n/strings';

const extractStringsFromModule = (nestedStrings, moduleName) => reduce(nestedStrings, (result, stringValue, stringKey) => ({
    ...result,
    [`${moduleName}.${stringKey}`]: stringValue
}), {});

const flattenStrings = (strings) => reduce(strings, (result, nestedStrings, moduleName) => ({
    ...result,
    ...extractStringsFromModule(nestedStrings, moduleName)
}), {});

const stringsByLocale = (currentStrings, currentLocale) => {
    const prefixed = mapValues(currentStrings, (strings, moduleName) => {
        if (!has(strings, currentLocale)) {
            throw new Error(`Missing localized messages for: ${moduleName}, with given locale: ${currentLocale}.`);
        }

        return strings[currentLocale];
    });

    return flattenStrings(prefixed);
};

export function getAvailableLocale(locale, stack = Object.keys(locales)) {
    return stack.includes(locale) ? locale : DEFAULT_LOCALE;
}

export function getDefaultLocale() {
    const locale = typeof (navigator) && ((navigator.languages ? navigator.languages[0] : (navigator.language || navigator.userLanguage))
        .slice(0, 2)
        .toLowerCase());

    return getAvailableLocale(locale);
}

function getMessagesByLocale(locale) {
    const stringsMessages = stringsByLocale(mergedStrings, locale);

    return stringsMessages;
}

export function getDictionaryByLocale(selectedLocale) {
    const locale = getAvailableLocale(selectedLocale);

    return {
        locale,
        messages: getMessagesByLocale(locale),
        key: locale
    };
}

export function getTranslationsByKeys(localeNames, moduleName, keys) {
    const module = mergedStrings[moduleName];

    return localeNames.reduce((stack, localeName) => ({
        ...stack,
        [localeName]: keys.reduce((localeStack, key) => {
            const keyNormalized = `${key.charAt(0).toLowerCase()}${key.slice(1)}`;
            const value = module[localeName][keyNormalized];

            return {
                ...localeStack,
                [key]: value
            };
        }, {})
    }), {});
}
