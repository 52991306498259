import { ACTION_TYPES } from './constants';

const initialState = {
    position: null,
    isPositionFetching: false,
    settingsPosition: null,
    isSettingsPositionFetching: false,
    isPositionAdding: false,
    positionAddError: null,
    isPositionEditing: false,
    positionEditError: null,
    isPositionDeleting: false,
    positionDeleteError: null,
    breadcrumb: []
};

export default function menuPositionReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.FETCHING_MENU_POSITION: {
            return {
                ...state,
                isPositionFetching: true,
                position: initialState.position
            };
        }

        case ACTION_TYPES.MENU_POSITION_FETCHED: {
            return {
                ...state,
                isPositionFetching: false,
                position: action.payload
            };
        }

        case ACTION_TYPES.MENU_POSITION_FETCH_FAILED: {
            return {
                ...state,
                isPositionFetching: false,
                position: initialState.position
            };
        }

        case ACTION_TYPES.FETCHING_MENU_POSITION_SETTINGS: {
            return {
                ...state,
                isSettingsPositionFetching: true,
                settingsPosition: initialState.position
            };
        }

        case ACTION_TYPES.MENU_POSITION_SETTINGS_FETCHED: {
            return {
                ...state,
                isSettingsPositionFetching: false,
                settingsPosition: action.payload
            };
        }

        case ACTION_TYPES.MENU_POSITION_SETTINGS_FETCH_FAILED: {
            return {
                ...state,
                isSettingsPositionFetching: false,
                settingsPosition: initialState.position
            };
        }

        case ACTION_TYPES.ADDING_MENU_POSITION: {
            return {
                ...state,
                isPositionAdding: true,
                positionAddError: null
            };
        }

        case ACTION_TYPES.BREADCRUMB_FETCHED: {
            return {
                ...state,
                breadcrumb: action.payload.partsNames
            };
        }

        case ACTION_TYPES.MENU_POSITION_ADDED: {
            return {
                ...state,
                isPositionAdding: false,
                positionAddError: null
            };
        }

        case ACTION_TYPES.ADD_MENU_POSITION_FAILED: {
            return {
                ...state,
                isPositionAdding: false,
                positionAddError: action.payload
            };
        }

        case ACTION_TYPES.EDITING_MENU_POSITION: {
            return {
                ...state,
                isPositionEditing: true,
                positionEditError: null
            };
        }

        case ACTION_TYPES.MENU_POSITION_EDITED: {
            return {
                ...state,
                isPositionEditing: false,
                positionEditError: null
            };
        }

        case ACTION_TYPES.EDIT_MENU_POSITION_FAILED: {
            return {
                ...state,
                isPositionEditing: false,
                positionEditError: action.payload
            };
        }

        case ACTION_TYPES.DELETEING_MENU_POSITION: {
            return {
                ...state,
                isPositionDeleting: true,
                positionDeleteError: null
            };
        }

        case ACTION_TYPES.MENU_POSITION_DELETED: {
            return {
                ...state,
                isPositionDeleting: false,
                positionDeleteError: null
            };
        }

        case ACTION_TYPES.DELETE_MENU_POSITION_FAILED: {
            return {
                ...state,
                isPositionDeleting: false,
                positionDeleteError: action.payload
            };
        }

        case ACTION_TYPES.CLEAR_MENU_POSITION: {
            return {
                ...state,
                position: initialState.position
            };
        }

        default: return state;
    }
}
