import format from 'date-fns/format';
import { getTimezoneOffsetTimeFromTime } from 'src/utils/time';
import { WEEK_DAYS_SELECTION, HOURS_SELECTION, DATES_SELECTION } from 'src/modules/Menu/constants';

export function prepareScheduleForm(formData) {
    if (!formData.schedule) {
        return ({
            ...formData,
            weekDays: {
                option: WEEK_DAYS_SELECTION.EVERY_DAY,
                days: {
                    monday: false,
                    tuesday: false,
                    wednesday: false,
                    thursday: false,
                    friday: false,
                    saturday: false,
                    sunday: false
                }
            },
            hours: {
                option: HOURS_SELECTION.ALL_DAY,
                from: null,
                to: null,
                wholeDay: true
            },
            dates: {
                option: DATES_SELECTION.ALL_YEAR,
                from: null,
                to: null,
                wholeYear: true
            },
            years: {
                everyYear: true
            }
        });
    }

    const {
        schedule: {
            monday,
            tuesday,
            wednesday,
            thursday,
            friday,
            saturday,
            sunday,
            fromTime,
            toTime,
            fromDate,
            toDate,
            everyYear,
            wholeDay,
            wholeYear
        },
        ...restFormData
    } = formData;

    return ({
        ...restFormData,
        weekDays: {
            option: monday && tuesday && wednesday && thursday && friday && saturday && sunday
                ? WEEK_DAYS_SELECTION.EVERY_DAY
                : WEEK_DAYS_SELECTION.SELECTED,
            days: {
                monday,
                tuesday,
                wednesday,
                thursday,
                friday,
                saturday,
                sunday
            }
        },
        hours: {
            option: wholeDay
                ? HOURS_SELECTION.ALL_DAY
                : HOURS_SELECTION.FROM_TO,
            from: fromTime ? getTimezoneOffsetTimeFromTime(fromTime) : null,
            to: toTime ? getTimezoneOffsetTimeFromTime(toTime) : null,
            wholeDay
        },
        dates: {
            option: wholeYear
                ? DATES_SELECTION.ALL_YEAR
                : DATES_SELECTION.FROM_TO,
            from: fromDate ? format(new Date(fromDate), 'yyyy-MM-dd') : null,
            to: toDate ? format(new Date(toDate), 'yyyy-MM-dd') : null,
            wholeYear
        },
        years: {
            everyYear
        }
    });
}

export function prepareSchedule({
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    sunday,
    fromDate,
    toDate,
    ...schedule
}) {
    return {
        days: Object.entries({
            monday,
            tuesday,
            wednesday,
            thursday,
            friday,
            saturday,
            sunday
        }).reduce(
            (result, [key, val]) => val
                ? [...result, key]
                : result,
            []
        ),
        isEveryDay: monday && tuesday && wednesday && thursday && friday && saturday && sunday,
        fromDate: fromDate ? new Date(fromDate) : null,
        toDate: toDate ? new Date(toDate) : null,
        ...schedule
    };
}
