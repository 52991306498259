import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DiContext from '@aveneo/frontmoon-di/lib';
import omit from 'lodash/omit';
import { injectIntl } from 'react-intl';

const isAuthenticated = (shouldRedirect = true) => (WrappedComponent) => {
    class IsAuthenticated extends Component {
        componentDidMount() {
            const { isLoggedIn } = this.props;

            if (!isLoggedIn && shouldRedirect) {
                this.redirect();
            }
        }

        componentDidUpdate() {
            if (!this.props.isLoggedIn && shouldRedirect) {
                this.redirect();
            }
        }

        redirect() {
            const { router } = this.props;

            router.replace('/logout');
        }

        render() {
            const omitedProps = omit(this.props, ['isLoggedIn', 'staticContext', 'dispatch', 'history', 'router', 'intl']);

            return this.props.isLoggedIn ? <WrappedComponent {...omitedProps} /> : null;
        }
    }

    const IsAuthenticatedWithIntl = injectIntl(IsAuthenticated);

    IsAuthenticated.propTypes = {
        isLoggedIn: PropTypes.bool.isRequired,
        router: PropTypes.object.isRequired,
        intl: PropTypes.object.isRequired
    };

    IsAuthenticated.contextType = DiContext;

    return IsAuthenticatedWithIntl;
};

export default isAuthenticated;
