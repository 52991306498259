import reducer from './reducer';
import { MODULE_NAME, ORDER_STATUS } from './constants';
import OrderWebSocket from './orderWebSocket';
import SessionWebSocket from './sessionWebSocket';

export {
    increaseQuantity,
    decreaseQuantity,
    setSelectedMenuPosition,
    clearSelectedMenuPosition,
    clearAddOrderPositionErrorMessage,
    setAddonAsSelected,
    setAddonAsUnselected
} from './actions';

export {
    selectOrders,
    selectAreOrdersFetched,
    selectMenuPositionConfigurator,
    selectIsOrderPositionAdded,
    selectOrderSummary,
    selectOrderContext,
    shouldShouldShowAddOrderPositionErrorMessage,
    selectShouldCreateNewOrder,
    selectLastOrderStatus,
    selectIsOrderContextDefined,
    selectIsSubmitOrderPending,
    selectCanOrder,
    selectIsAnyPlacedyOrder,
    selectValidationErrors
} from './selectors';

export { ORDER_STATUS };

export default () => ({
    [MODULE_NAME]: {
        reducer,
        WebSockets: [OrderWebSocket, SessionWebSocket]
    }
});
