import reducer from './reducer';
import { MODULE_NAME } from './constants';

export {
    fetchAddons,
    fetchAddon,
    editAddon,
    deleteAddon,
    addAddon,
    addAddonToExistingGroup,
    fetchAddonSettings,
    updateAddonOrder
} from './actions';

export {
    selectAddons,
    selectAddon,
    selectAddonsSettings,
    selectDeleteAddonStatus,
    selectAddAddonStatus,
    selectEditAddonStatus,
    selectAddonSettings,
    selectAddonTile
} from './selectors';

export default () => ({
    [MODULE_NAME]: {
        reducer
    }
});
