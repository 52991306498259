export const isValuePresent = (value) => (
    value !== null
    && value !== undefined
    && value !== ''
    && !(typeof value === 'number' && Number.isNaN(value))
);

export const getViewport = () => ({
    height: window.innerHeight || document.documentElement.offsetHeight,
    width: window.innerWidth || document.documentElement.offsetWidth
});
