import reducer from './reducer';
import { MODULE_NAME } from './constants';

export {
    fetchAddonGroups,
    fetchAddonGroup,
    editAddonGroup,
    deleteAddonGroupFromExistingProduct,
    addAddonGroup,
    addAddonGroupToExistingProduct,
    updateAddonGroupOrder
} from './actions';

export {
    selectAddonGroups,
    selectAddonGroup,
    selectDeleteAddonGroupStatus,
    selectAddAddonGroupStatus,
    selectEditAddonGroupStatus
} from './selectors';

export default () => ({
    [MODULE_NAME]: {
        reducer
    }
});
