import WebSocket from 'src/utils/webSocket';
import isString from 'lodash/isString';

import { selectUserToken } from 'src/modules/User';

import { ACTION_TYPES } from './constants';

class TableDetailWebSocket extends WebSocket {
    receive(details) {
        if (isString(details)) {
            return;
        }

        this.dispatch({
            type: ACTION_TYPES.TABLE_DETAILS_FETCHED,
            payload: details
        });
    }
}

export default ({
    WebSocket: TableDetailWebSocket,
    options: {
        userSelector: selectUserToken
    },
    socketName: 'tableDetailWebSocket'
});
