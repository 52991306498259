export const MODULE_NAME = 'menuPosition';

const prefix = `${MODULE_NAME}/`;

export const ACTION_TYPES = {
    BREADCRUMB_FETCHED: `${prefix}BREADCRUMB_FETCHED`,
    FETCH_BREADCRUMB_FAILED: `${prefix}FETCH_BREADCRUMB_FAILED`,
    FETCHING_MENU_POSITION: `${prefix}FETCHING_MENU_POSITION`,
    MENU_POSITION_STATUS_CHANGE_FAILED: `${prefix}MENU_POSITION_STATUS_CHANGE_FAILED`,
    MENU_POSITION_FETCHED: `${prefix}MENU_POSITION_FETCHED`,
    MENU_POSITION_FETCH_FAILED: `${prefix}MENU_POSITION_FETCH_FAILED`,
    FETCHING_MENU_POSITION_SETTINGS: `${prefix}FETCHING_MENU_POSITION_SETTINGS`,
    MENU_POSITION_SETTINGS_FETCHED: `${prefix}MENU_POSITION_SETTINGS_FETCHED`,
    MENU_POSITION_SETTINGS_FETCH_FAILED: `${prefix}MENU_POSITION_SETTINGS_FETCH_FAILED`,
    DELETEING_MENU_POSITION: `${prefix}DELETEING_MENU_POSITION`,
    MENU_POSITION_DELETED: `${prefix}MENU_POSITION_DELETED`,
    DELETE_MENU_POSITION_FAILED: `${prefix}DELETE_MENU_POSITION_FAILED`,
    ADDING_MENU_POSITION: `${prefix}ADDING_MENU_POSITION`,
    MENU_POSITION_ADDED: `${prefix}MENU_POSITION_ADDED`,
    ADD_MENU_POSITION_FAILED: `${prefix}ADD_MENU_POSITION_FAILED`,
    EDITING_MENU_POSITION: `${prefix}EDITING_MENU_POSITION`,
    MENU_POSITION_EDITED: `${prefix}MENU_POSITION_EDITED`,
    EDIT_MENU_POSITION_FAILED: `${prefix}EDIT_MENU_POSITION_FAILED`,
    ADD_IMAGE: `${prefix}ADD_IMAGE`,
    ADD_IMAGE_SUCCESS: `${prefix}ADD_IMAGE_SUCCESS`,
    ADD_IMAGE_FAILED: `${prefix}ADD_IMAGE_FAILED`,
    CLEAR_MENU_POSITION: `${prefix}CLEAR_MENU_POSITION`
};
