import ApiUrl from 'src/config/api';
import { selectUserToken } from 'src/modules/User';
import ApiError from 'src/utils/error';
import { ACTION_TYPES } from './constants';

export const fetchAreas = () => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.FETCHING_AREAS
    });

    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/areas/`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then((response) => {
        dispatch({
            type: ACTION_TYPES.AREAS_FETCHED,
            payload: response
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.AREAS_FETCH_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const fetchArea = (id) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.FETCHING_AREA
    });

    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/areas/${id}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then((response) => {
        dispatch({
            type: ACTION_TYPES.AREA_FETCHED,
            payload: response
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.AREA_FETCH_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const addArea = (area) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.ADDING_AREA
    });

    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/areas/`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify(area)
    }).then((response) => {
        if (!response.ok) {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then(() => {
        dispatch({
            type: ACTION_TYPES.AREA_ADDED
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.ADD_AREA_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const editArea = (id, area) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.EDITING_AREA
    });

    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/areas/${id}`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify(area)
    }).then((response) => {
        if (!response.ok) {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then(() => {
        dispatch({
            type: ACTION_TYPES.AREA_EDITED
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.EDIT_AREA_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const deleteArea = ({ id }) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.DELETEING_AREA
    });

    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/areas/${id}`, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }).then((response) => {
        if (!response.ok) {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then(() => {
        dispatch({
            type: ACTION_TYPES.AREA_DELETED
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.DELETE_AREA_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const statusChange = ({ isActive, id }) => async (dispatch, getState, diContainer) => {
    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/areas/${id}/state`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            isActive
        })
    }).then((response) => {
        if (!response.ok) {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then(() => {
        fetchAreas()(dispatch, getState, diContainer);
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.AREA_STATUS_CHANGE_FAILED,
            payload: error?.firstMessage
        });
    });
};
