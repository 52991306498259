import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';

import { getDictionaryByLocale } from 'src/i18n/i18n';

const LocaleProvider = ({ children }) => {
    const { locale } = useRouter();
    const [shortLocale] = locale ? locale.split('-') : ['pl'];

    return (
        <IntlProvider
          key={shortLocale}
          {...getDictionaryByLocale(shortLocale)}
        >
            {children}
        </IntlProvider>
    );
};

LocaleProvider.propTypes = {
    children: PropTypes.object
};

// LocaleProvider.defaultProps = {
//     locale: getDefaultLocale()
// };

export default LocaleProvider;
