import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const FontIcon = ({ alt, children, className, value, ...other }) => (
    <span
      data-sdk="font-icon"
      aria-label={alt}
      className={classnames(className, { [value]: typeof value === 'string' })}
      {...other}
    >
        {typeof value !== 'string' && (value)}
        {children}
    </span>
);

FontIcon.propTypes = {
    alt: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ])
};

FontIcon.defaultProps = {
    alt: '',
    className: ''
};

export default FontIcon;
