import omit from 'lodash/omit';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';

const mapProps = ({ input, intl, ...props }) => {
    const customProps = { };

    const isTouched = get(props.meta, 'touched');
    const validationError = get(props.meta, 'error');

    if (validationError && isTouched && !props.error) {
        customProps.error = intl.formatMessage({ id: validationError });
    }

    return {
        ...input,
        ...omit(props, ['meta']),
        onFileChange: (files) => {
            const areFilesAvailable = isArray(files) && files.length;
            const areFileAvailable = isObject(files);

            if (areFilesAvailable || areFileAvailable) {
                input.onChange(files);
            } else {
                input.onChange(null);
            }
        },
        onBlur: (event) => {
            const files = event.target.value;

            const areFilesAvailable = isArray(files) && files.length;
            const areFileAvailable = isObject(files);

            input.onBlur(areFilesAvailable || areFileAvailable ? event : undefined);
        },
        ...customProps
    };
};

export default mapProps;
