import { createSelector } from 'reselect';

export const selectAddonGroups = (state) => state.addonGroup.addonGroups;

export const selectAddonGroup = (state) => state.addonGroup.addonGroup;

export const selectIsAddAddonGroupPending = (state) => state.addonGroup.isAddonGroupAdding;

export const selectAddAddonGroupError = (state) => state.addonGroup.addonGroupAddError;

export const selectIsEditAddonGroupPending = (state) => state.addonGroup.isAddonGroupEditing;

export const selectEditAddonGroupError = (state) => state.addonGroup.addonGroupEditError;

export const selectIsDeleteAddonGroupPending = (state) => state.addonGroup.isAddonGroupDeleting;

export const selectDeleteAddonGroupError = (state) => state.addonGroup.addonGroupDeleteError;

export const selectAddAddonGroupStatus = createSelector(
    selectIsAddAddonGroupPending,
    selectAddAddonGroupError,
    (isPending, error) => ({
        isPending,
        error
    })
);

export const selectEditAddonGroupStatus = createSelector(
    selectIsEditAddonGroupPending,
    selectEditAddonGroupError,
    (isPending, error) => ({
        isPending,
        error
    })
);

export const selectDeleteAddonGroupStatus = createSelector(
    selectIsDeleteAddonGroupPending,
    selectDeleteAddonGroupError,
    (isPending, error) => ({
        isPending,
        error
    })
);
