import { applyMiddleware, compose, createStore } from 'redux';
import makeMiddleware from './middleware';

const createExtendedStore = (reducers, initialState = {}, { di }) => {
    const middleware = makeMiddleware(di);
    const enhancers = [];

    let composeEnhancers = compose;
    if (
        process.env.NODE_ENV !== 'production'
        && typeof window === 'object'
        && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ) {
        composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});
    }

    const store = createStore(
        reducers,
        initialState,
        composeEnhancers(
            applyMiddleware(...middleware),
            ...enhancers
        )
    );

    store.asyncReducers = {};

    return store;
};

export default createExtendedStore;
