import createStore from 'src/store/createStore';

const createDefaultStore = ({ ...additionalParams }) => (preloadState = {}) => {
    const isServer = typeof window === 'undefined';

    if (isServer) {
        return createStore(
            () => {},
            preloadState,
            { ...additionalParams }
        );
    }

    const { persistStore, persistReducer } = require('redux-persist');
    const storage = require('redux-persist/lib/storage').default;

    const persistConfig = {
        key: 'nextjs',
        whitelist: ['fromClient'], // make sure it does not clash with server keys
        storage
    };

    const persistedReducer = persistReducer(persistConfig, () => {});
    const store = createStore(
        persistedReducer,
        preloadState,
        { ...additionalParams }
    );

    // eslint-disable-next-line no-underscore-dangle
    store.__persistor = persistStore(store); // Nasty hack

    return store;
};

export default createDefaultStore;
