import { ACTION_TYPES } from './constants';

export const pushNotification = ({
    type,
    title,
    message,
    theme,
    onDismiss,
    isDismissButtonEnabled,
    actions,
    timeout,
    shouldDismissOnMessageClick = true
}) => async (dispatch) => {
    dispatch({
        type: ACTION_TYPES.NOTIFICATION_PUSHED,
        payload: {
            type,
            title,
            message,
            theme,
            onDismiss,
            isDismissButtonEnabled,
            actions,
            timeout,
            shouldDismissOnMessageClick
        }
    });
};

export const dismissFirstNotification = () => async (dispatch) => {
    dispatch({
        type: ACTION_TYPES.NOTIFICATION_REMOVED
    });
};
