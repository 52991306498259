import omit from 'lodash/omit';
import { ACTION_TYPES } from './constants';

const initialState = {
    data: {}
};

export default function objectsReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.AUTOCOMPLETE_FETCHED: {
            const {
                id,
                data
            } = action.payload;

            return {
                ...state,
                data: {
                    ...state.data,
                    [id]: data
                }
            };
        }
        case ACTION_TYPES.CLEAR_AUTOCOMPLETE: {
            const data = omit(state.data, [`${action.payload}`]);

            return {
                ...state,
                data
            };
        }

        default: return state;
    }
}
