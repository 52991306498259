import PropTypes from 'prop-types';

import theme from 'src/styles/components/MarkerComponent.module.scss';

const MapMarker = ({
    color,
    backgroundColor,
    logo
}) => (
    <div className={theme.container} style={{ backgroundColor }}>
        <div className={theme.circle} style={{ backgroundColor: color }}>
            <img
              className={theme.image}
              src={logo || '/logo.svg'}
              alt="restaurant"
              onError={({ currentTarget }) => {
                // eslint-disable-next-line no-param-reassign
                currentTarget.onerror = null; // prevents looping
                // eslint-disable-next-line no-param-reassign
                currentTarget.src = '/logo.svg';
              }}
            />
        </div>
    </div>
);

MapMarker.propTypes = {
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
    logo: PropTypes.string
};

MapMarker.defaultProps = {
    color: '#DCA63D',
    backgroundColor: '#FFFFFF'
};

export default MapMarker;
