import WebSocket from 'src/utils/webSocket';
import isString from 'lodash/isString';

import { selectFingerprint } from 'src/modules/User';

import { ACTION_TYPES } from './constants';

class OrderWebSocket extends WebSocket {
    receive(orders) {
        if (isString(orders)) {
            return;
        }

        this.dispatch({
            type: ACTION_TYPES.ORDERS_FETCHED,
            payload: orders
        });
    }
}

export default ({
    WebSocket: OrderWebSocket,
    options: {
        userSelector: selectFingerprint
    },
    socketName: 'orderWebSocket'
});
