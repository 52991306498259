import jwtDecode from 'jwt-decode';
import { createSelector } from 'reselect';
import ApiUrl from 'src/config/api';
import { AUTHENTICATION_METHOD } from './constants';

export const selectFingerprint = (state) => state.user.fingerprint;

export const selectIsLoggingIn = (state) => state.user.isLoggingIn;

export const selectLoginError = (state) => state.user.loginError;

export const selectUser = (state) => state.user.user;

export const selectUserToken = (state) => `Bearer ${state.user?.user?.token}`;

export const selectUserContextRestaurantSlug = (state) => state.user?.user?.slug;

export const selectIsSessionRestored = (state) => state.user.isSessionRestored;

export const selectUserRestaurant = (state) => state.user.restaurant;

export const selectIsRestaurantFetchFailed = (state) => state.user.isRestaurantFetchFailed;

export const selectRestaurantColors = (state) => state.user.restaurant?.colors;

export const selectIsSignInCodeGenerated = (state) => state.user.isSignInCodeGenerated;

export const selectSignInRestaurantId = (state) => state.user.signInRestaurantId;

export const selectIsLoggedIn = createSelector(
    selectUser,
    (user) => Boolean(user)
);

export const selectUserRestaurantStyles = createSelector(
    selectUserRestaurant,
    (restaurant) => {
        if (!restaurant) {
            return {};
        }

        return {
            background: `url("${ApiUrl}/api/restaurant/restaurants/${restaurant.id}/images/${restaurant.backgroundId}")`,
            logo: `${ApiUrl}/api/restaurant/restaurants/${restaurant.id}/images/${restaurant.logoId}`
        };
    }
);

export const selectUserRestaurantSlug = createSelector(
    selectUserRestaurant,
    (restaurant) => restaurant?.slug
);

export const selectIsUserRestaurantInitialized = createSelector(
    selectUserRestaurant,
    (restaurant) => Boolean(restaurant)
);

export const selectRestaurantId = createSelector(
    selectUserRestaurant,
    (restaurant) => restaurant?.id
);

export const selectUserRestaurantId = createSelector(
    selectUser,
    (user) => {
        if (user) {
            const { sub } = jwtDecode(user.token);

            return sub;
        }

        return null;
    }
);

export const selectPrefferedUserLoginName = createSelector(
    selectUserRestaurant,
    (userRestaurant) => {
        if (userRestaurant?.authorizationMethod === AUTHENTICATION_METHOD.EMAIL) {
            return userRestaurant?.email || userRestaurant?.phoneNumber || '';
        }

        if (userRestaurant?.authorizationMethod === AUTHENTICATION_METHOD.SMS) {
            return userRestaurant?.phoneNumber || userRestaurant?.email || '';
        }

        return userRestaurant?.email || userRestaurant?.phoneNumber || '';
    }
);
