import { getUrl } from 'src/utils/image';
import { createSelector } from 'reselect';
import { getTimezoneOffsetTimeFromTime } from 'src/utils/time';
import { prepareScheduleForm } from 'src/utils/schedule';
import { DEFAULT_COLORS } from './constants';

export const selectRestaurantDetails = (state) => state.restaurant.details;

export const selectRestaurantDetailsFeatched = (state) => state.restaurant.restaurantFetchedSuccessfuly;

export const selectRestaurantUpdateSuccess = (state) => state.restaurant.restaurantUpdateSuccess;

export const selectRestaurantUpdateError = (state) => state.restaurant.restaurantUpdateError;

export const selectRestaurantBackground = createSelector(
    selectRestaurantDetails,
    (restaurant) => {
        if (restaurant && restaurant.backgroundId) {
            return getUrl(restaurant.restaurantId, restaurant.backgroundId);
        }

        return null;
    }
);

export const selectRestaurantGallery = createSelector(
    selectRestaurantBackground,
    (background) => background != null ? [{
        image: null,
        thumbnail: background
    }] : []
);

export const selectRestaurantDetailsWithFormattedTime = createSelector(
    selectRestaurantDetails,
    (details) => {
        if (details?.schedule) {
            const {
                schedule: {
                    monday,
                    tuesday,
                    wednesday,
                    thursday,
                    friday,
                    saturday,
                    sunday,
                    fromDate,
                    toDate,
                    ...schedule
                }
            } = details;

            return ({
                ...details,
                schedule: {
                    days: Object.entries({
                        monday,
                        tuesday,
                        wednesday,
                        thursday,
                        friday,
                        saturday,
                        sunday
                    }).reduce(
                        (result, [key, val]) => val
                            ? [...result, key]
                            : result,
                        []
                    ),
                    isEveryDay: monday && tuesday && wednesday && thursday && friday && saturday && sunday,
                    fromDate: fromDate ? new Date(fromDate) : null,
                    toDate: toDate ? new Date(toDate) : null,
                    ...schedule
                },
                toTime: details?.schedule?.toTime ? getTimezoneOffsetTimeFromTime(details.schedule.toTime) : null,
                fromTime: details?.schedule?.fromTime ? getTimezoneOffsetTimeFromTime(details.schedule.fromTime) : null,
                colors: (!details.colors || !details.colors?.primaryColor || !details.colors?.secondaryColor)
                    ? {
                        secondaryColor: DEFAULT_COLORS.SECONDARY_COLOR,
                        primaryColor: DEFAULT_COLORS.PRIMARY_COLOR
                    }
                    : details.colors
            });
        }

        return details;
    }
);

export const selectRestaurantFormData = createSelector(
    selectRestaurantDetails,
    (details) => {
        if (details) {
            const form = prepareScheduleForm(details);

            return {
                logo: form.logoId
                    ? { id: form.logoId, name: `${form.logoId}.jpg` }
                    : null,
                background: form.backgroundId
                    ? { id: form.backgroundId, name: `${form.backgroundId}.jpg` }
                    : null,
                ...form
            };
        }
        return details;
    }
);
