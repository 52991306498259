import reducer from './reducer';
import { ACTION_TYPES, MODULE_NAME, DEFAULT_TAB, ORDER_STATUS, BILL_STATUS, ORDER_CARD_STATUS } from './constants';
import TablesWebSocket from './tablesWebSocket';
import TableDetailWebSocket from './tableDetailWebSocket';

export {
    connectTableSocket,
    disconnectTableSocket,
    connectTableDeatilSocket,
    disconnectTableDeatilSocket,
    setTab,
    setActiveTable,
    deleteTable,
    setTableFilter,
    setOrderAsAccepted,
    setOrderAsCompleted,
    closeTableDetails,
    editTable,
    setWaiterCallAsAccepted,
    createTable,
    assignCodeToLogin,
    assignCodeToTable,
    clearAssignTable,
    clearEditedTable,
    fetchTables,
    closeSession,
    clearCloseBillError,
    pushTableNotification,
    setIdleMode,
    getTable
} from './actions';

export {
    selectTables,
    selectTabs,
    selectActiveTab,
    selectActionCount,
    selectTableFilterStatus,
    selectTableOrders,
    selectAreTableOrdersFetched,
    selectOrderContext,
    selectTableDetails,
    selectTable,
    selectTableActions,
    selectCallWaiterDate,
    selectTableCreated,
    selectCodeAssignedSuccessfuly,
    selectTableEditSuccess,
    selectServerTime,
    selectAddTableStatus,
    selectDeleteTableStatus,
    selectEditTableStatus,
    selectCloseBillError,
    selectTableBill,
    selectAllTables,
    selectTablesNotifcation,
    selectShouldWaiterDoSomething,
    selectIsInIdleMode,
    selectSelectedSessionId,
    selectSelectedTable,
    selectPaymentMethod
} from './selectors';

export {
    ACTION_TYPES,
    MODULE_NAME,
    DEFAULT_TAB,
    ORDER_STATUS,
    BILL_STATUS,
    ORDER_CARD_STATUS
};

export default () => ({
    [MODULE_NAME]: {
        reducer,
        WebSockets: [TablesWebSocket, TableDetailWebSocket]
    }
});
