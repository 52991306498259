import env from '@beam-australia/react-env';

const CONFIG = {
    API_URL: env('API_URL'),
    API_VERSION: ''
};

export function getApiUrl() {
    return CONFIG.API_URL;
}

export default getApiUrl();
