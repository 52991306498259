import isAfter from 'date-fns/isAfter';
import ApiError from 'src/utils/error';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import ApiUrl from 'src/config/api';
import { ACTION_TYPES } from './constants';
import { selectUserToken } from './selectors';

export const initializeFingerprint = (code) => async (dispatch) => {
    dispatch({
        type: ACTION_TYPES.FINGERPRINT_INITIALIZED,
        payload: code
    });
};

let expiriationTimeTimeout = null;

function encryptToken(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');

    return JSON.parse(atob(base64));
}

function getCookie(name) {
    return document.cookie.split(';').find((c) => c.trim().startsWith(`${name}=`));
}

function deleteCookie(name, path, domain) {
    if (getCookie(name)) {
        document.cookie = `${name}=${
            (path) ? `;path=${path}` : ''
        }${
            (domain) ? `;domain=${domain}` : ''
        };expires=Thu, 01 Jan 1970 00:00:01 GMT`;
    }
}

function setCookie(cname, cvalue, exDate) {
    const expires = `expires=${exDate.toUTCString()}`;
    document.cookie = `${cname}=${cvalue};${expires};path=/`;
}

export const clearCookies = () => {
    deleteCookie('application', '/');
    deleteCookie('waiterLogged', '/');
};

export const logout = () => async (dispatch) => {
    localStorage.removeItem('user');

    if (expiriationTimeTimeout) {
        clearTimeout(expiriationTimeTimeout);
    }

    if (window) {
        clearCookies();
    }

    dispatch({
        type: ACTION_TYPES.LOGOUT
    });
};

const AUTO_LOGOUT_INTERVAL = 60 * 60 * 1000;

function autoLogout(dispatch, user) {
    const now = new Date();
    if (expiriationTimeTimeout) {
        clearTimeout(expiriationTimeTimeout);
    }

    if (user) {
        const expiriationTime = differenceInMilliseconds(new Date(user?.exp * 1000), now);

        if (expiriationTime > 0) {
            const timeout = expiriationTime > AUTO_LOGOUT_INTERVAL
                ? AUTO_LOGOUT_INTERVAL
                : expiriationTime;

            expiriationTimeTimeout = setTimeout(() => {
                autoLogout(dispatch, user);
            }, timeout);
        } else {
            logout()(dispatch);

            expiriationTimeTimeout = null;
        }
    }
}

function handleSuccessfulLogin({ user, loginCode }, dispatch) {
    localStorage.setItem('user', JSON.stringify(user));
    const userTokenData = encryptToken(user.token);

    autoLogout(dispatch, userTokenData);

    setCookie('waiterLogged', loginCode, new Date(userTokenData.exp * 1000));

    dispatch({
        type: ACTION_TYPES.LOGGED_IN,
        payload: {
            ...user,
            role: userTokenData['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'],
            email: userTokenData['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'],
            loginCode,
            slug: userTokenData.name
        }
    });
}

export const login = (signinCode, loginCode = '', signInRestaurantId) => async (dispatch) => {
    dispatch({
        type: ACTION_TYPES.LOGGING_IN
    });

    await fetch(`${ApiUrl}/api/restaurant/restaurants/jwttokens`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
            signinCode,
            restaurantId: signInRestaurantId
        })
    }).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then((user) => {
        handleSuccessfulLogin({ user, loginCode }, dispatch);
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.LOGIN_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const restoreUserSession = () => async (dispatch) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const encryptedToken = user && encryptToken(user.token);

    const restoredUser = user && isAfter(new Date(encryptedToken.exp * 1000), new Date())
        ? {
            ...user,
            role: encryptedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'],
            email: encryptedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'],
            slug: encryptedToken.name
        }
        : null;

    autoLogout(dispatch, encryptedToken);

    dispatch({
        type: ACTION_TYPES.RESTORE_SESSION,
        payload: restoredUser
    });
};

export const fetchRestaurantBySlug = (slug) => async (dispatch) => {
    await fetch(`${ApiUrl}/api/restaurant/restaurantSessions`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            restaurantName: slug
        })
    }).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            throw new Error(response);
        }
    }).then((response) => {
        dispatch({
            type: ACTION_TYPES.RESTAURANT_FETCHED,
            payload: response
        });
    }).catch(() => {
        dispatch({
            type: ACTION_TYPES.RESTAURANT_INITIALIZE_FAILED
        });
        dispatch({
            type: ACTION_TYPES.LOGOUT
        });
    });
};

export const fetchRestaurantByCode = (code) => async (dispatch, getState) => {
    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/restaurantSessions`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            code
        })
    }).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            throw new Error(response);
        }
    }).then((response) => {
        dispatch({
            type: ACTION_TYPES.RESTAURANT_FETCHED,
            payload: response
        });
    }).catch(() => {
        dispatch({
            type: ACTION_TYPES.RESTAURANT_INITIALIZE_FAILED
        });
    });
};

export const passwordLogin = (restaurantSlug, password) => async (dispatch) => {
    dispatch({
        type: ACTION_TYPES.LOGGING_IN
    });

    await fetch(`${ApiUrl}/api/administrators/jwtTokens`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
            restaurantSlug,
            password
        })
    }).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then((user) => {
        handleSuccessfulLogin({ user, loginCode: '' }, dispatch);
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.LOGIN_FAILED,
            payload: error?.firstMessage || 'invalidSlugOrPassword'
        });
    });
};

export const generateSignInCodeByUser = ({ email, phoneNumber, authorizationMethod }) => async (dispatch) => {
    dispatch({
        type: ACTION_TYPES.LOGGING_IN
    });

    await fetch(`${ApiUrl}/api/restaurant/restaurants/signinCodes`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
            email,
            phoneNumber,
            authorizationMethod
        })
    }).then((response) => {
        if (!response.ok) {
            return response.json().then((json) => { throw new ApiError(json); });
        }

        return response.json();
    }).then((restaurantId) => {
        dispatch({
            type: ACTION_TYPES.GENERATE_SIGN_IN_CODE_SUCCESS,
            payload: restaurantId
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.LOGIN_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const clearLoginForm = () => async (dispatch) => {
    dispatch({
        type: ACTION_TYPES.CLEAR_LOGIN_FORM
    });
};
