import omit from 'lodash/omit';
import { ACTION_TYPES } from './constants';

const initialState = {
    fingerprint: null,
    isSessionRestored: false,
    isLoggingIn: false,
    loginError: null,
    user: null,
    restaurant: null,
    initialized: false,
    isRestaurantFetchFailed: false,
    isSignInCodeGenerated: false,
    signInRestaurantId: null
};

export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.FINGERPRINT_INITIALIZED: {
            return {
                ...state,
                fingerprint: action.payload
            };
        }

        case ACTION_TYPES.LOGGING_IN: {
            return {
                ...state,
                isLoggingIn: true
            };
        }

        case ACTION_TYPES.LOGGED_IN: {
            return {
                ...state,
                isLoggingIn: false,
                user: action.payload
            };
        }

        case ACTION_TYPES.LOGIN_FAILED: {
            return {
                ...state,
                isLoggingIn: false,
                loginError: action.payload || 'invalidCode'
            };
        }

        case ACTION_TYPES.RESTORE_SESSION: {
            return {
                ...state,
                isSessionRestored: true,
                user: action.payload
            };
        }

        case ACTION_TYPES.RESTAURANT_FETCHED: {
            return {
                ...state,
                restaurant: {
                    ...action.payload.restaurant,
                    routeType: action.payload.routeType,
                    tableId: action.payload.tableId
                }
            };
        }

        case ACTION_TYPES.LOGOUT: {
            const logoutState = omit(initialState, [
                'isLoggingIn',
                'isSessionRestored',
                'isRestaurantFetchFailed'
            ]);

            return {
                ...state,
                ...logoutState
            };
        }

        case ACTION_TYPES.RESTAURANT_INITIALIZE_FAILED: {
            return {
                ...state,
                restaurant: initialState.restaurant,
                isRestaurantFetchFailed: true
            };
        }

        case ACTION_TYPES.GENERATE_SIGN_IN_CODE_SUCCESS: {
            return {
                ...state,
                loginError: initialState.loginError,
                signInRestaurantId: action.payload,
                isSignInCodeGenerated: true,
                isLoggingIn: false
            };
        }

        case ACTION_TYPES.CLEAR_LOGIN_FORM: {
            return {
                ...state,
                loginError: initialState.loginError,
                isSignInCodeGenerated: initialState.isSignInCodeGenerated,
                isLoggingIn: initialState.isLoggingIn,
                signInRestaurantId: initialState.signInRestaurantId
            };
        }

        default: return state;
    }
}
