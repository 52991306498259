import { createSelector } from 'reselect';
import get from 'lodash/get';
import { DEFAULT_TAB, ORDER_CARD_STATUS } from './constants';

export const selectAllTables = (state) => state.tables.allTables;

export const selectActiveTables = (state) => state.tables.tables;

export const selectActiveTab = (state) => state.tables.activeTab;

export const selectTableFilterStatus = (state) => state.tables.filterStatus;

export const selectSearchText = (state) => get(state.form.searchForm, 'values.searchText');

export const selectTableOrders = (state) => state.tables.details.orders;

export const selectTableBill = (state) => state.tables.details.billSummary;

export const selectPaymentMethod = (state) => state.tables.details.billSummary.paymentMethod;

export const selectCallWaiterDate = (state) => state.tables.details.waiterCallDate;

export const selectServerTime = (state) => state.tables.details.serverTime;

export const selectAreTableOrdersFetched = (state) => state.tables.tableOrdersFetched;

export const selectTableDetails = (state) => state.tables.details;

export const selectTable = (state) => state.tables.table;

export const selectTableCreated = (state) => state.tables.tableCreated;

export const selectTableCodeAssigned = (state) => state.tables.tableCodeAssigned;

export const selectLoggedinAssigned = (state) => state.tables.loggedinAssigned;

export const selectTableEditSuccess = (state) => state.tables.edit.isSuccess;

export const selectIsAddTablePending = (state) => state.tables.isTableAdding;

export const selectAddTableError = (state) => state.tables.tableAddError;

export const selectIsEditTablePending = (state) => state.tables.isTableEditing;

export const selectEditTableError = (state) => state.tables.tableEditError;

export const selectIsDeleteTablePending = (state) => state.tables.isTableDeleting;

export const selectDeleteTableError = (state) => state.tables.tableDeleteError;

export const selectCloseBillError = (state) => state.tables.closeBillError;

export const selectTablesNotifcation = (state) => state.tables.notification;

export const selectIsInIdleMode = (state) => state.tables.isInIdleMode;

export const selectSelectedSessionId = (state) => state.tables.selectedSessionId;

export const selectCodeAssignedSuccessfuly = createSelector(
    selectTableCreated,
    selectTableCodeAssigned,
    selectLoggedinAssigned,
    (tableCreated, tableAssigned, loggedinAssigned) => tableCreated || tableAssigned || loggedinAssigned
);

export const selectTabs = createSelector(
    selectActiveTables,
    (tables) => tables.reduce((result, table) => {
        if (result.indexOf(table.areaName) === -1) {
            return [...result, table.areaName];
        }
        return result;
    }, [])
);

const calculateScore = (table) => {
    let score = 0;

    if (table.isActive) {
        score += 1;
    }

    if (table.isBillClosed) {
        score += 1;
    }

    if (table.isNewOrder) {
        score += 1.5;
    }

    if (table.isWaiterCalled) {
        score += 2;
    }

    return score;
};

export const selectTables = createSelector(
    selectActiveTables,
    selectActiveTab,
    selectSearchText,
    selectTableFilterStatus,
    (tables, activeTab, searchText, filterStatus) => {
        let filteredTables = tables.filter((table) => (activeTab === DEFAULT_TAB || table.areaName === activeTab)
            && (!searchText
                || (table.name && table.name.toLowerCase().includes(searchText.toLowerCase()))
                // eslint-disable-next-line eqeqeq
                || (table.number && table.number.toString().toLowerCase().includes(searchText))
            ));

        if (filterStatus) {
            filteredTables = filteredTables.sort((current, next) => {
                if (calculateScore(current) < calculateScore(next)) {
                    return 1;
                }
                if (calculateScore(current) > calculateScore(next)) {
                    return -1;
                }

                return 0;
            });
        }

        return filteredTables.map((table) => ({
            ...table,
            inactiveTable: (!table.isNewOrder && !table.isBillClosed && !table.isWaiterCalled)
                && table.priceSum === 0
                && table.ordersCount === 0,
            isActive: table.isNewOrder || table.isBillClosed || table.isWaiterCalled,
            isInProgress: (!table.isNewOrder && !table.isBillClosed && !table.isWaiterCalled)
                && (table.priceSum !== 0 || table.ordersCount !== 0)
        }));
    }
);

export const selectSelectedTable = createSelector(
    selectTables,
    selectSelectedSessionId,
    (tables, sessionId) => tables.find((table) => table.sessionId === sessionId)
);

export const selectActionCount = createSelector(
    selectActiveTables,
    (tables) => tables.reduce((result, table) => {
        let notification = 0;

        if (table.isNewOrder) {
            notification += 1;
        }
        if (table.isBillClosed) {
            notification += 1;
        }
        if (table.isWaiterCalled) {
            notification += 1;
        }
        return result + notification;
    }, 0)
);

export const selectOrderContext = createSelector(
    selectSelectedSessionId,
    selectTableDetails,
    (sessionId, tableDetails) => {
        if (!tableDetails) {
            return null;
        }

        return ({ sessionId });
    }
);

export const selectTableOrdersWithNumbers = createSelector(
    selectTableOrders,
    (tableOrders) => tableOrders ? tableOrders.sort((a, b) => new Date(a.createDate) - new Date(b.createDate))
        .map((tableOrder, index) => ({ ...tableOrder, type: ORDER_CARD_STATUS.ORDER, number: index + 1 })) : []
);

export const selectBillDetails = createSelector(
    selectTableBill,
    (bill) => {
        if (bill) {
            return [{
                ...bill,
                createDate: new Date(),
                type: ORDER_CARD_STATUS.BILL,
                id: 'BillSummary'
            }];
        }

        return [];
    }
);

export const selectCallWaiterDetails = createSelector(
    selectCallWaiterDate,
    (callWaiterDate) => {
        if (callWaiterDate) {
            return [{
                createDate: callWaiterDate,
                type: ORDER_CARD_STATUS.WAITER_CALLED,
                id: 'WaiterCalled'
            }];
        }

        return [];
    }
);

export const selectTableActions = createSelector(
    selectTableOrdersWithNumbers,
    selectBillDetails,
    selectCallWaiterDetails,
    (tableOrders, bill, callWaiter) => [
        ...bill,
        ...callWaiter,
        ...[...tableOrders].sort((a, b) => new Date(b.createDate) - new Date(a.createDate))
    ]
);

export const selectAddTableStatus = createSelector(
    selectIsAddTablePending,
    selectAddTableError,
    (isPending, error) => ({
        isPending,
        error
    })
);

export const selectEditTableStatus = createSelector(
    selectIsEditTablePending,
    selectEditTableError,
    (isPending, error) => ({
        isPending,
        error
    })
);

export const selectDeleteTableStatus = createSelector(
    selectIsDeleteTablePending,
    selectDeleteTableError,
    (isPending, error) => ({
        isPending,
        error
    })
);

export const selectShouldWaiterDoSomething = createSelector(
    selectActionCount,
    (actionCount) => actionCount > 0
);
