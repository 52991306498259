import arrayMove from 'array-move';
import { ACTION_TYPES } from './constants';

const initialState = {
    addons: null,
    areAddonsFetching: false,
    addon: null,
    isAddonFetching: false,
    isAddonAdding: false,
    addonAddError: null,
    isAddonEditing: false,
    addonEditError: null,
    isAddonGroupDeleting: false,
    addonDeleteError: null,
    settingsAddon: null,
    isSettingsAddonFetching: false
};

export default function addonGroupReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.FETCHING_ADDONS: {
            return {
                ...state,
                areAddonsFetching: true
            };
        }

        case ACTION_TYPES.ADDONS_FETCHED: {
            const { addons, addonGroupId } = action.payload;

            return {
                ...state,
                areAddonsFetching: false,
                addons: {
                    ...state.addons || {},
                    [addonGroupId]: addons
                }
            };
        }

        case ACTION_TYPES.ADDONS_FETCH_FAILED: {
            return {
                ...state,
                areAddonsFetching: false,
                addons: initialState.addons
            };
        }

        case ACTION_TYPES.FETCHING_ADDON: {
            return {
                ...state,
                isAddonFetching: true,
                addon: initialState.addon
            };
        }

        case ACTION_TYPES.ADDON_FETCHED: {
            return {
                ...state,
                isAddonFetching: false,
                addon: action.payload
            };
        }

        case ACTION_TYPES.ADDON_FETCH_FAILED: {
            return {
                ...state,
                isAddonFetching: false,
                addon: initialState.addon
            };
        }

        case ACTION_TYPES.ADDING_ADDON: {
            return {
                ...state,
                isAddonAdding: true,
                addonAddError: null
            };
        }

        case ACTION_TYPES.ADDON_ADDED: {
            return {
                ...state,
                isAddonAdding: false,
                addonAddError: null
            };
        }

        case ACTION_TYPES.ADD_ADDON_FAILED: {
            return {
                ...state,
                isAddonAdding: false,
                addonAddError: action.payload
            };
        }

        case ACTION_TYPES.EDITING_ADDON: {
            return {
                ...state,
                isAddonEditing: true,
                addonEditError: null
            };
        }

        case ACTION_TYPES.ADDON_EDITED: {
            return {
                ...state,
                isAddonEditing: false,
                addonEditError: null
            };
        }

        case ACTION_TYPES.EDIT_ADDON_FAILED: {
            return {
                ...state,
                isAddonEditing: false,
                addonEditError: action.payload
            };
        }

        case ACTION_TYPES.DELETEING_ADDON: {
            return {
                ...state,
                isAddonDeleting: true,
                addonDeleteError: null
            };
        }

        case ACTION_TYPES.ADDON_DELETED: {
            return {
                ...state,
                isAddonDeleting: false,
                addonDeleteError: null
            };
        }

        case ACTION_TYPES.DELETE_ADDON_FAILED: {
            return {
                ...state,
                isAddonDeleting: false,
                addonDeleteError: action.payload
            };
        }

        case ACTION_TYPES.FETCHING_ADDON_SETTINGS: {
            return {
                ...state,
                isSettingsAddonFetching: true,
                settingsAddon: initialState.addon
            };
        }

        case ACTION_TYPES.ADDON_SETTINGS_FETCHED: {
            return {
                ...state,
                isSettingsAddonFetching: false,
                settingsAddon: action.payload
            };
        }

        case ACTION_TYPES.ADDON_SETTINGS_FETCH_FAILED: {
            return {
                ...state,
                isSettingsAddonFetching: false,
                settingsAddon: initialState.addon
            };
        }

        case ACTION_TYPES.ADDON_REORDERED: {
            const { fromIndex, toIndex, addonGroupId } = action.payload;

            return {
                ...state,
                addons: {
                    ...state.addons || {},
                    [addonGroupId]: arrayMove(state.addons[addonGroupId], fromIndex - 1, toIndex - 1)
                }
            };
        }

        case ACTION_TYPES.ADDON_REORDER_FAILED: {
            const { fromIndex, toIndex, addonGroupId } = action.payload;

            return {
                ...state,
                addons: {
                    ...state.addons || {},
                    [addonGroupId]: arrayMove(...state.addons[addonGroupId], toIndex - 1, fromIndex - 1)
                }
            };
        }

        default: return state;
    }
}
