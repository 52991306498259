import ApiUrl from 'src/config/api';
import { selectUserToken } from 'src/modules/User';
import ApiError from 'src/utils/error';
import get from 'lodash/get';
import { SOCKET_STATE } from 'src/utils/webSocket';
import { ACTION_TYPES, MODULE_NAME, ORDER_STATUS } from './constants';

export const connectTableSocket = () => async (dispatch, getState, di) => {
    const socketState = di[MODULE_NAME].tablesWebSocket.getSocketState();
    // eslint-disable-next-line no-debugger

    if (socketState !== SOCKET_STATE.CONNECTING
        || socketState !== SOCKET_STATE.OPEN) {
        di[MODULE_NAME].tablesWebSocket.connect(
            dispatch,
            getState,
            `api/waiter/tables`
        );
    }
};

export const disconnectTableSocket = () => async (dispatch, getState, di) => {
    const { tablesWebSocket } = di[MODULE_NAME];

    if (tablesWebSocket.socket.connection) {
        tablesWebSocket.close();
    }
};

export const connectTableDeatilSocket = ({ sessionId }) => async (dispatch, getState, di) => {
    const socketState = di[MODULE_NAME].tableDetailWebSocket.getSocketState();
    if (socketState !== SOCKET_STATE.CONNECTING
        || socketState !== SOCKET_STATE.OPEN) {
        di[MODULE_NAME].tableDetailWebSocket.connect(
            dispatch,
            getState,
            `api/waiter/clientSessions/${sessionId}/waiterSummaries`
        );
    }
};

export const disconnectTableDeatilSocket = () => async (dispatch, getState, di) => {
    const { tableDetailWebSocket } = di[MODULE_NAME];

    if (tableDetailWebSocket.socket.connection) {
        tableDetailWebSocket.close();
    }
};

export const setTab = (selectedTab) => async (dispatch) => {
    dispatch({
        type: ACTION_TYPES.TAB_SELECTION_CHANGED,
        payload: { selectedTab }
    });
};

export const setActiveTable = (selectedSessionId) => async (dispatch) => {
    dispatch({
        type: ACTION_TYPES.SELECT_TABLE_CHANGED,
        payload: { selectedSessionId }
    });
};

export const setTableFilter = (filterStatus) => async (dispatch) => {
    dispatch({
        type: ACTION_TYPES.TABLE_FILTER_CHANGED,
        payload: filterStatus
    });
};

export const fetchTables = () => async (dispatch, getState) => {
    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/tables/details`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then((response) => {
        dispatch({
            type: ACTION_TYPES.ALL_TABLES_FETCHED,
            payload: response
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.ALL_TABLES_FETCH_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const deleteTable = ({ id }) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.DELETING_TABLE
    });

    const token = selectUserToken(getState());
    await fetch(`${ApiUrl}/api/restaurant/tables/${id}`, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }).then((response) => {
        if (!response.ok) {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then(() => {
        dispatch({
            type: ACTION_TYPES.TABLE_DELETED
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.DELETING_TABLE_ERROR,
            payload: error?.firstMessage
        });
    });
};

export const getTable = (id) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.CLEAR_TABLE
    });

    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/tables/${id}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then((response) => {
        dispatch({
            type: ACTION_TYPES.TABLE_FETCHED,
            payload: response
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.TABLE_FETCH_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const closeSession = (sessionId, isBillPaid) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.BILL_PAY
    });

    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/waiter/clientSessions/${sessionId}/status`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            isActive: false,
            billPaid: isBillPaid
        })
    }).then((response) => {
        if (!response.ok) {
            return response.json().then((json) => { throw new ApiError(get(json, 'errors', json)); });
        }
    }).then(() => {
        dispatch({
            type: ACTION_TYPES.BILL_PAID
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.BILL_PAY_ERROR,
            payload: error?.firstMessage
        });
    });
};

export const setOrderAsAccepted = (sessionId, orderId) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.ORDER_ACCEPT
    });

    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/waiter/clientSessions/${sessionId}/bill/orders/${orderId}/status`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            status: ORDER_STATUS.ACCEPTED
        })
    }).then((response) => {
        if (!response.ok) {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then(() => {
        dispatch({
            type: ACTION_TYPES.ORDER_ACCEPTED
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.ORDER_ACCEPT_ERROR,
            payload: error?.firstMessage
        });
    });
};

export const setOrderAsCompleted = (sessionId, orderId) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.ORDER_COMPLETE
    });

    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/waiter/clientSessions/${sessionId}/bill/orders/${orderId}/status`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            status: ORDER_STATUS.COMPLETED
        })
    }).then((response) => {
        if (!response.ok) {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then(() => {
        dispatch({
            type: ACTION_TYPES.ORDER_COMPLETED
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.ORDER_COMPLETE_ERROR,
            payload: error?.firstMessage
        });
    });
};

export const closeTableDetails = () => async (dispatch) => {
    dispatch({
        type: ACTION_TYPES.CLOSE_TABLE_DETAILS
    });
};

export const editTable = (id, { name, number, area, codeNumber }) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.EDITING_TABLE
    });

    const token = selectUserToken(getState());
    await fetch(`${ApiUrl}/api/restaurant/tables/${id}`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            name,
            number,
            areaId: area?.id,
            codeNumber
        })
    }).then((response) => {
        if (!response.ok) {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then(() => {
        dispatch({
            type: ACTION_TYPES.EDITING_TABLE_SUCCESS
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.EDITING_TABLE_ERROR,
            payload: error?.firstMessage
        });
    });
};

export const clearEditedTable = () => async (dispatch) => {
    dispatch({
        type: ACTION_TYPES.CLEAR_EDITING_TABLE
    });
};

export const createTable = ({ name, number, area, codeNumber }) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.CREATE_TABLE
    });

    const token = selectUserToken(getState());
    await fetch(`${ApiUrl}/api/restaurant/tables`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            name,
            codeNumber,
            number,
            areaId: area?.id
        })
    }).then((response) => {
        if (!response.ok) {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then(() => {
        dispatch({
            type: ACTION_TYPES.TABLE_CREATED
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.TABLE_CREATE_ERROR,
            payload: error?.firstMessage
        });
    });
};

export const assignCodeToTable = ({ code, table: { id } }) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.ASSIGN_CODE_TO_TABLE
    });

    const token = selectUserToken(getState());
    await fetch(`${ApiUrl}/api/restaurant/tables/${id}/code`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            code
        })
    }).then((response) => {
        if (!response.ok) {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then(() => {
        dispatch({
            type: ACTION_TYPES.ASSIGN_CODE_TO_TABLE_SUCCESS
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.ASSIGN_CODE_TO_TABLE_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const clearAssignTable = () => async (dispatch) => {
    dispatch({
        type: ACTION_TYPES.CLEAR_ASSIGN_CODE_SUCCESS
    });
};

export const assignCodeToLogin = (code) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.ASSIGN_LOGIN
    });

    const token = selectUserToken(getState());
    await fetch(`${ApiUrl}/api/restaurant/logincodes`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            'loginCode': code
        })
    }).then((response) => {
        if (!response.ok) {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then(() => {
        dispatch({
            type: ACTION_TYPES.ASSIGNED_LOGIN
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.ASSIGN_LOGIN_ERROR,
            payload: error?.firstMessage
        });
    });
};

export const setWaiterCallAsAccepted = (sessionId) => async (dispatch, getState) => {
    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/waiter/clientSessions/${sessionId}/waiterCalls/status`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            isAccepted: true
        })
    }).then((response) => {
        if (!response.ok) {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then(() => {
        dispatch({
            type: ACTION_TYPES.WAITER_CALL_ACCEPT
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.WAITER_CALL_ACCEPT_ERROR,
            payload: error?.firstMessage
        });
    });
};

export const clearCloseBillError = () => async (dispatch) => {
    dispatch({
        type: ACTION_TYPES.CLEAR_CLOSE_BILL_ERROR
    });
};

export const pushTableNotification = (type) => async (dispatch) => {
    dispatch({
        type: ACTION_TYPES.PUSH_TABLE_NOTIFICATION,
        payload: {
            type
        }
    });
};

export const setIdleMode = (state) => async (dispatch) => {
    dispatch({
        type: ACTION_TYPES.SET_IDLE_MODE,
        payload: state
    });
};
