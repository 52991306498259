export const MODULE_NAME = 'modal';

const prefix = `${MODULE_NAME}/`;

export const ACTION_TYPES = {
    ADDED_MESSAGE: `${prefix}ADDED_MESSAGE`,
    DISMISSED_FIRST_MESSAGE: `${prefix}DISMISSED_FIRST_MESSAGE`,
    DISMISS_ALL_MESSAGES: `${prefix}DISMISS_ALL_MESSAGES`
};

export const MESSAGE_TYPE = {
    XS: 'xs',
    SMALL: 'small',
    NORMAL: 'normal',
    LARGE: 'large'
};
