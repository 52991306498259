import compose from '@aveneo/frontmoon-utils/lib/hocs/compose';

import ChipInput from 'react-chip-input';

import ViewportWatcherPackage from '@aveneo/frontmoon-viewport-watcher/lib/components';
import FormComponentsPackage from '@aveneo/frontmoon-form/lib/components';

import withViewport from '@aveneo/frontmoon-viewport-watcher/lib/hocs/withViewport';
import formReducer from '@aveneo/frontmoon-form/lib/modules/Form/reducer';

import SessionManagerPackage from 'src/hocs/withSessionManager';
import IsAuthenticatedPackage from 'src/hocs/isAuthenticated';
import withInitializeRestaurant from 'src/hocs/withInitializeRestaurant';
import withScreenHeightDetector from 'src/hocs/withScreenHeightDetector';

import moduleViewport from '@aveneo/frontmoon-viewport-watcher/lib/modules/Viewport';
import createModuleOrders from 'src/modules/Order';
import createModuleTables from 'src/modules/Tables';
import createModuleNotificaiton from 'src/modules/Notification';
import createModuleMenu from 'src/modules/Menu';
import createModuleMenuPosition from 'src/modules/MenuPosition';
import createModuleAddonGroup from 'src/modules/AddonGroup';
import createModuleAddon from 'src/modules/Addon';
import createModuleModal from 'src/modules/Modal';
import createModuleUser from 'src/modules/User';
import createModuleSettings from 'src/modules/Settings';
import createModuleAutocomplete from 'src/modules/Autocomplete';
import createModuleRestaurant from 'src/modules/Restaurant';
import createModuleArea from 'src/modules/Area';

import Input from 'src/components/Input';
import InputFile from 'src/components/InputFile';
import Checkbox from 'src/components/Checkbox';
import PredefinedAutocomplete from 'src/components/PredefinedAutocomplete';
import GenericAutocomplete from 'src/components/GenericAutocomplete';
import GoogleMapReact from 'src/components/GoogleMapReact';

import DropdownPackage from 'src/components/Dropdown';
import OverridedFormComponetnsPackage from 'src/components/Form';

const parseModules = (modules) => modules.reduce((parsedModules, module) => [
    ...parsedModules,
    ...Object.keys(module).map((key) => ({
        moduleName: key,
        module: module[key]
    }))
], []);

const dependencies = (di, store) => ({
    modules: parseModules([
        moduleViewport,
        createModuleOrders(),
        createModuleTables(),
        createModuleNotificaiton(),
        createModuleMenu(),
        createModuleMenuPosition(),
        createModuleAddonGroup(),
        createModuleAddon(),
        createModuleModal(),
        createModuleUser(),
        createModuleSettings(),
        createModuleAutocomplete(),
        createModuleRestaurant(),
        createModuleArea(),
        { 'form': { reducer: formReducer } }
    ]),
    components: [
        () => ({
            store: { dependency: store, method: 'constant' },
            withViewport,
            withInitializeRestaurant,
            Input,
            InputFile,
            Checkbox,
            GenericAutocomplete,
            PredefinedAutocomplete,
            GoogleMapReact,
            ChipInput
        }),
        DropdownPackage,
        SessionManagerPackage,
        IsAuthenticatedPackage,
        ViewportWatcherPackage,
        FormComponentsPackage,
        OverridedFormComponetnsPackage,
        ({ components }) => ({
            ViewportWatcher: compose(
                components.withSessionManager,
                components.withInitializeRestaurant,
                withScreenHeightDetector
            )(components.ViewportWatcher)
        })
    ]
});

export default dependencies;
