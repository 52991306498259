import reducer from './reducer';
import { ACTION_TYPES, MODULE_NAME, SETTINGS_TYPE, AUTHENTICATION_METHOD, AUTHENTICATION_METHOD_TRANSLATIONS } from './constants';

export {
    fetchRestaurant,
    updateRestaurant,
    clearRestaurantUpdateSuccess
} from './actions';

export {
    selectRestaurantDetails,
    selectRestaurantDetailsFeatched,
    selectRestaurantGallery,
    selectRestaurantUpdateSuccess,
    selectRestaurantDetailsWithFormattedTime,
    selectRestaurantFormData,
    selectRestaurantUpdateError
} from './selectors';

export {
    ACTION_TYPES,
    MODULE_NAME,
    SETTINGS_TYPE,
    AUTHENTICATION_METHOD,
    AUTHENTICATION_METHOD_TRANSLATIONS
};

export default () => ({
    [MODULE_NAME]: {
        reducer
    }
});
