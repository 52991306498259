import { connect } from 'react-redux';

import {
    restoreUserSession,
    selectIsSessionRestored
} from 'src/modules/User';

const mapDispatchToProps = {
    restoreUserSession
};

const mapStateToProps = (state) => ({
    isSessionRestored: selectIsSessionRestored(state)
});

export default connect(mapStateToProps, mapDispatchToProps);
