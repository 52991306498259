import { useStore } from 'react-redux';
import { processRegisterDependencies } from '@aveneo/frontmoon-di/lib';

import getAppDependencies from 'src/dependencies';

let isInitialized = false;

const processDependencies = (di) => (WrappedComponent) => (props) => {
    const store = useStore();

    if (!isInitialized) {
        processRegisterDependencies([getAppDependencies], di, store);

        isInitialized = true;
    }

    return <WrappedComponent {...props} store={store} di={di} />;
};

export default processDependencies;
