import { ACTION_TYPES } from './constants';

export const setActiveTab = (tab) => async (dispatch) => {
    dispatch({
        type: ACTION_TYPES.ACTIVE_TAB_CHANGED,
        payload: tab
    });
};

export const changeIdleObject = (idleObject) => async (dispatch) => {
    dispatch({
        type: ACTION_TYPES.CHANGE_IDLE_OBJECT,
        payload: idleObject
    });
};
