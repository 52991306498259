/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';

import useRestaurantSlug from 'src/hooks/useRestaurantSlug';
import useRestaurantCode from 'src/hooks/useRestaurantCode';

const withInitializeRestaurant = (Component) => ({
    fetchRestaurantBySlug,
    fetchRestaurantByCode,
    isInitialized,
    restaurantId,
    userRestaurantId,
    logout,
    isLoggedIn,
    ...props
}) => {
    const slug = useRestaurantSlug();
    const code = useRestaurantCode();

    useEffect(() => {
        if (slug && !isInitialized) {
            fetchRestaurantBySlug(slug);
        }
    }, [slug, isInitialized]);

    useEffect(() => {
        if (code && !isInitialized) {
            fetchRestaurantByCode(code);
        }
    }, [code, isInitialized]);

    useEffect(() => {
        if (isInitialized && restaurantId !== userRestaurantId && isLoggedIn) {
            logout();
        }
    }, [isInitialized, restaurantId]);

    return <Component {...props} />;
};

export default withInitializeRestaurant;
