import { ACTION_TYPES } from './constants';

const initialState = {
    messages: []
};

export default function menuReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.ADDED_MESSAGE: {
            if (action.payload.shouldBeFirst) {
                return {
                    ...state,
                    messages: [action.payload, ...state.messages]
                };
            }

            return {
                ...state,
                messages: state.messages.concat(action.payload)
            };
        }
        case ACTION_TYPES.DISMISSED_FIRST_MESSAGE: {
            return {
                ...state,
                messages: state.messages.slice(1)
            };
        }
        case ACTION_TYPES.DISMISS_ALL_MESSAGES: {
            return {
                ...state,
                messages: initialState.messages
            };
        }

        default: return state;
    }
}
