export const MODULE_NAME = 'addonGroup';

const prefix = `${MODULE_NAME}/`;

export const ACTION_TYPES = {
    FETCHING_ADDON_GROUPS: `${prefix}FETCHING_ADDON_GROUPS`,
    ADDON_GROUPS_FETCHED: `${prefix}ADDON_GROUPS_FETCHED`,
    ADDON_GROUPS_FETCH_FAILED: `${prefix}ADDON_GROUPS_FETCH_FAILED`,
    FETCHING_ADDON_GROUP: `${prefix}FETCHING_ADDON_GROUP`,
    ADDON_GROUP_FETCHED: `${prefix}ADDON_GROUP_FETCHED`,
    ADDON_GROUP_FETCH_FAILED: `${prefix}ADDON_GROUP_FETCH_FAILED`,
    ADDING_ADDON_GROUP: `${prefix}ADDING_ADDON_GROUP`,
    ADDON_GROUP_ADDED: `${prefix}ADDON_GROUP_ADDED`,
    ADD_ADDON_GROUP_FAILED: `${prefix}ADD_ADDON_GROUP_FAILED`,
    EDITING_ADDON_GROUP: `${prefix}EDITING_ADDON_GROUP`,
    ADDON_GROUP_EDITED: `${prefix}ADDON_GROUP_EDITED`,
    EDIT_ADDON_GROUP_FAILED: `${prefix}EDIT_ADDON_GROUP_FAILED`,
    DELETEING_ADDON_GROUP: `${prefix}DELETEING_ADDON_GROUP`,
    ADDON_GROUP_DELETED: `${prefix}ADDON_GROUP_DELETED`,
    DELETE_ADDON_GROUP_FAILED: `${prefix}DELETE_ADDON_GROUP_FAILED`,
    ADDON_GROUP_REORDERED: `${prefix}ADDON_GROUP_REORDERED`,
    ADDON_GROUP_REORDER_FAILED: `${prefix}ADDON_GROUP_REORDER_FAILED`
};
