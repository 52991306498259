import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import DiContext from '@aveneo/frontmoon-di/lib';
import ReactDOM from 'react-dom';
import { addEventsToDocument, removeEventsFromDocument, pauseEvent, targetIsDescendant } from 'src/utils/event';

import theme from 'src/styles/components/Dropdown.module.scss';

class BaseDropdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active: false,
            up: false
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevState.active && this.state.active) {
            addEventsToDocument(this.getDocumentEvents());
        }

        if (prevState.active && !this.state.active) {
            removeEventsFromDocument(this.getDocumentEvents());
        }
    }

    componentWillUnmount() {
        if (this.state.active) {
            removeEventsFromDocument(this.getDocumentEvents());
        }
    }

    getDocumentEvents = () => ({
        click: this.handleDocumentClick,
        touchend: this.handleDocumentClick
    });

    getSelectedItem = () => {
        const { valueKey, value, source, allowBlank } = this.props;

        const result = source.find((item) => item[valueKey] === value);

        if (result) {
            return result;
        }

        return !allowBlank
            ? source[0]
            : undefined;
    };

    handleSelect = (item, event) => {
        if (this.props.onBlur) this.props.onBlur(event);
        if (!this.props.disabled && this.props.onChange) {
            if (this.props.name) {
                // eslint-disable-next-line no-param-reassign
                event.target.name = this.props.name;
            }

            this.props.onChange(item, event);
            this.close();
        }
    };

    handleClick = (event) => {
        if (!this.state.active && !this.props.disabled) {
            this.open(event);
        } else {
            this.close();
        }

        pauseEvent(event);
        if (this.props.onClick) this.props.onClick(event);
    };

    handleDocumentClick = (event) => {
        // eslint-disable-next-line react/no-find-dom-node
        if (this.state.active && !targetIsDescendant(event, ReactDOM.findDOMNode(this))) {
            this.setState({ active: false });
        }
    };

    close = () => {
        if (this.state.active) {
            this.setState({ active: false });
        }
    }

    open = (event) => {
        if (this.state.active) return;
        const client = event.target.getBoundingClientRect();
        const screenHeight = window.innerHeight || document.documentElement.offsetHeight;
        const up = this.props.auto ? client.top > ((screenHeight / 2) + client.height) : false;
        this.setState({ active: true, up });
    };

    handleFocus = () => {
        // event.stopPropagation();
        // if (!this.props.disabled) this.open(event);
        // if (this.props.onFocus) this.props.onFocus(event);
    };

    handleBlur = (event) => {
        event.stopPropagation();
        if (this.state.active) this.close();
        if (this.props.onBlur) this.props.onBlur(event);
    }

    renderTemplateValue(selected) {
        const className = classnames(theme.field, {
            [theme.errored]: this.props.error,
            [theme.disabled]: this.props.disabled,
            [theme.required]: this.props.required
        });

        return (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div className={className} onClick={this.handleClick}>
                <div className={`${theme.templateValue} ${theme.value}`}>
                    {this.props.template(selected)}
                </div>
                {this.props.label
                    ? (
                        <label// eslint-disable-line jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control
                          className={theme.label}
                        >
                            {this.props.label}
                            {this.props.required ? <span className={theme.required}> * </span> : null}
                        </label>
                    ) : null}
                {this.props.error ? <span className={theme.error}>{this.props.error}</span> : null}
            </div>
        );
    }

    renderValue = (item, idx) => {
        const { labelKey, valueKey } = this.props;
        const className = classnames({
            [theme.selected]: item[valueKey] === this.props.value,
            [theme.disabled]: item.disabled
        });
        return (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <li
              key={idx}
              className={className}
              onMouseDown={!item.disabled && this.handleSelect.bind(this, item[valueKey])}
            >
                {this.props.template ? this.props.template(item) : item[labelKey]}
            </li>
        );
    };

    render() {
        const {
            allowBlank, auto, labelKey, required, onChange, onFocus, onBlur, shadow,
            source, template, value, valueKey, dropdownRef, inputRef, disableClearIcon,
            ...others
        } = this.props;
        const { Input } = this.context.container;

        const selected = this.getSelectedItem();
        const className = classnames(theme.dropdown, {
            [theme.up]: this.state.up,
            [theme.active]: this.state.active,
            [theme.disabled]: this.props.disabled,
            [theme.required]: this.props.required,
            [theme.withLabel]: this.props.label
        }, this.props.className);

        return (
            <>
                <div
                  className={className}
                  data-sdk="dropdown"
                  ref={dropdownRef}
                  tabIndex="-1"
                >
                    <Input
                      {...others}
                      tabIndex="0"
                      className={theme.value}
                      onClick={this.handleClick}
                      onBlur={this.handleBlur}
                      onFocus={this.handleFocus}
                      required={this.props.required}
                      readOnly
                      type={template && selected ? 'hidden' : null}
                      theme={theme}
                      value={selected && selected[labelKey] ? selected[labelKey] : ''}
                      shadow={shadow}
                    />
                    {template && selected ? this.renderTemplateValue(selected) : null}
                    <ul className={theme.values}>
                        {source.map(this.renderValue)}
                    </ul>
                </div>
            </>
        );
    }
}

BaseDropdown.propTypes = {
    allowBlank: PropTypes.bool,
    auto: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    label: PropTypes.string,
    labelKey: PropTypes.string,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    required: PropTypes.bool,
    source: PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ])).isRequired,
    template: PropTypes.func,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    valueKey: PropTypes.string,
    dropdownRef: PropTypes.object,
    shadow: PropTypes.bool,
    disableClearIcon: PropTypes.bool,
    inputRef: PropTypes.object
};

BaseDropdown.defaultProps = {
    auto: true,
    className: '',
    allowBlank: true,
    disabled: false,
    labelKey: 'label',
    required: false,
    valueKey: 'value'
};

export default BaseDropdown;

BaseDropdown.contextType = DiContext;
