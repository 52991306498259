import reducer from './reducer';
import { MODULE_NAME } from './constants';

export { pushNotification, dismissFirstNotification } from './actions';

export { selectFirstNotification } from './selectors';

export default () => ({
    [MODULE_NAME]: {
        reducer
    }
});
