import React, { Component } from 'react';
import ApiUrl from 'src/config/api';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import omit from 'lodash/omit';
import get from 'lodash/get';
import classnames from 'classnames';
import DiContainer from '@aveneo/frontmoon-di/lib';
import Cropper from 'react-easy-crop';
import { injectIntl } from 'react-intl';

import Input from 'src/components/Input';
import IconButton from 'src/components/IconButton';

import theme from 'src/styles/components/InputFile.module.scss';

const IMAGES_EXTENSIONS = {
    PNG: 'png',
    JPG: 'jpg',
    JPEG: 'jpeg',
    TIFF: 'tiff',
    GIF: 'gif',
    SVG: 'svg',
    BMP: 'bmp'
};

class InputFile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isImageVisible: false,
            imgSrc: null,
            crop: { x: 0, y: 0 },
            defaultCrop: props.defaultCrop,
            zoom: 1
        };
    }

    componentDidMount() {
        const { value, onFileChange } = this.props;
        const fileName = get(value, 'name');

        if (value) {
            onFileChange(value);
        }

        if (fileName) {
            const fileExtension = fileName.split('.').pop();
            const isImage = Object.values(IMAGES_EXTENSIONS).includes(fileExtension);

            if (isImage) {
                this.readImageFromUrl();
            }
        }
    }

    componentDidUpdate({ value, defaultCrop }) {
        const fileId = get(this.props.value, 'id');
        const fileHash = get(this.props.value, 'hash');

        if ((fileId && fileId !== get(value, 'id'))
            || (fileHash && fileHash !== get(value, 'hash'))) {
            const fileName = get(this.props.value, 'name');
            const fileExtension = fileName.split('.').pop();
            const isImage = Object.values(IMAGES_EXTENSIONS).includes(fileExtension);

            if (isImage) {
                this.readImageFromUrl();
            }
        }

        if (this.props.defaultCrop && defaultCrop !== this.props.defaultCrop) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ defaultCrop: this.props.defaultCrop });
        }
    }

    handleChangeFile = () => {
        if (this.file.files.length) {
            const file = this.file.files[0];
            const fileName = get(file, 'name');

            this.props.onFileChange(file);

            if (fileName) {
                const fileExtension = fileName.split('.').pop();

                const isImage = Object.values(IMAGES_EXTENSIONS).includes(fileExtension);

                if (isImage) {
                    this.readImageFromFile(file);
                } else {
                    this.clearImage();
                }
            }
        } else {
            this.onClear();
        }

        if (this.props.shouldClearInputAfterSelect) {
            this.file.value = '';
            this.file.type = '';
            this.file.type = 'file';
        }
    }

    handleSelectFile = () => {
        this.file.click();
        this.props.onBlur({ target: { value: null } });
    }

    handleSelectFileOnKey = (event) => {
        if (event.keyCode === 13) {
            this.file.click();
            this.props.onBlur({ target: { value: null } });
        }
    }

    onClear = () => {
        this.props.onFileChange(null);
        this.props.onBlur({ target: { value: null } });
        this.clearImage();
    }

    clearImage() {
        this.setState({
            isImageVisible: false,
            imgSrc: null
        });
    }

    readImageFromFile(file) {
        if (file) {
            const reader = new FileReader();

            reader.onload = (event) => {
                this.setState({
                    imgSrc: event.target.result,
                    isImageVisible: true
                });
            };

            reader.readAsDataURL(file);
        }
    }

    async readImageFromUrl(endPointWithHash) {
        const { endpoint, value } = this.props;

        const fileId = get(value, 'id');

        if (!endPointWithHash && !endpoint && !fileId) {
            return;
        }

        this.setState({
            imgSrc: `${ApiUrl}${endPointWithHash || endpoint}${fileId}`,
            isImageVisible: true
        });
    }

    render() {
        const {
            id,
            value,
            accept,
            className,
            disabled,
            disableClearIcon,
            name,
            label,
            onFileChange,
            onFileNameChange,
            shouldShowImage,
            shouldClearInputAfterSelect,
            CustomInput,
            imageAspectRatio,
            onDimensionsChange,
            defaultCrop,
            intl,
            ...rest
        } = this.props;

        const { isImageVisible, imgSrc } = this.state;

        const fileName = get(value, 'fullName') || get(value, 'name') || intl.formatMessage({ id: 'App.noFileSelected' });
        const inputProps = omit(rest, [
            'onBlur',
            'onClick',
            'onFocus',
            'onChange',
            'onDragStart',
            'onDrop',
            'type',
            'tReady',
            'i18n',
            'isReadonly',
            'ripple'
        ]);
        const hiddenInputProps = omit(rest, [
            'required',
            'tReady',
            'i18n',
            'isReadonly',
            'ripple'
        ]);

        return (
            <>
                <div className={classnames(className, theme.container, { [theme.imageVisible]: isImageVisible })}>
                    <Input
                      {...hiddenInputProps}
                      className={theme.fileInput}
                      name={name}
                      id={id}
                      type="file"
                      onChange={this.handleChangeFile}
                      inputRef={(ref) => { this.file = ref; }}
                      accept={accept}
                    />
                    {!CustomInput && (
                        <Input
                          {...inputProps}
                          {...hiddenInputProps}
                          className={theme.input}
                          label={label}
                          onClick={this.handleSelectFile}
                          onKeyUp={this.handleSelectFileOnKey}
                          onFocus={this.props.onFocus}
                          onBlur={this.props.onBlur}
                          value={fileName}
                          theme={theme}
                        />
                    )}
                    {CustomInput && (
                        <CustomInput
                          {...inputProps}
                          label={label}
                          onClick={this.handleSelectFile}
                          onKeyUp={this.handleSelectFileOnKey}
                          onFocus={this.props.onFocus}
                          onBlur={this.props.onBlur}
                          value={fileName}
                          hasValue={Boolean(get(value, 'fullName') || get(value, 'name'))}
                        />
                    )}
                    {(!disabled && !disableClearIcon) && (
                        <IconButton
                          icon="icon-close"
                          className={classnames(theme.clearIcon, { [theme.iconOnImage]: shouldShowImage && imgSrc && isImageVisible })}
                          onClick={this.onClear}
                        />
                    )}
                </div>
                {(shouldShowImage && imgSrc && isImageVisible) && (
                    <div className={theme.cropper}>
                        <Cropper
                          image={imgSrc}
                          aspect={imageAspectRatio}
                          crop={this.state.crop}
                          zoom={this.state.zoom}
                          onCropChange={(crop) => {
                              if (Number.isNaN(crop.x) || Number.isNaN(crop.y)) {
                                  return;
                              }

                              this.setState({ crop });
                          }}
                          onZoomChange={(zoom) => { this.setState({ zoom }); }}
                          onCropComplete={(croppedArea, croppedAreaPixels) => {
                              if (croppedAreaPixels.x === this.state.defaultCrop?.x
                                && croppedAreaPixels.y === this.state.defaultCrop?.y
                                && croppedAreaPixels.width === this.state.defaultCrop?.width
                                && croppedAreaPixels.height === this.state.defaultCrop?.height) {
                                    return;
                              }

                              onDimensionsChange(croppedAreaPixels);
                          }}
                          initialCroppedAreaPixels={this.state.defaultCrop}
                        />
                    </div>
                )}
            </>
        );
    }
}

InputFile.propTypes = {
    onFileChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onFileNameChange: PropTypes.func,
    value: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]),
    id: PropTypes.string,
    accept: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    disableClearIcon: PropTypes.bool,
    shouldShowImage: PropTypes.bool,
    shouldClearInputAfterSelect: PropTypes.bool,
    CustomInput: PropTypes.func,
    endpoint: PropTypes.string,
    imageAspectRatio: PropTypes.number,
    onDimensionsChange: PropTypes.func,
    defaultCrop: PropTypes.object,
    intl: PropTypes.object
};

InputFile.defaultProps = {
    onFileNameChange: noop,
    shouldShowImage: true,
    shouldClearInputAfterSelect: false,
    onBlur: noop,
    onFileChange: noop,
    imageAspectRatio: 1,
    onDimensionsChange: noop
};

export default injectIntl(InputFile);

InputFile.contextType = DiContainer;
