import ApiUrl from 'src/config/api';
import { selectUserToken } from 'src/modules/User';
import ApiError from 'src/utils/error';
import { fetchMenuPositions } from 'src/modules/Menu';
import { ACTION_TYPES } from './constants';

export const fetchMenuPosition = ({ menuId, categoryId, id }) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.FETCHING_MENU_POSITION
    });

    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/menus/${menuId}/categories/${categoryId}/positions/${id}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then((response) => {
        dispatch({
            type: ACTION_TYPES.MENU_POSITION_FETCHED,
            payload: response
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.MENU_POSITION_FETCH_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const fetchMenuPositionSettings = ({ menuId, categoryId, id }) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.FETCHING_MENU_POSITION_SETTINGS
    });

    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/menus/${menuId}/categories/${categoryId}/positions/${id}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then((response) => {
        dispatch({
            type: ACTION_TYPES.MENU_POSITION_SETTINGS_FETCHED,
            payload: response
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.MENU_POSITION_SETTINGS_FETCH_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const uploadPhoto = (image) => async (dispatch, getState) => {
    const token = selectUserToken(getState());

    const formData = new FormData();

    formData.append('Image', image);
    formData.append('Extension', image.name.split('.').pop());

    return fetch(`${ApiUrl}/api/restaurant/images`, {
        method: 'POST',
        headers: {
            'Authorization': token
        },
        body: formData
    }).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then((response) => {
        dispatch({
            type: ACTION_TYPES.ADD_IMAGE_SUCCESS,
            payload: response
        });

        return response?.id;
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.ADD_IMAGE_FAILED,
            payload: error?.firstMessage
        });

        return null;
    });
};

export const addMenuPosition = ({ menuId, categoryId }, position) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.ADDING_MENU_POSITION
    });

    const token = selectUserToken(getState());

    let productImageId = null;

    if (position.productImage) {
        if (position.productImage.id) {
            productImageId = position.productImage.id;
        } else {
            productImageId = await uploadPhoto(position.productImage)(dispatch, getState);
        }
    }

    await fetch(`${ApiUrl}/api/restaurant/menus/${menuId}/categories/${categoryId}/positions`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            ...position,
            productImageId,
            allergensNames: position.allergensNames ? position.allergensNames.split(',').map((alergen) => alergen.trim()) : [],
            tagsNames: position.tagsNames ? position.tagsNames.split(',').map((alergen) => alergen.trim()) : []
        })
    }).then((response) => {
        if (!response.ok) {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then(() => {
        dispatch({
            type: ACTION_TYPES.MENU_POSITION_ADDED
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.ADD_MENU_POSITION_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const addMenuPositionByExistingProduct = ({ menuId, categoryId, productId }) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.ADDING_MENU_POSITION
    });

    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/products/${productId}/menus/${menuId}/categories/${categoryId}/positions`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }).then((response) => {
        if (!response.ok) {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then(() => {
        dispatch({
            type: ACTION_TYPES.MENU_POSITION_ADDED
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.ADD_MENU_POSITION_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const editMenuPosition = ({ id, menuId, categoryId }, position) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.EDITING_MENU_POSITION
    });

    const token = selectUserToken(getState());

    let productImageId = null;

    if (position.productImage) {
        if (position.productImage.id) {
            productImageId = position.productImage.id;
        } else {
            productImageId = await uploadPhoto(position.productImage)(dispatch, getState);
        }
    }

    await fetch(`${ApiUrl}/api/restaurant/menus/${menuId}/categories/${categoryId}/positions/${id}`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            ...position,
            allergensNames: position.allergensNames ? position.allergensNames.split(',').map((alergen) => alergen.trim()) : [],
            tagsNames: position.tagsNames ? position.tagsNames.split(',').map((alergen) => alergen.trim()) : [],
            productImageId
        })
    }).then((response) => {
        if (!response.ok) {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then(() => {
        dispatch({
            type: ACTION_TYPES.MENU_POSITION_EDITED
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.EDIT_MENU_POSITION_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const fetchBreadcrumb = (parts) => async (dispatch, getState) => {
    const token = selectUserToken(getState());

    // eslint-disable-next-line max-len
    const encodedParts = parts.map(({ id, type }, index) => `breadcrumbPartInputModels[${index}].id=${id}&breadcrumbPartInputModels[${index}].type=${type}`).join('&');

    await fetch(`${ApiUrl}/api/restaurant/breadcrumbs?${encodedParts}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }).then((response) => {
        if (!response.ok) {
            return response.json().then((json) => { throw new ApiError(json); });
        }

        return response.json();
    }).then((response) => {
        dispatch({
            type: ACTION_TYPES.BREADCRUMB_FETCHED,
            payload: response
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.FETCH_BREADCRUMB_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const deleteMenuPosition = ({ menuId, categoryId, id }) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.DELETEING_MENU_POSITION
    });

    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/menus/${menuId}/categories/${categoryId}/positions/${id}`, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }).then((response) => {
        if (!response.ok) {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then(() => {
        dispatch({
            type: ACTION_TYPES.MENU_POSITION_DELETED
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.DELETE_MENU_POSITION_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const statusChange = ({ isActive, id, menuId, categoryId }) => async (dispatch, getState, diContainer) => {
    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/menus/${menuId}/categories/${categoryId}/positions/${id}/status`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            isActive
        })
    }).then((response) => {
        if (!response.ok) {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then(() => {
        fetchMenuPositions(menuId, categoryId)(dispatch, getState, diContainer);
        fetchMenuPositionSettings({ menuId, categoryId, id })(dispatch, getState, diContainer);
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.MENU_POSITION_STATUS_CHANGE_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const clearMenuPosition = () => async (dispatch) => {
    dispatch({
        type: ACTION_TYPES.CLEAR_MENU_POSITION
    });
};
