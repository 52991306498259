import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import FontIcon from 'src/components/FontIcon';
import theme from 'src/styles/components/Button.module.scss';

class IconButton extends Component {
    getLevel = () => {
        if (this.props.primary) return 'primary';
        if (this.props.accent) return 'accent';
        return 'neutral';
    }

    handleMouseUp = (event) => {
        this.buttonNode.blur();
        if (this.props.onMouseUp) this.props.onMouseUp(event);
    };

    handleMouseLeave = (event) => {
        this.buttonNode.blur();
        if (this.props.onMouseLeave) this.props.onMouseLeave(event);
    };

    render() {
        const {
            accent,
            children,
            className,
            href,
            icon,
            inverse,
            neutral,
            primary,
            type,
            mini,
            ...others
        } = this.props;

        const element = href ? 'a' : 'button';
        const level = this.getLevel();
        const classes = classnames([theme.toggle], {
            [theme[level]]: neutral,
            [theme.inverse]: inverse,
            [theme.mini]: mini
        }, className);

        const props = {
            ...others,
            href,
            'ref': (node) => { this.buttonNode = node; },
            'className': classes,
            'disabled': this.props.disabled,
            'onMouseUp': this.handleMouseUp,
            'onMouseLeave': this.handleMouseLeave,
            'type': !href ? type : null,
            'data-sdk': 'button'
        };

        const iconElement = typeof icon === 'string'
            ? <FontIcon className={theme.icon} value={icon} />
            : icon;

        return React.createElement(
            element, props,
            icon && iconElement,
            children
        );
    }
}

IconButton.propTypes = {
    accent: PropTypes.bool,
    mini: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    href: PropTypes.string,
    icon: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element
    ]),
    inverse: PropTypes.bool,
    neutral: PropTypes.bool,
    onMouseLeave: PropTypes.func,
    onMouseUp: PropTypes.func,
    primary: PropTypes.bool,
    type: PropTypes.string
};

IconButton.defaultProps = {
    accent: false,
    className: '',
    neutral: true,
    primary: false,
    type: 'button'
};

export default IconButton;
