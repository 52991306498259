export function pauseEvent(event) {
    event.stopPropagation();
    event.preventDefault();
}

export function addEventsToDocument(eventMap) {
    Object.keys(eventMap).forEach((key) => {
        document.addEventListener(key, eventMap[key], false);
    });
}

export function removeEventsFromDocument(eventMap) {
    Object.keys(eventMap).forEach((key) => {
        document.removeEventListener(key, eventMap[key], false);
    });
}

export function targetIsDescendant(event, parent) {
    let node = event.target;
    while (node !== null) {
        if (node === parent) return true;
        node = node.parentNode;
    }
    return false;
}

export function getMousePosition(event) {
    return {
        x: event.pageX - (window.scrollX || window.pageXOffset),
        y: event.pageY - (window.scrollY || window.pageYOffset)
    };
}

export function getTouchPosition(event) {
    return {
        x: event.touches[0].pageX - (window.scrollX || window.pageXOffset),
        y: event.touches[0].pageY - (window.scrollY || window.pageYOffset)
    };
}
