import { ACTION_TYPES } from './constants';

const initialState = {
    details: null,
    restaurantFeatching: false,
    restaurantFetchedSuccessfuly: false,
    restaurantUpdateingInProgress: false,
    restaurantUpdateSuccess: false,
    restaurantUpdateError: null
};

export default function menuReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.FETCHING_RESTAURANT: {
            return {
                ...state,
                restaurantFeatching: true
            };
        }

        case ACTION_TYPES.RESTAURANT_FETCHED: {
            return {
                ...state,
                isRestaurantFeatching: false,
                restaurantFetchedSuccessfuly: true,
                details: action.payload
            };
        }

        case ACTION_TYPES.RESTAURANT_FETCH_FAILED: {
            return {
                ...state,
                isRestaurantFeatching: false,
                restaurantFetchedSuccessfuly: false
            };
        }

        case ACTION_TYPES.UPDATE_RESTAURANT: {
            return {
                ...state,
                restaurantUpdateingInProgress: true,
                restaurantUpdateError: initialState.restaurantUpdateError
            };
        }

        case ACTION_TYPES.UPDATE_RESTAURANT_SUCCESS: {
            return {
                ...state,
                restaurantUpdateingInProgress: false,
                restaurantUpdateSuccess: true
            };
        }

        case ACTION_TYPES.UPDATE_RESTAURANT_FAILED: {
            return {
                ...state,
                restaurantUpdateingInProgress: false,
                restaurantUpdateSuccess: false,
                restaurantUpdateError: action.payload
            };
        }

        case ACTION_TYPES.CLEAR_RESTAUANT_UPDATE_SUCCESS: {
            return {
                ...state,
                restaurantUpdateSuccess: false,
                restaurantUpdateingInProgress: false,
                restaurantUpdateError: initialState.restaurantUpdateError
            };
        }

        default: return state;
    }
}
