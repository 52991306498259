export const MODULE_NAME = 'restaurant';

const prefix = `${MODULE_NAME}/`;

export const ACTION_TYPES = {
    ADD_IMAGE_SUCCESS: `${prefix}ADD_IMAGE_SUCCESS`,
    ADD_IMAGE_FAILED: `${prefix}ADD_IMAGE_FAILED`,
    FETCHING_RESTAURANT: `${prefix}FETCHING_RESTAURANT`,
    RESTAURANT_FETCHED: `${prefix}RESTAURANT_FETCHED`,
    RESTAURANT_FETCH_FAILED: `${prefix}RESTAURANT_FETCH_FAILED`,
    UPDATE_RESTAURANT: `${prefix}UPDATE_RESTAURANT`,
    UPDATE_RESTAURANT_SUCCESS: `${prefix}UPDATE_RESTAURANT_SUCCESS`,
    UPDATE_RESTAURANT_FAILED: `${prefix}UPDATE_RESTAURANT_FAILED`,
    CLEAR_RESTAUANT_UPDATE_SUCCESS: `${prefix}CLEAR_RESTAUANT_UPDATE_SUCCESS`
};

export const SETTINGS_TYPE = {
    BASE: 'base',
    LOCATION: 'location',
    SOCIAL: 'social',
    POSITION: 'position',
    GALLERY: 'gallery'
};

export const AUTHENTICATION_METHOD = {
    EMAIL: 0,
    SMS: 1
};

export const AUTHENTICATION_METHOD_TRANSLATIONS = {
    [AUTHENTICATION_METHOD.EMAIL]: 'email',
    [AUTHENTICATION_METHOD.SMS]: 'sms'
};

export const DEFAULT_COLORS = {
    PRIMARY_COLOR: '#d9af27',
    SECONDARY_COLOR: '#fff'
};
