import ApiUrl from 'src/config/api';
import { selectUserToken } from 'src/modules/User';
import ApiError from 'src/utils/error';
import { ACTION_TYPES } from './constants';

export const fetchAddons = (addonGroupId) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.FETCHING_ADDONS
    });

    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/addonGroups/${addonGroupId}/addons`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then((response) => {
        dispatch({
            type: ACTION_TYPES.ADDONS_FETCHED,
            payload: { addons: response.addons, addonGroupId }
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.ADDONS_FETCH_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const fetchAddon = (id, addonGroupId) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.FETCHING_ADDON
    });

    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/addonGroups/${addonGroupId}/addons/${id}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then((response) => {
        dispatch({
            type: ACTION_TYPES.ADDON_FETCHED,
            payload: response
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.ADDON_FETCH_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const fetchAddonSettings = (id, addonGroupId) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.FETCHING_ADDON_SETTINGS
    });

    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/addonGroups/${addonGroupId}/addons/${id}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then((response) => {
        dispatch({
            type: ACTION_TYPES.ADDON_SETTINGS_FETCHED,
            payload: response
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.ADDON_SETTINGS_FETCH_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const addAddon = ({ addonGroupId }, addon) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.ADDING_ADDON
    });

    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/addongroups/${addonGroupId}/addons`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({ ...addon })
    }).then((response) => {
        if (!response.ok) {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then(() => {
        dispatch({
            type: ACTION_TYPES.ADDON_ADDED
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.ADD_ADDON_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const addAddonToExistingGroup = ({ addonGroupId, productId }, values) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.ADDING_ADDON
    });

    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/products/${productId}/addonGroups/${addonGroupId}/addons`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify(values)
    }).then((response) => {
        if (!response.ok) {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then(() => {
        dispatch({
            type: ACTION_TYPES.ADDON_ADDED
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.ADD_ADDON_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const editAddon = ({ id, addonGroupId }, position) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.EDITING_ADDON
    });

    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/addonGroups/${addonGroupId}/addons/${id}`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify(position)
    }).then((response) => {
        if (!response.ok) {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then(() => {
        dispatch({
            type: ACTION_TYPES.ADDON_EDITED
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.EDIT_ADDON_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const deleteAddon = ({ addonGroupId, id }) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.DELETEING_ADDON
    });

    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/addongroups/${addonGroupId}/addons/${id}`, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }).then((response) => {
        if (!response.ok) {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).then(() => {
        dispatch({
            type: ACTION_TYPES.ADDON_DELETED
        });
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.DELETE_ADDON_FAILED,
            payload: error?.firstMessage
        });
    });
};

export const updateAddonOrder = (id, { addonGroupId }, { fromIndex, toIndex }) => async (dispatch, getState) => {
    dispatch({
        type: ACTION_TYPES.ADDON_REORDERED,
        payload: { id, addonGroupId, fromIndex, toIndex }
    });

    const token = selectUserToken(getState());

    await fetch(`${ApiUrl}/api/restaurant/addongroups/${addonGroupId}/addons/${id}/order`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify({
            order: toIndex
        })
    }).then((response) => {
        if (!response.ok) {
            return response.json().then((json) => { throw new ApiError(json); });
        }
    }).catch((error) => {
        dispatch({
            type: ACTION_TYPES.ADDON_REORDER_FAILED,
            payload: { error: error?.firstMessage, id, addonGroupId, fromIndex, toIndex }
        });
    });
};
