export const MODULE_NAME = 'settings';

const prefix = `${MODULE_NAME}/`;

export const ACTION_TYPES = {
    ACTIVE_TAB_CHANGED: `${prefix}ACTIVE_TAB_CHANGED`,
    CHANGE_IDLE_OBJECT: `${prefix}CHANGE_IDLE_OBJECT`
};

export const TAB = {
    SETTINGS: 'general',
    TABLES: 'tables',
    MENU: 'menu',
    NEWCODE: 'newcode',
    AREAS: 'areas'
};

export const SECTION = {
    SETTINGS: 'settings',
    TABLES: 'tables',
    NEWCODE: 'newcode'
};

export const TABS = [TAB.TABLES, TAB.MENU, TAB.AREAS, TAB.SETTINGS];
