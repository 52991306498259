import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { isDevelopment } from '../config/development';

export default (di) => {
    const middlewares = [
        thunk.withExtraArgument(di.container)
    ];

    if (isDevelopment) {
        middlewares.push(createLogger());
    }

    return middlewares;
};
