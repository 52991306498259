import WebSocket from 'src/utils/webSocket';

import { selectFingerprint } from 'src/modules/User';

class SessionWebSocket extends WebSocket {
    receive() {}
}

export default ({
    WebSocket: SessionWebSocket,
    options: {
        userSelector: selectFingerprint
    },
    socketName: 'sessionWebSocket'
});
