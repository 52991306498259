import { createSelector } from 'reselect';
import { prepareScheduleForm, prepareSchedule } from 'src/utils/schedule';

export const selectMenus = (state) => state.menu.menus || [];

export const selectMenu = (state) => state.menu.menu;

export const selectMenuCategory = (state) => state.menu.menuCategory;

export const selectMenuCategories = (state) => state.menu.categories;

export const selectMenuPositions = (state) => state.menu.positions;

export const selectIsAddMenuPending = (state) => state.menu.isMenuAdding;

export const selectAddMenuError = (state) => state.menu.menuAddError;

export const selectIsEditMenuPending = (state) => state.menu.isMenuEditing;

export const selectEditMenuError = (state) => state.menu.menuEditError;

export const selectIsDeleteMenuPending = (state) => state.menu.isMenuDeleting;

export const selectDeleteMenuError = (state) => state.menu.menuDeleteError;

export const selectIsAddMenuCategoryPending = (state) => state.menu.isMenuCategoryAdding;

export const selectAddMenuCategoryError = (state) => state.menu.menuCategoryAddError;

export const selectIsEditMenuCategoryPending = (state) => state.menu.isMenuCategoryEditing;

export const selectEditMenuCategoryError = (state) => state.menu.menuCategoryEditError;

export const selectIsDeleteMenuCategoryPending = (state) => state.menu.isMenuCategoryDeleting;

export const selectDeleteMenuCategoryError = (state) => state.menu.menuCategoryDeleteError;

export const selectMenuCategoryName = (state) => state.menu.menuCategoryName;

export const selectMenuName = (state) => state.menu.menuName;

export const selectCategorySchedule = (state) => state.menu.categorySchedule;

export const seelctIsCategoryHiddenWhenNotActive = (state) => state.menu.isHiddenWhenNotActive;

export const selectAddMenuStatus = createSelector(
    selectIsAddMenuPending,
    selectAddMenuError,
    (isPending, error) => ({
        isPending,
        error
    })
);

export const selectEditMenuStatus = createSelector(
    selectIsEditMenuPending,
    selectEditMenuError,
    (isPending, error) => ({
        isPending,
        error
    })
);

export const selectDeleteMenuStatus = createSelector(
    selectIsDeleteMenuPending,
    selectDeleteMenuError,
    (isPending, error) => ({
        isPending,
        error
    })
);

export const selectAddMenuCategoryStatus = createSelector(
    selectIsAddMenuCategoryPending,
    selectAddMenuCategoryError,
    (isPending, error) => ({
        isPending,
        error
    })
);

export const selectEditMenuCategoryStatus = createSelector(
    selectIsEditMenuCategoryPending,
    selectEditMenuCategoryError,
    (isPending, error) => ({
        isPending,
        error
    })
);

export const selectDeleteMenuCategoryStatus = createSelector(
    selectIsDeleteMenuCategoryPending,
    selectDeleteMenuCategoryError,
    (isPending, error) => ({
        isPending,
        error
    })
);

function prepareTiles(collection) {
    return collection.map(({
        schedule,
        ...element
    }) => ({
        ...element,
        schedule: prepareSchedule(schedule)
    }));
}

export const selectMenusTiles = createSelector(
    selectMenus,
    (menus) => prepareTiles(menus)
);

export const selectMenuCategoriesTiles = createSelector(
    selectMenuCategories,
    (categories) => prepareTiles(categories)
);

export const selectMenuFormInitialData = createSelector(
    selectMenu,
    (menu) => {
        if (!menu) {
            return null;
        }

        return prepareScheduleForm(menu);
    }
);

export const selectMenuCategoryFormInitialData = createSelector(
    selectMenuCategory,
    (menuCategory) => {
        if (!menuCategory) {
            return null;
        }

        return prepareScheduleForm(menuCategory);
    }
);

export const selectAreMenuPositionsHiddenInCategory = createSelector(
    selectMenuPositions,
    (positions) => positions.some((position) => position.isHiddenWhenNotActive)
);

export const selectMenuPositionsManagementAdditionalData = createSelector(
    selectCategorySchedule,
    seelctIsCategoryHiddenWhenNotActive,
    (schedule, isCategoryHiddenWhenNotActive) => ({
        schedule: schedule && prepareSchedule(schedule),
        isCategoryHiddenWhenNotActive
    })
);
