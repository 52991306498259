import { connect } from 'react-redux';
import {
    selectRestaurantColors
} from 'src/modules/User';

const mapStateToProps = (state) => ({
    colors: selectRestaurantColors(state)
});

export default connect(mapStateToProps);
