import { MODULE_NAME } from './constants';
import reducer from './reducer';

export {
    autocomplete,
    clearAutocomplete
} from './actions';

export {
    selectAutocomplete,
    selectAutocompleteData
} from './selectors';

export default () => ({
    [MODULE_NAME]: { reducer }
});
