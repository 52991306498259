import { connect } from 'react-redux';

import {
    selectAutocompleteData,
    autocomplete,
    clearAutocomplete
} from 'src/modules/Autocomplete';

const mapDispatchToProps = {
    autocomplete,
    clearAutocomplete
};

const mapStateToProps = (state, { id }) => ({
    data: selectAutocompleteData(state, id) || []
});

export default connect(mapStateToProps, mapDispatchToProps);
