import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { injectIntl } from 'react-intl';

const createMappedComponent = (Component, mapProps) => (props) => <Component {...mapProps(props)} />;

const createComponent = (Component, mapProps) => {
    const MappedComponent = createMappedComponent(Component, mapProps);

    const ReduxToolboxField = (props) => <Field {...props} component={MappedComponent} />;

    ReduxToolboxField.propTypes = {
        name: PropTypes.string.isRequired
    };

    return injectIntl(ReduxToolboxField);
};

export default createComponent;
