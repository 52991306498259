import Head from 'next/head';
import App from 'next/app';
import React from 'react';
import PropTypes from 'prop-types';
import { createWrapper } from 'next-redux-wrapper';
import makeStore from 'src/store';
import processDependencies from 'src/store/processDependencies';
import Bottle from 'bottlejs';
import DiContext from '@aveneo/frontmoon-di/lib';
import compose from '@aveneo/frontmoon-utils/lib/hocs/compose';
import { PersistGate } from 'redux-persist/integration/react';

import LocaleProvider from 'src/components/LocaleProvider';
import RestaurantColors from 'src/components/RestaurantColors';

import 'src/styles/globals.scss';

const diContainer = Bottle.pop('app');

const DiWrapper = ({ children, di, store }) => {
    const { ViewportWatcher } = di.container;

    return (
        <>
            <Head>
                <script rel="preload" src="/__ENV.js" />
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
                />
                <meta name="mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
                <link rel="manifest" href="/manifest.json" />
            </Head>
            <PersistGate
              // eslint-disable-next-line no-underscore-dangle
              persistor={store.__persistor}
            >
                <DiContext.Provider value={di}>
                    <ViewportWatcher>
                        {children}
                    </ViewportWatcher>
                </DiContext.Provider>
            </PersistGate>
        </>
    );
};

DiWrapper.propTypes = {
    children: PropTypes.node,
    di: PropTypes.object,
    store: PropTypes.object
};

const ComposedDiWrapper = compose(
    createWrapper(makeStore({ di: diContainer }), { debug: true }).withRedux,
    processDependencies(diContainer)
)(DiWrapper);

class MyApp extends App {
    render() {
        const { Component, pageProps } = this.props;

        const Layout = Component.layout || React.Fragment;

        return (
            // eslint-disable-next-line no-underscore-dangle
            <LocaleProvider>
                <ComposedDiWrapper>
                    <Layout>
                        <RestaurantColors />
                        <Component {...pageProps} />
                    </Layout>
                </ComposedDiWrapper>
            </LocaleProvider>
        );
    }
}

export default MyApp;
