export const MODULE_NAME = 'addon';

const prefix = `${MODULE_NAME}/`;

export const ACTION_TYPES = {
    FETCHING_ADDONS: `${prefix}FETCHING_ADDONS`,
    ADDONS_FETCHED: `${prefix}ADDONS_FETCHED`,
    ADDONS_FETCH_FAILED: `${prefix}ADDONS_FETCH_FAILED`,
    FETCHING_ADDON: `${prefix}FETCHING_ADDON`,
    ADDON_FETCHED: `${prefix}ADDON_FETCHED`,
    ADDON_FETCH_FAILED: `${prefix}ADDON_FETCH_FAILED`,
    ADDING_ADDON: `${prefix}ADDING_ADDON`,
    ADDON_ADDED: `${prefix}ADDON_ADDED`,
    ADD_ADDON_FAILED: `${prefix}ADD_ADDON_FAILED`,
    EDITING_ADDON: `${prefix}EDITING_ADDON`,
    ADDON_EDITED: `${prefix}ADDON_EDITED`,
    EDIT_ADDON_FAILED: `${prefix}EDIT_ADDON_FAILED`,
    DELETEING_ADDON: `${prefix}DELETEING_ADDON`,
    ADDON_DELETED: `${prefix}ADDON_DELETED`,
    DELETE_ADDON_FAILED: `${prefix}DELETE_ADDON_FAILED`,
    FETCHING_ADDON_SETTINGS: `${prefix}FETCHING_ADDON_SETTINGS`,
    ADDON_SETTINGS_FETCHED: `${prefix}ADDON_SETTINGS_FETCHED`,
    ADDON_SETTINGS_FETCH_FAILED: `${prefix}ADDON_SETTINGS_FETCH_FAILED`,
    ADDON_REORDERED: `${prefix}ADDON_REORDERED`,
    ADDON_REORDER_FAILED: `${prefix}ADDON_REORDER_FAILED`
};
