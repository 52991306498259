import PropTypes from 'prop-types';
import { useEffect } from 'react';
import tinycolor from 'tinycolor2';

const RestaurantColors = ({
    colors
}) => {
    useEffect(() => {
        if (colors) {
            document.documentElement.style.setProperty('--color-accent-contrast', colors.secondaryColor);
            document.documentElement.style.setProperty('--color-accent', colors.primaryColor);
            document.documentElement.style.setProperty('--color-accent-light', tinycolor(colors.primaryColor).lighten(10).toString());
            document.documentElement.style.setProperty('--color-accent-lighten', tinycolor(colors.primaryColor).lighten(20).toString());
        }
    }, [colors]);

    return null;
};

RestaurantColors.propTypes = {
    colors: PropTypes.shape({
        primaryColor: PropTypes.string,
        secondaryColor: PropTypes.string
    })
};

export default RestaurantColors;
