import AutocompleteProps from './Autocomplete';
import InputProps from './Input';
import ChipInputProps from './ChipInput';
import DropdownProps from './Dropdown';
import InputImageProps from './InputImage';
import InputFileProps from './InputFile';
import ColorPickerProps from './ColorPicker';
import InputDateTimePickerProps from './InputDateTimePicker';
import CheckboxProps from './Checkbox';
import SliderProps from './Slider';
import SwitchProps from './Switch';
import WysiwygProps from './Wysiwyg';
import LogicQueryProps from './LogicQueryInput';
import AceEditorProps from './AceEditor';
import GoogleMapReactProps from './GoogleMapReact';
import createComponent from './createComponent';

export default ({ components }) => {
    const formComponents = {
        createComponent
    };

    if (components.Input) {
        formComponents.InputForm = createComponent(components.Input, InputProps);
    }

    if (components.PredefinedAutocomplete) {
        formComponents.PredefinedAutocompleteForm = createComponent(components.PredefinedAutocomplete, AutocompleteProps);
    }

    if (components.Checkbox) {
        formComponents.CheckboxForm = createComponent(components.Checkbox, CheckboxProps);
    }

    if (components.ColorPicker) {
        formComponents.ColorPickerForm = createComponent(components.ColorPicker, ColorPickerProps);
    }

    if (components.Dropdown) {
        formComponents.DropdownForm = createComponent(components.Dropdown, DropdownProps);
    }
    if (components.InputDateTimePicker) {
        formComponents.InputDateTimePickerForm = createComponent(components.InputDateTimePicker, InputDateTimePickerProps);
    }

    if (components.InputDatePicker) {
        formComponents.InputDatePickerForm = createComponent(components.InputDatePicker, InputDateTimePickerProps);
    }

    if (components.InputFile) {
        formComponents.InputFileForm = createComponent(components.InputFile, InputFileProps);
    }

    if (components.Input) {
        formComponents.InputForm = createComponent(components.Input, InputProps);
    }

    if (components.InputImage) {
        formComponents.InputImageForm = createComponent(components.InputImage, InputImageProps);
    }

    if (components.Slider) {
        formComponents.SliderForm = createComponent(components.Slider, SliderProps);
    }

    if (components.Switch) {
        formComponents.SwitchForm = createComponent(components.Switch, SwitchProps);
    }

    if (components.Wysiwyg) {
        formComponents.WysiwygForm = createComponent(components.Wysiwyg, WysiwygProps);
    }

    if (components.LogicQueryInput) {
        formComponents.LogicQueryInputForm = createComponent(components.LogicQueryInput, LogicQueryProps);
    }

    if (components.AceEditor) {
        formComponents.AceEditorForm = createComponent(components.AceEditor, AceEditorProps);
    }

    if (components.GoogleMapReact) {
        formComponents.GoogleMapReactForm = createComponent(components.GoogleMapReact, GoogleMapReactProps);
    }

    if (components.GenericAutocomplete) {
        formComponents.ChipInputForm = createComponent(components.GenericAutocomplete, ChipInputProps);
    }

    return formComponents;
};
