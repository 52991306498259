import omit from 'lodash/omit';
import get from 'lodash/get';

const mapProps = ({ input, intl, ...props }) => {
    const customProps = { };

    const isTouched = get(props.meta, 'touched');
    const error = get(props.meta, 'error');

    if (error && isTouched) {
        customProps.error = intl.formatMessage({ id: error });
    }

    return {
        ...input,
        ...omit(props, ['meta']),
        onDateTimeSelected: (dateTime) => { input.onChange(dateTime); },
        ...customProps
    };
};

export default mapProps;
