import arrayMove from 'array-move';
import { ACTION_TYPES } from './constants';

const initialState = {
    addonGroups: null,
    areAddonGroupsFetching: false,
    addonGroup: null,
    isAddonGroupFetching: false,
    isAddonGroupAdding: false,
    addonGroupAddError: null,
    isAddonGroupEditing: false,
    addonGroupEditError: null,
    isAddonGroupDeleting: false,
    addonGroupDeleteError: null
};

export default function addonGroupReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.FETCHING_ADDON_GROUPS: {
            return {
                ...state,
                areAddonGroupsFetching: true,
                addonGroups: initialState.addonGroups
            };
        }

        case ACTION_TYPES.ADDON_GROUPS_FETCHED: {
            const { addonGroups } = action.payload;

            return {
                ...state,
                areAddonGroupsFetching: false,
                addonGroups
            };
        }

        case ACTION_TYPES.ADDON_GROUPS_FETCH_FAILED: {
            return {
                ...state,
                areAddonGroupsFetching: false,
                addonGroups: initialState.addonGroups
            };
        }

        case ACTION_TYPES.FETCHING_ADDON_GROUP: {
            return {
                ...state,
                isAddonGroupFetching: true,
                addonGroup: initialState.addonGroup
            };
        }

        case ACTION_TYPES.ADDON_GROUP_FETCHED: {
            return {
                ...state,
                isAddonGroupFetching: false,
                addonGroup: action.payload
            };
        }

        case ACTION_TYPES.ADDON_GROUP_FETCH_FAILED: {
            return {
                ...state,
                isAddonGroupFetching: false,
                addonGroup: initialState.addonGroup
            };
        }

        case ACTION_TYPES.ADDING_ADDON_GROUP: {
            return {
                ...state,
                isAddonGroupAdding: true,
                addonGroupAddError: null
            };
        }

        case ACTION_TYPES.ADDON_GROUP_ADDED: {
            return {
                ...state,
                isAddonGroupAdding: false,
                addonGroupAddError: null
            };
        }

        case ACTION_TYPES.ADD_ADDON_GROUP_FAILED: {
            return {
                ...state,
                isAddonGroupAdding: false,
                addonGroupAddError: action.payload
            };
        }

        case ACTION_TYPES.EDITING_ADDON_GROUP: {
            return {
                ...state,
                isAddonGroupEditing: true,
                addonGroupEditError: null
            };
        }

        case ACTION_TYPES.ADDON_GROUP_EDITED: {
            return {
                ...state,
                isAddonGroupEditing: false,
                addonGroupEditError: null
            };
        }

        case ACTION_TYPES.EDIT_ADDON_GROUP_FAILED: {
            return {
                ...state,
                isAddonGroupEditing: false,
                addonGroupEditError: action.payload
            };
        }

        case ACTION_TYPES.DELETEING_ADDON_GROUP: {
            return {
                ...state,
                isAddonGroupDeleting: true,
                addonGroupDeleteError: null
            };
        }

        case ACTION_TYPES.ADDON_GROUP_DELETED: {
            return {
                ...state,
                isAddonGroupDeleting: false,
                addonGroupDeleteError: null
            };
        }

        case ACTION_TYPES.DELETE_ADDON_GROUP_FAILED: {
            return {
                ...state,
                isAddonGroupDeleting: false,
                addonGroupDeleteError: action.payload
            };
        }

        case ACTION_TYPES.ADDON_GROUP_REORDERED: {
            const { fromIndex, toIndex } = action.payload;

            return {
                ...state,
                addonGroups: arrayMove(state.addonGroups, fromIndex - 1, toIndex - 1)
            };
        }

        case ACTION_TYPES.ADDON_GROUP_REORDER_FAILED: {
            const { fromIndex, toIndex } = action.payload;

            return {
                ...state,
                addonGroups: arrayMove(state.addonGroups, toIndex - 1, fromIndex - 1)
            };
        }

        default: return state;
    }
}
