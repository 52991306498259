import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';

import Autocomplete from 'src/components/Autocomplete';

class PredefinedAutocomplete extends Component {
    hanldeQueryChanged = debounce((query) => {
        const { actionUrl, autocomplete, id, filterParameters, fieldName, responseDataSelector } = this.props;

        this.lastQuery = query;

        autocomplete({
            id,
            fieldName: fieldName || id,
            url: actionUrl,
            query,
            filterParameters,
            selectedIds: this.state.selectedIds,
            responseDataSelector
        });
    }, 500);

    constructor(props) {
        super(props);

        this.state = {
            selectedIds: []
        };
    }

    componentDidUpdate({ filterParameters, autocomplete, actionUrl, id, fieldName, responseDataSelector }, prevState) {
        const hasFilterParametersChanged = !isEqual(filterParameters, this.props.filterParameters);
        const selectedIdsChanged = prevState.selectedIds.length !== this.state.selectedIds.length;

        if (hasFilterParametersChanged || selectedIdsChanged) {
            const query = this.lastQuery;

            autocomplete({
                id,
                fieldName: fieldName || id,
                url: actionUrl,
                query,
                filterParameters: this.props.filterParameters,
                selectedIds: this.state.selectedIds,
                responseDataSelector
            });
        }
    }

    componentWillUnmount() {
        this.props.clearAutocomplete({ id: this.props.id });
    }

    handleValueChanged = (value) => {
        if (this.props.shouldOmitExistingElements) {
            if (isArray(value)) {
                this.setState({
                    selectedIds: value.map((item) => item[this.props.keyFieldName])
                });
            } else if (value) {
                this.setState({
                    selectedIds: isObject(value)
                        ? [value[this.props.keyFieldName]]
                        : value
                });
            } else {
                this.setState({
                    selectedIds: []
                });
            }
        }
    }

    render() {
        const {
            data,
            formId,
            actionUrl,
            autocomplete,
            clearAutocomplete,
            filterParameters,
            shouldOmitExistingElements,
            responseDataSelector,
            label,
            ...rest
        } = this.props;

        return (
            <Autocomplete
              onQueryChange={this.hanldeQueryChanged}
              onInitialize={this.hanldeQueryChanged}
              onValueChanged={this.handleValueChanged}
              multiple={false}
              source={data}
              label={label}
              suggestionMatch="anywhere"
              {...rest}
            />
        );
    }
}

PredefinedAutocomplete.propTypes = {
    id: PropTypes.string.isRequired,
    autocomplete: PropTypes.func.isRequired,
    actionUrl: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.object),
    initialElement: PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        query: PropTypes.string
    }),
    keyFieldName: PropTypes.string,
    valueFieldName: PropTypes.string,
    fieldName: PropTypes.string,
    filterParameters: PropTypes.object,
    shouldOmitExistingElements: PropTypes.bool,
    responseDataSelector: PropTypes.string,
    clearAutocomplete: PropTypes.func.isRequired,
    formId: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    label: PropTypes.string
};

PredefinedAutocomplete.defaultProps = {
    keyFieldName: 'id',
    valueFieldName: 'name',
    filterParameters: {},
    shouldOmitExistingElements: false,
    responseDataSelector: 'items'
};

export default PredefinedAutocomplete;
