import get from 'lodash/get';

import { ACTION_TYPES as MENU_ACTION_TYPES } from 'src/modules/Menu/constants';
import { ACTION_TYPES } from './constants';

const initialState = {
    orders: [],
    areOrdersFetched: false,
    isOrderPositionAdded: false,
    isOrderPositionAdding: false,
    isOrderPositionRemoved: false,
    context: null,
    shouldShowAddOrderPositionErrorMessage: false,
    isSubmitOrderPenging: false,
    configurablePosition: null,
    validationErrors: [],
    isValid: true,
    price: 0,
    positionsNumber: 0
};

export default function menuReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.INCREASE_QUANTITY: {
            return {
                ...state,
                configurablePosition: {
                    ...state.configurablePosition,
                    quantity: state.configurablePosition.quantity + 1
                }
            };
        }

        case ACTION_TYPES.DECREASE_QUANTITY: {
            return {
                ...state,
                configurablePosition: {
                    ...state.configurablePosition,
                    quantity: state.configurablePosition.quantity <= 1
                        ? state.configurablePosition.quantity
                        : state.configurablePosition.quantity - 1
                }
            };
        }

        case ACTION_TYPES.SET_SELECTED_MENU_POSITION: {
            return {
                ...state,
                configurablePosition: {
                    ...action.payload,
                    quantity: 1
                },
                validationErrors: initialState.validationErrors
            };
        }

        case ACTION_TYPES.SELECTED_MENU_POSITION_CLEARED: {
            return {
                ...state,
                configurablePosition: initialState.configurablePosition,
                isOrderPositionAdded: initialState.isOrderPositionAdded
            };
        }

        case ACTION_TYPES.ORDER_POSITION_ADDED: {
            return {
                ...state,
                isOrderPositionAdded: true
            };
        }

        case ACTION_TYPES.ADD_ORDER_POSITION_FAILED: {
            return {
                ...state,
                shouldShowAddOrderPositionErrorMessage: true,
                isOrderPositionAdded: false
            };
        }

        case ACTION_TYPES.CLEAR_ADD_ORDER_POSITION_ERROR_MESSAGE: {
            return {
                ...state,
                shouldShowAddOrderPositionErrorMessage: false,
                isOrderPositionAdded: false,
                validationErrors: initialState.validationErrors
            };
        }

        case ACTION_TYPES.ORDERS_FETCHED: {
            const {
                orders,
                positionsNumber,
                price
            } = action.payload;

            return {
                ...state,
                orders,
                price,
                positionsNumber,
                selectAreOrdersFetched: true
            };
        }

        case ACTION_TYPES.SUBMIT_ORDER_PENDING: {
            return {
                ...state,
                isSubmitOrderPending: true
            };
        }

        case ACTION_TYPES.SUBMIT_ORDER_SUCCESS: {
            return {
                ...state,
                isSubmitOrderPending: false
            };
        }

        case ACTION_TYPES.SUBMIT_ORDER_FAILED: {
            return {
                ...state,
                isSubmitOrderPending: false
            };
        }

        case MENU_ACTION_TYPES.MENU_POSITION_DETAILS_FETCHED: {
            return {
                ...state,
                configurablePosition: {
                    ...state.configurablePosition,
                    ...action.payload,
                    quantity: state.configurablePosition.quantity
                }
            };
        }

        case ACTION_TYPES.SET_ADDON_AS_SELECTED: {
            const { addonId, quantity } = action.payload;

            const prepareConfigurablePosition = (position, group) => {
                let shouldOverrideSelected = false;

                if (group && group.addons.some((addon) => addon.id === addonId)) {
                    shouldOverrideSelected = true;
                }

                let canChangeQuantity = false;
                if (addonId === position.id
                    && quantity !== undefined
                    && quantity >= 0
                    && group
                    && shouldOverrideSelected
                    && group.maxQuantity >= group.addons.reduce(
                        (result, addon) => result + (addonId === addon.id ? quantity : addon.quantity),
                        0
                    )
                ) {
                    canChangeQuantity = true;
                }

                return ({
                    ...position,
                    quantity: addonId === position.id && canChangeQuantity
                        ? quantity
                        : position.quantity,
                    addonGroups: position.addonGroups.map((addonGroup) => {
                        shouldOverrideSelected = false;
                        if (addonGroup && addonGroup.controlType === 'radio' && addonGroup.addons.some((addon) => addon.id === addonId)) {
                            shouldOverrideSelected = true;
                        }

                        return ({
                            ...addonGroup,
                            selectedAddon: shouldOverrideSelected
                                ? get(addonGroup.addons.find((addon) => addon.id === addonId), 'id', undefined)
                                : addonGroup.selectedAddon,
                            addons: addonGroup.addons.map((addon) => prepareConfigurablePosition(addon, addonGroup))
                        });
                    })
                });
            };

            const configurablePosition = prepareConfigurablePosition(state.configurablePosition);

            const calculatePrice = (position) => position.price + position.addonGroups.reduce(
                (groupSum, addonGroup) => groupSum + addonGroup.addons.reduce(
                    (addonsSum, addon) => {
                        if (addon.isSelected || addon.quantity) {
                            return addonsSum + addon.price * (addon.quantity || 1);
                        }

                        return addonsSum;
                    },
                    0
                ),
                0
            );

            const calculatedPrice = calculatePrice(configurablePosition);

            return {
                ...state,
                configurablePosition: {
                    ...configurablePosition,
                    quantity: state.configurablePosition.quantity,
                    calculatedPrice
                }
            };
        }

        case ACTION_TYPES.ORDER_POSITION_VALIDATED: {
            const errors = action.payload;

            return {
                ...state,
                validationErrors: errors,
                isValid: Boolean(errors.length)
            };
        }

        case ACTION_TYPES.REMOVE_ORDER_POSITION: {
            return {
                ...state,
                isSubmitOrderPending: false,
                isOrderPositionRemoved: true
            };
        }

        case ACTION_TYPES.REMOVE_ORDER_POSITION_FAILED: {
            return {
                ...state,
                isSubmitOrderPending: false,
                shouldShowAddOrderPositionErrorMessage: true,
                isOrderPositionRemoved: false
            };
        }

        default: return state;
    }
}
