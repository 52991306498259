import compose from '@aveneo/frontmoon-utils/lib/hocs/compose';
import lifecycle from '@aveneo/frontmoon-utils/lib/hocs/lifecycle';
import branch from '@aveneo/frontmoon-utils/lib/hocs/branch';
import renderNothing from '@aveneo/frontmoon-utils/lib/hocs/renderNothing';
import identity from 'lodash/identity';

import connectContainer from './SessionManagerContainer';

export default () => ({
    withSessionManager: compose(
        connectContainer,
        lifecycle({
            componentDidMount() {
                this.props.restoreUserSession();
            }
        }),
        branch(
            (props) => !props.isSessionRestored,
            renderNothing,
            identity
        )
    )
});
