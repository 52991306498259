import reducer from './reducer';
import { MODULE_NAME } from './constants';

export {
    fetchAreas,
    fetchArea,
    addArea,
    deleteArea,
    editArea,
    statusChange
} from './actions';

export {
    selectAreas,
    selectAreasTiles,
    selectArea,
    selectAreaFormInitialData,
    selectAddAreaStatus,
    selectEditAreaStatus,
    selectDeleteAreaStatus
} from './selectors';

export default () => ({
    [MODULE_NAME]: {
        reducer
    }
});
