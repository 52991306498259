import { createSelector } from 'reselect';

export const selectTabs = (state) => state.settings.tabs;

export const selectActiveTab = (state) => state.settings.activeTab;

export const selectIdleObject = (state) => state.settings.idleObject;

export const selectHeaderTabs = createSelector(
    selectTabs,
    selectActiveTab,
    (tabs, active) => tabs.map((tab) => ({
        name: tab,
        isActive: tab === active
    }))
);
